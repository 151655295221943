import React from 'react';

import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textTitleContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    '& p': {
      fontWeight: '600',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  icon: {
    margin: ' auto',
    height: ' 120px',
    display: ' block',
    marginBottom: theme.spacing(2),
  },
}));

const WhatWeDo = () => {
  const classes = useStyles();
  return (
    <Container directon="column">
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item container xs={4}>
              <Grid item container direction="column">
                <Grid item className={classes.textTitleContainer}>
                  <Typography align="center">
                    <FormattedMessage {...messages.creation} />
                  </Typography>
                </Grid>
                <Grid item>
                  <img className={classes.icon} src="/create.svg" alt="create" />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" align="center">
                    <FormattedMessage {...messages.creationText} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item container direction="column">
                <Grid item className={classes.textTitleContainer}>
                  <Typography align="center">
                    <FormattedMessage {...messages.discover} />
                  </Typography>
                </Grid>
                <Grid item>
                  <img className={classes.icon} src="/find.svg" alt="find" />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" align="center">
                    <FormattedMessage {...messages.discoverText} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item container direction="column">
                <Grid item className={classes.textTitleContainer}>
                  <Typography align="center">
                    <FormattedMessage {...messages.gestion} />
                  </Typography>
                </Grid>
                <Grid item>
                  <img className={classes.icon} src="/config.svg" alt="config" />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" align="center">
                    <FormattedMessage {...messages.gestionText} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

export default WhatWeDo;
