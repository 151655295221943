import { compose, reduce, pathOr, set, lensPath } from 'ramda';
import { request } from './utils';
import { SIGNED_OUT } from './auth'; // eslint-disable-line
import { MESSAGE_PUBLISHED } from '../../lib/models/events';

export const CHANNELS_LOADED = '@channels:CHANNELS_LOADED';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case CHANNELS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v.tripId]: v }), { ...state.data }),
        pathOr([], ['data', 'channels']),
      )(action);
      return { ...state, data };
    case MESSAGE_PUBLISHED:
      return {
        ...state,
        data: set(lensPath([action.message.tripId, 'lastMessageId']), action.message._id, state.data),
      };
    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};

const loadAll = () => dispatch =>
  request(dispatch, { method: 'chat:getChannels' }).then(data => {
    dispatch({ type: CHANNELS_LOADED, data });
    return data;
  });

const read = tripId => dispatch => request(dispatch, { method: 'chat:read', tripId });

const actions = {
  loadAll,
  read,
};

export default { reducer, actions };
