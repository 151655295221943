import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getcurrentTripId } from '../../selectors/ridingTrip';
import { getUser } from '../../selectors/auth';
import { getTrip } from '../../selectors/trips';
import actions from '../../actions';
import { getBikersArray, getBikersCount } from '../../selectors/bikers';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    trip: useSelector(getTrip(useSelector(getcurrentTripId))),
    bikersCount: useSelector(getBikersCount),
    bikers: useSelector(getBikersArray) || [],
    loadBikers: (search, accountStatus) => dispatch(actions.bikers.loadAllByAdmin(search, accountStatus)),
    loadBikersCount: accountStatus => dispatch(actions.bikers.loadBikersCount(accountStatus)),
    removeAccountWithId: id => dispatch(actions.bikers.removeAccountWithId(id)),
    sendNotifications: natification => dispatch(actions.bikers.sendNotifications(natification)),
    setSnackBar: snackBar => dispatch(actions.ui.setSnackBar(snackBar)),
  };
};

export const useSearchInput = () => {
  const [searchInput, setSearchInput] = useState('');
  return [searchInput, setSearchInput];
};

export const useAccountStatuses = () => {
  const [accountStatus, setAccountStatus] = useState([]);
  return [accountStatus, setAccountStatus];
};

export const useCurrentActivePage = initPage => {
  const [currentPage, setCurrentPage] = useState(initPage);
  return [currentPage, setCurrentPage];
};
