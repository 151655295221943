import { prop, compose, propOr, values, filter, path } from 'ramda';
import { isFuture, isPast, subDays } from 'date-fns';
import { createSelector } from 'reselect';
import { isActive } from '../../lib/models/trips';

const root = prop('ridingTrip');
export const getcurrentTripId = createSelector(
  root,
  prop('tripId'),
);

export const getShareLocation = createSelector(
  root,
  prop('shareLocation'),
);

export const getTrips = createSelector(
  root,
  compose(
    values,
    propOr([], 'trips'),
  ),
);

export const getDirections = createSelector(
  root,
  prop('directions'),
);

export const getNextDayTrips = createSelector(
  getTrips,
  trips =>
    filter(trip => isPast(subDays(new Date(trip.startAt), 2)) && isFuture(new Date(trip.startAt)) && isActive(trip))(
      trips,
    ),
);

export const getRouteAddress = index =>
  createSelector(
    getDirections,
    path(['waypoints', index, 'name']),
  );
