import { length } from 'ramda';
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { setTripDate } from '../utils/time';
const curretDate = new Date();

const MAX_TIME_DIFFERENCE = 30;
const MIN_YEARS_DIFFERENCE = 2;
const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

export const emptyForm = value => !value;
export const invalidDate = value => value && value.toString() === 'Invalid Date';
export const oldDate = value => value && value < startOfToday();
export const futureDate = value => value && value > endOfToday();
export const invalidYear = value => value && value.getFullYear() >= curretDate.getFullYear() + MIN_YEARS_DIFFERENCE;
export const dateTooSoon = (date, time) =>
  date && time && differenceInMinutes(setTripDate(date, time), new Date()) < MAX_TIME_DIFFERENCE;

export const isMailValid = value => value && emailRegex.test(value);

export const ispasswordValid = value => value && length(value) > 6;
