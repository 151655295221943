import axios from 'axios';
import polyline from '@mapbox/polyline';
import { prop, map, join, compose, toPairs, path } from 'ramda';

export const GOELOCATION_TIMEOUT = 100000;

const makeCoordinates = compose(
  join(';'),
  map(feature => `${path(['geometry', 'coordinates', 0], feature)},${path(['geometry', 'coordinates', 1], feature)}`),
  path(['location', 'features']),
);
const makeParams = compose(
  join('&'),
  map(([key, val]) => val && `${key}=${val}`),
  toPairs,
);

export const getOptimizedRoute = (
  route,
  {
    // source = 'first',
    steps = true,
    overview = 'full',
    exclude,
    ...restParams
  } = {},
) => {
  const url = makeDirectionsBaseUrl(makeCoordinates(route));
  const params = makeParams({
    // source,
    steps,
    ...restParams,
    overview,
    exclude,
    access_token: process.env.REACT_APP_MAPBOX_TOKEN,
  });
  return axios
    .get(`${url}?${params}`)
    .then(prop('data'))
    .then(data => ({
      ...data,
      routes: [
        ...data.routes.map(route => {
          // console.log(data)

          // console.log("=====", {...route,geometry:polyline.toGeoJSON(route.geometry),
          //   legs:[...route.legs.map((leg =>( {...leg, steps:leg.steps
          //     .map(step => ({...step,geometry: polyline.toGeoJSON(step.geometry)})) } ))) ]})
          return {
            ...route,
            geometry: polyline.toGeoJSON(route.geometry),
            legs: [
              ...route.legs.map(leg => ({
                ...leg,
                steps: leg.steps.map(step => ({
                  ...step,
                  geometry: step.geometry ? polyline.toGeoJSON(step.geometry) : { coordinates: [] },
                })),
              })),
            ],
          };
        }),
      ],
    }));
};

const makeDirectionsBaseUrl = coordinates => `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}.json`;

export const getDirections = route => {
  const coordinates = makeCoordinates(route);
  const baseUrl = makeDirectionsBaseUrl(coordinates);
  return axios.get(`${baseUrl}?geometries=geojson`, {
    params: {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
    },
  });
};

const makeAddressBaseUrl = coordinates => `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates}.json`;

export const getAddress = (position = [], limit = 1) => {
  const coordinates = `${position[0]},${position[1]}`;
  const baseUrl = makeAddressBaseUrl(coordinates);
  return axios.get(baseUrl, {
    params: {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
      limit,
      autocomplete: true,
      fuzzyMatch: true,
    },
  });
};
export const getGeoCoder = (position = [], limit = 1, proximity) => {
  const baseUrl = makeAddressBaseUrl(position);
  return axios.get(baseUrl, {
    params: {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
      limit,
      autocomplete: true,
      fuzzyMatch: true,
      proximity,
    },
  });
};

const metersPerPixel = (latitude, zoomLevel) => {
  const earthCircumference = 40075016.686;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (earthCircumference * Math.cos(latitudeRadians)) / 2 ** zoomLevel + 8;
};

export const pixelValue = (latitude, km, zoomLevel) => (km * 1000000) / metersPerPixel(latitude, zoomLevel);

export const pixelToCoordinatesDiff = (pixels, zoom) => pixels / 2 ** zoom;
