import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btn: {
    minWidth: 0,
    width: theme.spacing(3),
    padding: '0',
    border: 'none',
    borderRadius: '0',
  },
}));
const ZoomButtons = ({ viewport, setViewport }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} orientation="vertical" variant="contained" aria-label="small outlined button group">
      <Grid item>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={() =>
            setViewport({
              ...viewport,
              zoom: viewport.zoom + 1,
            })
          }
        >
          <AddIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={() =>
            setViewport({
              ...viewport,
              zoom: viewport.zoom - 1,
            })
          }
        >
          <RemoveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

ZoomButtons.propTypes = {
  viewport: PropTypes.object,
  setViewport: PropTypes.func,
};

export default ZoomButtons;
