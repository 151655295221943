import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { Fab, Grid, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useConnect, useDrawer } from './hooks';
import Nav from '.';
import MainMenu from './MainMenu';
import useRoutes from '../../hooks/routes';
import { ACCOUNT_STATUSES } from '../../../lib/models/bikers';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(1),
    minWidth: 'fit-content',
    overflow: 'hidden',
    color: theme.palette.primary.main,
    width: '100%',
  },
  nav: {
    display: 'flex',
  },
  logoImg: {
    height: '113px',
    margin: 'auto',
    display: 'block',
    position: 'absolute',
    marginTop: '12px',
  },
  fab: {
    color: theme.palette.appBar.main,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    '&:hover,&active,&focus': {
      boxShadow: 'inherit',
      backgroundColor: 'transparent',
    },
  },

  menuFab: {
    boxShadow: theme.shadows[12],

    padding: 4,
  },
  burgerMenu: {
    maxHeight: '35px',
  },
}));

const NavHome = () => {
  const classes = useStyles();
  const { user } = useConnect();
  const [drawer, setDrawer] = useDrawer();
  const routes = useRoutes();
  const history = useHistory();
  const path = useLocation();
  const useIsComplete =
    user && user.status !== ACCOUNT_STATUSES.uncomplete && user.status !== ACCOUNT_STATUSES.unverified;
  const handleClick = () => setDrawer(!drawer);
  const handleHomeClick = () => history.push(routes.getDefaultRoute().path);
  return (
    <Nav className={path.pathname === '/' && classes.nav}>
      <IconButton onClick={handleClick} className={clsx(classes.fab, classes.menuFab)}>
        <Grid container justify="center">
          <Grid item className={classes.burgerMenu}>
            {useIsComplete && <MenuIcon fontSize="large" />}
          </Grid>
        </Grid>
      </IconButton>
      {path.pathname !== '/' && (
        <Fab onClick={handleHomeClick} className={classes.fab}>
          <Grid container justify="center">
            <Grid item>
              <HomeIcon fontSize="large" className={classes.fab} />
            </Grid>
          </Grid>
        </Fab>
      )}

      {path.pathname === '/' ? (
        <img src="ComotLogoPicBlack.webp" alt="comotLogo" className={classes.logoImg} />
      ) : (
        <Typography variant="h6" className={classes.title}>
          <FormattedMessage {...messages.title} />
        </Typography>
      )}
      <MainMenu open={drawer} setDrawer={setDrawer} user={user} />
    </Nav>
  );
};

export default NavHome;
