import React from 'react';
import { getYear } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.grey['300'],
    marginTop: theme.spacing(4),
    color: theme.palette.primary,
  },
  subFooter: {
    backgroundColor: theme.palette.grey['400'],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subFooterText: {
    fontSize: '.9em',
  },
  image: {
    width: '100%',
  },
  victoryImg: {
    height: '3em',
    display: 'block',
    margin: 'auto',
  },
  slogImg: {
    height: '6em',
    display: 'block',
    margin: 'auto',
  },
  icon: {
    color: theme.palette.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  allrights: {
    paddingBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: '600',
  },
}));

const COMOT_FACEBOOK = 'https://www.facebook.com/app.comot';

const COMOT_INSTAGRAM = 'https://www.instagram.com/app.comot/';

const Footer = () => {
  const classes = useStyles();
  return (
    <footer>
      <Container directon="column" className={classes.container}>
        <Grid container spacing={4} justify="space-between">
          <Grid item container xs={4}>
            <img src="/comotSlog.png" alt="comot" className={classes.slogImg} />
          </Grid>
          <Grid item container direction="column" xs={4} justify="space-between">
            <Grid item>
              <Typography align="center" className={classes.bold} color="primary">
                <FormattedMessage {...messages.comotMiddleText} />
              </Typography>
            </Grid>
            <Grid item>
              <img src="/victorySign.png" alt="comot" className={classes.victoryImg} />
            </Grid>
            <Grid item className={classes.allrights}>
              <Typography align="center" color="primary">
                <FormattedMessage {...messages.allRightsReserved} values={{ year: getYear(new Date()) }} />
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={4} justify="space-between">
            <Grid item>
              <Typography align="center" color="primary" className={classes.bold}>
                <FormattedMessage {...messages.findUs} />
              </Typography>
            </Grid>
            <Grid item container justify="center">
              <Grid item>
                <a target="_blank" href={COMOT_FACEBOOK} rel="noopener noreferrer">
                  <IconButton color="primary" className={classes.icon} aria-label="FacebookIcon">
                    <FacebookIcon fontSize="large" />
                  </IconButton>
                </a>
              </Grid>
              <Grid item>
                <a target="_blank" href={COMOT_INSTAGRAM} rel="noopener noreferrer">
                  <IconButton color="primary" className={classes.icon} aria-label="InstagramIcon">
                    <InstagramIcon fontSize="large" />
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.subFooter}>
        <Typography align="center" color="primary" className={classes.subFooterText}>
          <FormattedMessage {...messages.subFooter} />
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
