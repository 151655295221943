const LOCALIZE = 'LOCALIZE';

const reducer = (state = null, action) => {
  switch (action.type) {
    case LOCALIZE:
      return action.position;
    default:
      return state;
  }
};

export const localize = position => ({ type: LOCALIZE, position });

const actions = { localize };
export default { reducer, actions };
