import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import { useConnect } from './hooks';
import useHeight from '../../hooks/useHeight';

const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  icon: {
    marginBottom: '-7px',
  },
  drawer: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
  },
  drawerPaper: ({ height }) => ({
    width: `${drawerWidth}px`,
    // position: 'fixed',
    height: 'auto',
    maxHeight: `calc(${height}px  - ${theme.heights.appBar})`,
    top: theme.heights.appBar,
  }),
  drawerPaperRoot: {
    right: '0',
    position: 'absolute',
  },
  drawerHeader: {
    display: 'flex',
    position: 'sticky',
    top: '0',
    zIndex: ' 1500',
    background: ' #FFF',

    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...theme.mixins.toolbar,
    color: theme.palette.grey[800],
    minHeight: '64px',
  },
  containerStyle: {
    width: '25%',
  },
  notification: { width: '400%' },
  delete: { width: '100%', height: '100%', marginLeft: ' 300%' },
  iconDel: { fontSize: '2.5rem' },
}));

const Notifications = ({ event, children }) => {
  const height = useHeight();
  const classes = useStyles({ height });
  const { remove, setEventAsRed } = useConnect();

  return (
    <Box
      onMouseOver={() => !event.readAt && setEventAsRed(event._id)}
      onFocus={() => !event.readAt && setEventAsRed(event._id)}
    >
      <SwipeableViews
        enableMouseEvents
        containerStyle={{ width: '25%' }}
        slideStyle={{
          overflow: 'visible',
        }}
      >
        <Box className={classes.notification}>{children}</Box>
        <Button variant="contained" color="secondary" onClick={() => remove(event)} className={classes.delete}>
          <DeleteIcon className={classes.iconDel} />
        </Button>
      </SwipeableViews>
    </Box>
  );
};

Notifications.propTypes = {
  event: PropTypes.object,
  children: PropTypes.node,
};

export default Notifications;
