import React from 'react';
import { object, element } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocale } from '../../selectors/language';

const LanguageProvider = ({ messages, children }) => {
  const locale = useSelector(getLocale);
  return (
    <IntlProvider key={locale} defaultLocale="fr" locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  messages: object,
  children: element.isRequired,
};

export default LanguageProvider;
