import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import LanguageProvider from './components/LanguageProvider';
import routes from './routes';
import { RoutesProvider } from './components/RoutesProvider';
import App from './components/App';
import configureStore from './store/configureStore';
import themeApp from './theme';
import api from '../lib/api';
import { getToken } from './selectors/auth';
import { TOKEN } from './ducks/auth';
import actions from './actions';
import * as serviceWorker from './serviceWorker';
import { MODE } from '../lib/models/trips';

const token = localStorage.getItem(TOKEN);
const history = createBrowserHistory();
const initialState = {
  language: {
    locale: 'fr',
  },
  tunnel: {
    currentStep: 0,
    currentMode: MODE.create,
  },
};
const store = configureStore(initialState);
api.config({ getBearer: () => getToken(store.getState()) });

const theme = themeApp;

const render = () => {
  const ROOT = (
    <StylesProvider injectFirst>
      <RoutesProvider value={routes}>
        <Provider store={store}>
          <LanguageProvider messages={window.I18N || {}}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router history={history}>
                <App store={store} />
              </Router>
            </ThemeProvider>
          </LanguageProvider>
        </Provider>
      </RoutesProvider>
    </StylesProvider>
  );

  ReactDOM.render(ROOT, document.getElementById('root'));
};
const run = async () => {
  if (token) {
    store.dispatch(actions.auth.addToken(token));
    await api.auth
      .getUser()
      .then(user => store.dispatch(actions.auth.signedIn(token, user)))
      .catch(() => {
        store.dispatch(actions.auth.signOut());
      });
  }
  render();
};

serviceWorker.register();
run();
