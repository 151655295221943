import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { map, head, tail } from 'ramda';
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import * as turf from '@turf/helpers';
import { StaticMap } from 'react-map-gl';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { getOptimizedRoute } from '../../mapbox';
import { getCoordinates } from '../../../lib/models/routes';

const useStyles = makeStyles(() => ({
  container: {
    display: 'none',
    width: '100%',
    height: '100%',
  },
}));

const makePoint = coordinates => ({ type: 'Point', coordinates });

const getBBox = route => {
  const locations = map(coordinates => makePoint(coordinates), getCoordinates(route));
  const feature = turf.geometryCollection(locations);
  return bbox(feature);
};

const Map = ({ route, setRouteImage }) => {
  const WIDTH = 400;
  const HEIGHT = 200;
  const classes = useStyles();

  const mapRef = useCallback(
    node => {
      if (node && route) {
        const mb = node.getMap();
        mb.on('load', () => {
          mb.loadImage('/markerStart.png', (error, firstMarker) => {
            if (error) throw error;

            mb.addImage('firstmarker', firstMarker);

            mb.addSource('startingPoint', {
              type: 'geojson',
              data: {
                type: 'MultiPoint',
                coordinates: [head(getCoordinates(route))],
              },
            });

            mb.addLayer({
              id: 'firstMarkerarkersLocations',
              type: 'symbol',
              source: 'startingPoint',
              layout: {
                'icon-image': 'firstmarker',
                'icon-size': 0.5,
                'icon-offset': [0, -24],
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
              },
              paint: {
                'icon-opacity': 1,
              },
            });
          });

          mb.loadImage('/marker.png', (error, image) => {
            if (error) throw error;

            mb.addImage('marker', image);

            mb.addSource('locations', {
              type: 'geojson',
              data: {
                type: 'MultiPoint',
                coordinates: tail(getCoordinates(route)),
              },
            });

            mb.addLayer({
              id: 'markersLocations',
              type: 'symbol',
              source: 'locations',
              layout: {
                'icon-image': 'marker',
                'icon-size': 0.5,
                'icon-offset': [0, -24],
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
              },
              paint: {
                'icon-opacity': 0.5,
              },
            });

            const nothing = turf.featureCollection([]);

            mb.addSource('route', {
              type: 'geojson',
              data: nothing,
            });

            mb.addLayer(
              {
                id: 'routeline-active',
                type: 'line',
                source: 'route',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#3887be',
                  'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12],
                },
              },
              'waterway-label',
            );

            mb.addLayer(
              {
                id: 'routearrows',
                type: 'symbol',
                source: 'route',
                layout: {
                  'symbol-placement': 'line',
                  'text-field': '▶',
                  'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
                  'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
                  'text-keep-upright': false,
                },
                paint: {
                  'text-color': '#3887be',
                  'text-halo-color': 'hsl(55, 11%, 96%)',
                  'text-halo-width': 3,
                },
              },
              'waterway-label',
            );

            getOptimizedRoute(route).then(data => {
              const routeGeoJSON = data.routes[0]
                ? turf.featureCollection([turf.feature(data.routes[0].geometry)])
                : nothing;
              mb.getSource('route').setData(routeGeoJSON);
            });
          });
        });

        mb.on('idle', () => {
          const url = node
            .getMap()
            .getCanvas()
            .toDataURL('image/png');
          // api.routes.update({ _id: route._id, image: url });
          setRouteImage(url);
        });
      }
    },
    [route], // eslint-disable-line
  );

  if (!route) return null;

  const viewport = {
    width: WIDTH,
    height: HEIGHT,
    longitude: getCoordinates(route)[0][0],
    latitude: getCoordinates(route)[0][1],
  };
  const [minLng, minLat, maxLng, maxLat] = getBBox(route);
  const mviewport = new WebMercatorViewport(viewport);
  const { longitude, latitude, zoom } = mviewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
    padding: 20,
    offset: [0, 20],
  });

  return (
    <Box className={classes.container}>
      <StaticMap
        {...viewport}
        longitude={longitude}
        latitude={latitude}
        zoom={zoom < 8 ? zoom : 8}
        width={WIDTH}
        height={HEIGHT}
        ref={mapRef}
        preserveDrawingBuffer
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      />
    </Box>
  );
};
Map.propTypes = {
  route: PropTypes.object,
  setRouteImage: PropTypes.func,
};
export default Map;
