import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'components.Home.create',
    defaultMessage: 'Créer une balade',
  },
  join: {
    id: 'components.Home.join',
    defaultMessage: 'Voir la carte',
  },
  title: {
    id: 'components.Home.title',
    defaultMessage: 'Prêt pour une escapade?',
  },
  subTitle: {
    id: 'components.Home.subTitle',
    defaultMessage: 'Rejoins une balade',
  },
  destinations: {
    id: 'components.Home.destinations',
    defaultMessage: 'Mes Trajets',
  },
  profile: {
    id: 'components.Home.profil',
    defaultMessage: 'Mon profil',
  },
  edit: {
    id: 'components.Home.edit',
    defaultMessage: 'Créer',
  },
  catalog: {
    id: 'components.Home.catalog',
    defaultMessage: 'Catalogue',
  },
  gathering: {
    id: 'components.Home.catalog',
    defaultMessage: 'Rassemblement',
  },
  myTrips: {
    id: 'components.Home.myTrips',
    defaultMessage: 'Mes Trajets',
  },
  advice: {
    id: 'components.Home.advice',
    defaultMessage: 'Conseils',
  },
  chat: {
    id: 'components.Home.chat',
    defaultMessage: 'Tchat',
  },
  friends: {
    id: 'components.Home.friends',
    defaultMessage: 'Amis',
  },
  accept: {
    id: 'components.Home.accept',
    defaultMessage: 'Accepter',
  },
  refuse: {
    id: 'components.Home.refuse',
    defaultMessage: 'Réfuser',
  },
  fullScreenMessage: {
    id: 'components.Home.fullScreenMessage',
    defaultMessage: 'Voulez-vous activer le mode plein ecran ?',
  },
  welcome: {
    id: 'components.Home.welcome',
    defaultMessage: 'Bienvenue!',
  },
  connectToContinue: {
    id: 'components.Home.connectToContinue',
    defaultMessage: 'Connecte toi pour continuer',
  },
  supportUs: {
    id: 'components.Home.supportUs',
    defaultMessage: 'Soutiens-nous !',
  },
  premiumDrawerTitle: {
    id: 'components.Home.premiumDrawerTitle',
    defaultMessage:
      "Encore {days} jours d'attente avant de pouvoir créer ou rejoindre une nouvelle balade {br} {br} <b>Impatient ?</b> ",
  },
  premiumDrawerText: {
    id: 'components.Home.premiumText',
    defaultMessage: 'Pour continuer sans attendre !',
  },
  gatheringTrip: {
    id: 'components.Home.gatheringTrip',
    defaultMessage: 'Rassemblement',
  },
});
