import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getToken } from '../../selectors/auth';
import { getHomeTrips } from '../../selectors/homeTrips';
import actions from '../../actions';
import { getIsNative } from '../../selectors/native';
import { NATIVE_ACTIONS } from '../../../lib/models/native';
import { getRoute } from '../../selectors/routes';

export const useConnect = Routeid => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    token: useSelector(getToken),
    route: useSelector(getRoute(Routeid)),
    clearTunnel: () => dispatch(actions.tunnel.clearTunnel()),
    isNative: useSelector(getIsNative),
    trips: useSelector(getHomeTrips),
    loadTrips: () => dispatch(actions.homeTrips.loadSuggestedTrips()),
    openExternalLink: payload =>
      dispatch(actions.native.postMessage({ type: NATIVE_ACTIONS.START_NAVIGATION, payload })),
  };
};

export const useCollapseMenu = () => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(prev => !prev);
  };
  return { checked, handleChange };
};

export const useLoginPopup = () => {
  const [loginPopup, setLoginPopup] = useState(false);

  return [loginPopup, setLoginPopup];
};

export const useDrawer = () => {
  const [drawer, setDrawer] = useState(false);
  return [drawer, setDrawer];
};

export const usePremiumDrawer = () => {
  const [premiumDrawer, setPremiumDrawer] = useState(false);
  return [premiumDrawer, setPremiumDrawer];
};
export const useSelectedTrip = () => {
  const [selectedTrip, setSelectedTrip] = useState(0);
  return [selectedTrip, setSelectedTrip];
};
