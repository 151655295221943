import { defineMessages } from 'react-intl';

export default defineMessages({
  addressMail: {
    id: 'components.addNewUser.addressMail',
    defaultMessage: 'Adresse mail',
  },
  unvalidEmail: {
    id: 'components.addNewUser.unvalidEmail',
    defaultMessage: "Le format de l'e-mail est incorrect",
  },
  required: {
    id: 'components.addNewUser.required',
    defaultMessage: 'Le champ ne peut pas être vide',
  },
  free: {
    id: 'components.addNewUser.free',
    defaultMessage: 'Gratuit',
  },
  premium: {
    id: 'components.addNewUser.premium',
    defaultMessage: 'Premium',
  },
  validateButton: {
    id: 'components.addNewUser.validateButton',
    defaultMessage: 'confirmer',
  },
  update: {
    id: 'components.addNewUser.update',
    defaultMessage: 'update',
  },
  type: {
    id: 'components.addNewUser.type',
    defaultMessage: 'type',
  },
  title: {
    id: 'components.addNewUser.title',
    defaultMessage: 'Créer un nouvel utilisateur',
  },
  userExistTitle: {
    id: 'components.addNewUser.userExistTitle',
    defaultMessage: "L'utilisateur existe déjà",
  },
  userExistText: {
    id: 'components.addNewUser.userExistText',
    defaultMessage: 'Essayez avec un autre e-mail',
  },
  accountCreated: {
    id: 'components.addNewUser.accountCreated',
    defaultMessage: 'le compte a été créé',
  },
  accountUpdated: {
    id: 'components.addNewUser.accountUpdated',
    defaultMessage: 'le compte a été mis à jour',
  },
  role: {
    id: 'components.addNewUser.role',
    defaultMessage: 'Role',
  },
  admin: {
    id: 'components.addNewUser.admin',
    defaultMessage: 'Admin',
  },
  endDate: {
    id: 'components.addNewUser.endDate',
    defaultMessage: 'Date de fin',
  },
});
