import { compose, prop, propOr, values } from 'ramda';
import { createSelector } from 'reselect';

const root = prop('bikers');

export const getBikers = createSelector(
  root,
  propOr({}, 'data'),
);

export const getBikersCount = createSelector(
  root,
  propOr({}, 'bikersCounts'),
);

export const getBikersArray = createSelector(
  root,
  compose(
    values,
    propOr([], 'data'),
  ),
);
export const getBiker = id =>
  createSelector(
    getBikers,
    prop(id),
  );
