import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [height, setHeight] = useState(window.innerHeight); //eslint-disable-line
  const changescreenHeight = () => setHeight(window.innerHeight);

  useEffect(() => {
    changescreenHeight();
    window.addEventListener('resize', changescreenHeight);
    return () => window.removeEventListener('resize', changescreenHeight);
  }, []);
  return window.innerHeight;
};

export default useWindowSize;
