import { defineMessages } from 'react-intl';

export default defineMessages({
  comot: {
    id: 'components.Footer.comot',
    defaultMessage: 'Comot',
  },
  comotText: {
    id: 'components.Footer.comotText',
    defaultMessage: 'Ne roulez plus seul !',
  },

  comotMiddleText: {
    id: 'components.Footer.comotMiddleText',
    defaultMessage: 'Nous avons hâte de rouler avec toi!',
  },
  allRightsReserved: {
    id: 'components.Footer.allRightsReserved',
    defaultMessage: '© Comot {year}',
  },
  findUs: {
    id: 'components.Footer.findUs',
    defaultMessage: 'Retrouve nous également sur les réseaux sociaux :',
  },
  subFooter: {
    id: 'components.Footer.subFooter',
    defaultMessage:
      "Comot est une application créée par des motards pour les motards. Nous travaillons sur l'application pendant notre temps libre et sommes toujours à l'écoute de vos avis afin de l'améliorer.",
  },
});
