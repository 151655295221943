import { path, equals, prop } from 'ramda';
import { isFuture, differenceInDays, addDays } from 'date-fns';

export const AVATAR_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extraLarge',
};

export const ACCOUNT_STATUSES = {
  removed: 'removed',
  active: 'active',
  uncomplete: 'uncomplete',
  unverified: 'unverified',
};

export const ROLES = {
  admin: 'admin',
};

export const SPREMIUM_TYPE = {
  granted: 'granted',
  payed: 'payed',
};

export const ACCOUNT_TYPES = { PREMIUM: 'premium', FREE: 'free' };

export const fullname = ({ firstname, lastname, userName } = {}) =>
  userName ? userName || '' : `${firstname || ''} ${lastname || ''}`;

export const getLocation = biker =>
  path(['location', 'coordinates'], biker) && {
    longitude: path(['location', 'coordinates', 0], biker),
    latitude: path(['location', 'coordinates', 1], biker),
  };
export const isLocated = getLocation;

export const isLoggedUser = (user, biker) => equals(prop('_id', user), biker._id);

export const isPremiumUserGranted = user => prop('expiresAt', user) && isFuture(new Date(prop('expiresAt', user)));

export const isSubscriber = user =>
  path(['subscription', 'endDate'], user) && isFuture(new Date(path(['subscription', 'endDate'], user)));

export const isPremiumUser = user => isPremiumUserGranted(user) || isSubscriber(user);

export const isFreeUser = user => !isPremiumUser(user);

export const tripsLimitReached = user =>
  prop('lastTripDate', user) && differenceInDays(new Date(), new Date(prop('lastTripDate', user))) < 45;

export const daysLeftTillNewTripAllower = user =>
  (prop('lastTripDate', user) && differenceInDays(addDays(new Date(prop('lastTripDate', user)), 45), new Date())) || 0;

export const hasCanceledSubscription = user =>
  prop('subscription', user) && path(['subscription', 'status'], user) === 'canceled';
