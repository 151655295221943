import { prop, propOr, compose, values, map, filter, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { getBikers } from './bikers';
import { fullname } from '../../lib/models/bikers';

const root = prop('friends');

export const getFriendArray = createSelector(
  root,
  compose(
    values,
    propOr([], 'data'),
  ),
);

export const getFriends = createSelector(
  root,
  propOr({}, 'data'),
);

export const filterFriends = searchInput =>
  createSelector(
    getFriendArray,
    getBikers,
    (friends, bikers) =>
      compose(
        filter(friend => {
          if (isEmpty(searchInput)) return friend;
          const regex = new RegExp(`^(${searchInput}|${searchInput}.+|.+${searchInput}|.+${searchInput}+)`, 'i');
          return fullname(friend.biker).match(regex);
        }),
        map(friend => ({ ...friend, biker: bikers[prop('recipient', friend)] })),
      )(friends),
  );

export const getFriend = id =>
  createSelector(
    getFriends,
    prop(id),
  );

export const GetOtherBikers = createSelector(
  root,
  compose(
    values,
    propOr([], 'otherBikers'),
  ),
);
