import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#060632',
    },
    secondary: {
      main: '#960032',
    },
    success: {
      main: '#13844F',
      dark: '#0c5e38',
    },
    pending: {
      main: '#FFC800',
    },
    appBar: {
      main: '#000028',
      dark: '#000020',
    },
    subAppBar: {
      main: '#fafafa',
    },
  },
  heights: {
    appBar: '64px',
    tunnelNavigation: '48px',
  },
});

export default theme;
