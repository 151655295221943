import { compose, reduce, propOr, path, set, lensProp } from 'ramda';
import { request } from './utils';
import { BIKER_MOVED } from '../../lib/models/events';
export const TRIPPERS_LOCATIONS = '@tripper:TRIPPERS_LOCATIONS';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case TRIPPERS_LOCATIONS:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v.bikerId]: v }), {}),
        propOr([], 'locations'),
      )(action);
      return { ...state, data };
    case BIKER_MOVED:
      data = set(
        lensProp(action.bikerId),
        {
          ...path(['data', action.bikerId], state),
          updatedAt: action.createdAt,
          location: { ...action.location },
        },
        state.data,
      );
      if (action.location)
        return {
          ...state,
          data,
        };
      return {
        ...state,
      };
    default:
      return state;
  }
};

const loadTrippersLocations = id => dispatch => {
  request(dispatch, { method: 'trippers:loadTrippersPosition', id }).then(locations => {
    dispatch({ type: TRIPPERS_LOCATIONS, locations });
    return locations;
  });
};

const actions = { loadTrippersLocations };
export default { reducer, actions };
