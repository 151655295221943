import { reduce, toPairs, compose, find, filter, prop } from 'ramda';
import Home from './components/Home/Loadable';
import CreateTrip from './components/CreateTrip/Loadable';
import JoinTrip from './components/JoinTrip/Loadable';
import ImageMap from './components/ImageMap';
import MyTrips from './components/MyTrips/Loadable';
import JoinedTrips from './components/JoinedTrips/Loadable';
import Profile from './components/Profile/Loadable';
import Trip from './components/Trip/Loadable';
import MyTripDetails from './components/MyTripDetails/Loadable';
import Chat from './components/Chat/Loadable';
import Departure from './components/Departure/Loadable';
import ChatWidget from './components/ChatWidget/Loadable';
import Premium from './components/Premium/Loadable';
import PremiumCheckout from './components/PremiumCheckout/Loadable';
import Tutos from './components/Tutos/Loadable';
import TutosAboutUs from './components/TutoAboutUs/Loadable';
import Friends from './components/Friends/Loadable';
import ComotLogin from './components/ComotLogin/Loadable';
import EditPassword from './components/EditPassword/Loadable';
import Share from './components/Share/Loadable';
import ResetPassword from './components/ResetPassword/Loadable';
import AccountInfo from './components/AccountInfo/Loadable';
import Plus from './components/Plus/Loadable';
import AccountConfirmation from './components/AccountConfirmation/Loadable';
import BackOffice from './components/BackOffice';
import BackOfficeAdd from './components/BackOfficeAddUser';
import PremiumNew from './components/PremiumNew/Loadable';

const routes = {
  createfromCatalog: {
    path: '/catalog',
    component: CreateTrip,
    auth: true,
  },
  createNewRoute: {
    path: '/new_trip',
    component: CreateTrip,
    auth: true,
    premium: true,
  },
  editTrip: {
    path: '/edit_trip/:id',
    component: CreateTrip,
    auth: true,
  },
  redoTrip: {
    path: '/redo_trip/:id',
    component: CreateTrip,
    auth: true,
    premium: true,
  },
  imageMap: {
    path: '/image_map/:routeId',
    component: ImageMap,
  },
  joinTrip: {
    path: '/join_trip',
    component: JoinTrip,
    auth: true,
    premium: true,
  },
  myTripDetails: {
    path: '/my_trips/:id',
    component: MyTripDetails,
    auth: true,
  },
  myTrips: {
    path: '/my_trips',
    component: MyTrips,
    auth: true,
  },
  joinedTrips: {
    path: '/joined_trips',
    component: JoinedTrips,
    auth: true,
  },
  bikerProfile: {
    path: '/profile/:id',
    component: Profile,
    auth: true,
    excat: true,
  },
  profile: {
    path: '/profile',
    component: Profile,
    auth: true,
  },
  premiumOld: {
    path: '/premiumOld',
    component: Premium,
    auth: true,
  },
  checkout: {
    path: '/checkout/:id',
    component: PremiumCheckout,
    auth: true,
  },
  departure: {
    path: '/departure/:id',
    component: Departure,
    auth: true,
  },
  trip: {
    path: '/trip/:id',
    component: Trip,
    auth: true,
  },
  chats: {
    path: '/chats',
    component: Chat,
    auth: true,
  },
  TutosAboutUs: {
    path: '/faq/about_us',
    component: TutosAboutUs,
    exact: true,
    auth: false,
  },
  Tutos: {
    path: '/faq',
    component: Tutos,
    auth: false,
  },
  friends: {
    path: '/friends',
    component: Friends,
    auth: true,
  },
  chat: {
    path: '/chat/:id',
    component: ChatWidget,
    auth: true,
    exact: true,
  },
  comotLogin: {
    path: '/comotLogin',
    component: ComotLogin,
    auth: false,
    exact: true,
  },
  passwordReset: {
    path: '/resetPassword/:id',
    component: ComotLogin,
    auth: false,
    exact: true,
  },
  editPassword: {
    path: '/editPassword',
    component: EditPassword,
    auth: true,
  },
  resetPassword: {
    path: '/resetPassword',
    component: ResetPassword,
    auth: true,
  },
  accountInfo: {
    path: '/accountInfo',
    component: AccountInfo,
    auth: true,
    exact: true,
  },
  emailConfirmation: {
    path: '/accountConfirmation/:id',
    component: AccountConfirmation,
    exact: true,
  },
  accountConfirmation: {
    path: '/accountConfirmation',
    component: AccountConfirmation,
    auth: true,
    exact: true,
  },
  share: {
    path: '/share/:id',
    component: Share,
    auth: false,
    exact: false,
  },
  plus: {
    path: '/plus',
    component: Plus,
    auth: true,
    exact: true,
  },
  premium: {
    path: '/premium',
    component: PremiumNew,
    auth: true,
    exact: true,
  },
  backOffice: {
    path: '/backoffice',
    component: BackOffice,
    auth: true,
    admin: true,
    exact: true,
  },
  backOfficeAdd: {
    path: '/backoffice/add',
    component: BackOfficeAdd,
    auth: true,
    admin: true,
    exact: true,
  },
  backOfficeEdit: {
    path: '/backoffice/add/:id',
    component: BackOfficeAdd,
    auth: true,
    admin: true,
    exact: true,
  },

  home: {
    path: '/',
    component: Home,
    default: true,
    auth: true,
  },
};

const exportedRoutes = compose(
  reduce((acc, [name, r]) => [...acc, { ...r, name }], []),
  toPairs,
  filter(prop('path')),
)(routes);

export const getDefaultRoute = () => find(route => route.default, exportedRoutes) || null;
export const getRouteByName = name => routes[name];
export const getRouteByPath = path => find(r => r.path === path, exportedRoutes);
export const getPathByName = (name, param) => {
  const path = prop('path', getRouteByName(name));
  return param ? `${path.replace(':id', param)}` : path;
};
const isAuthRequired = route => route && route.auth;
const isAdminRequired = route => route && route.admin;
const isPremiumRequired = route => route && route.premium;

const ROUTES = {
  ...routes,
  all: exportedRoutes,
  getDefaultRoute,
  getRouteByPath,
  getPathByName,
  isAuthRequired,
  isAdminRequired,
  isPremiumRequired,
};

export default ROUTES;
