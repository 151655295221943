import { request } from './utils';

const SUGGESTED_TRIPS_LOADED = '@homeTrips:SUGGESTED_TRIPS_LOADED';

const reducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case SUGGESTED_TRIPS_LOADED:
      return { ...state, data: action.trips };

    default:
      return state;
  }
};

const loadSuggestedTrips = () => dispatch => {
  return request(dispatch, { method: 'trips:loadSuggestedTrips' }).then(trips => {
    dispatch({ type: SUGGESTED_TRIPS_LOADED, trips });
    return trips;
  });
};

const actions = {
  loadSuggestedTrips,
};

export default { reducer, actions };
