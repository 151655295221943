import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
const SelectField = ({
  input: { value, name, onChange, ...restInput },
  label,
  meta,
  toggleDatePicker,
  datePickerStatus,
  ...rest
}) => {
  const showError = meta.error && meta.touched && meta.dirty;
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={event => {
          onChange(event.target.value);
          if (toggleDatePicker) {
            if (event.target.value === 'period') toggleDatePicker(true);
            else toggleDatePicker(false);
          }
        }}
        value={value}
        helpertext={showError ? meta.error || meta.submitError : undefined}
        error={showError}
      />
      {meta.error && meta.touched && <Typography style={{ color: 'red' }}>{meta.error}</Typography>}
    </FormControl>
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  datePickerStatus: PropTypes.bool,
  toggleDatePicker: PropTypes.func,
};

export default SelectField;
