export const NATIVE_ACTIONS = {
  SGININ_WITH_GOOGLE: 'SGININ_WITH_GOOGLE',
  SGININ_WITH_FACEBOOK: 'SGININ_WITH_FACEBOOK',
  SGININ_WITH_EMAIL: 'SGININ_WITH_EMAIL',
  IS_NATIVE: 'IS_NATIVE',
  SET_TOKEN: 'SET_TOKEN',
  REMOVETOKEN_TOKEN: 'REMOVETOKEN_TOKEN',
  START_LOCATION_TRACKING: 'START_LOCATION_TRACKING',
  STOP_LOCATION_TRACKING: 'STOP_LOCATION_TRACKING',
  TRACK_MY_LOCATION: 'TRACK_MY_LOCATION',
  VALIDATE_USER: 'VALIDATE_USER',
  SHARE_LINK: 'SHARE_LINK',
  SHARE_TRIP: 'SHARE_TRIP',
  START_NAVIGATION: 'START_NAVIGATION',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  SHOW_ADD: 'SHOW_ADD',
  EXPORT_GPX: 'EXPORT_GPX',
};
