import { prop, propOr, values, compose, filter, find, head } from 'ramda';
import { createSelector } from 'reselect';
import { getUser } from './auth';
import * as wheelsDM from '../../lib/models/wheels';

const root = prop('trips');

export const getTrips = createSelector(
  root,
  compose(
    values,
    propOr([], 'data'),
  ),
);

export const getTrip = id =>
  createSelector(
    getTripsObj,
    propOr('', id),
  );

export const getTripsObj = createSelector(
  root,
  propOr([], 'data'),
);

export const getAcceptedTrippers = tripId =>
  createSelector(
    getTrip(tripId),
    trip =>
      filter(
        tripper => prop('status', tripper) === wheelsDM.STATUS.accepted && prop('tripId', tripper) === tripId,
        trip.trippers || [],
      ),
  );

export const getTripper = (bikerId, tripId) =>
  createSelector(
    getTrip(tripId),
    trip =>
      find(tripper => prop('tripId', tripper) === tripId && prop('bikerId', tripper) === bikerId)(trip.trippers || []),
  );

export const getUserTripper = tripId =>
  createSelector(
    getTrip(tripId),
    getUser,
    (trip, user) =>
      compose(
        head,
        filter(tripper => prop('bikerId', tripper) === prop('_id', user) && tripper.tripId === tripId),
      )(trip.trippers || []) || {},
  );
