import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { useIntl, FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useConnect } from './hooks';
import useHeight from '../../hooks/useHeight';
import NavBack from '../Nav/Back';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  backgourdContainer: ({ height }) => ({
    backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  container: ({ height }) => ({
    backgroundColor: '#FFFFFFCC',
    paddingBottom: theme.spacing(4),
    minHeight: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  divider: {
    height: '3px',
    borderRadius: '3px',
    margin: `${theme.spacing(1)}px auto`,
    width: '70%',
    maxWidth: '200px',
  },
  successButton: {
    marginTop: theme.spacing(1),
    margin: 'auto',
    color: '#FFF',
    borderRadius: '20px',
    height: '64px',
    backgroundColor: theme.palette.success.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '2px solid',
      borderColor: theme.palette.success.dark,
      content: '""',
    },
    '&:hover,&active,&focus': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const Backlog = () => {
  const height = useHeight();
  const intl = useIntl();
  const classes = useStyles({ height });
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const { sendNotifications } = useConnect();

  return (
    <>
      <div className={classes.backgourdContainer}>
        <div className={classes.container}>
          <NavBack title={intl.formatMessage(messages.title)} />
          <Container direction="column">
            <Grid item>
              <TextField
                variant="filled"
                label={intl.formatMessage(messages.notificationTitle)}
                fullWidth
                onChange={e => setTitle(e.target.value)}
                value={title}
              />
            </Grid>
            <TextField
              variant="filled"
              label={intl.formatMessage(messages.notificationContent)}
              fullWidth
              rows={4}
              multiline
              onChange={e => setBody(e.target.value)}
              value={body}
            />
            <Grid item sm={4}>
              <Button
                className={classes.successButton}
                variant="contained"
                onClick={() => sendNotifications({ title, body })}
                disabled={!title || !body}
              >
                <Add />
                <FormattedMessage {...messages.sendNotification} />
              </Button>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Backlog;
