import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import api from '../../../lib/api';

const useStyles = makeStyles(() => ({
  image: {
    height: 'auto',
    width: '340px',
    padding: '10px',
    borderRadius: '40px',
  },
  skeleton: {
    height: '170px',
  },
}));

const MapImage = ({ mapId }) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    api({ method: 'routes:getMapImage', _id: mapId })
      .then(img => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(img);
      })
      .catch(e => e);
  }, [mapId]);
  if (imageSrc) return <img data-testid="loaded" src={imageSrc} alt="map" className={classes.image} />;
  return <Skeleton variant="rect" data-testid="loading" width="100%" className={classes.skeleton} />;
};

MapImage.propTypes = {
  mapId: PropTypes.string,
};

export default MapImage;
