import React from 'react';
import propTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import messages from './messages';
import { ACCOUNT_STATUSES, ACCOUNT_TYPES, ROLES, isPremiumUser } from '../../../lib/models/bikers';
const useStyles = makeStyles(theme => ({
  deleted: {
    color: theme.palette.secondary.main,
  },
}));
const UserElements = ({ biker, removeAccount, editBiker }) => {
  const classes = useStyles();
  return (
    <TableRow key={biker._id}>
      <TableCell align="left" component="th" scope="biker">
        {biker._id}
      </TableCell>
      <TableCell align="left">{biker.email}</TableCell>
      <TableCell align="left">{biker.userName}</TableCell>
      <TableCell
        align="left"
        className={clsx({
          [classes.deleted]: biker.status === ACCOUNT_STATUSES.removed,
        })}
      >
        {' '}
        {biker.status && <FormattedMessage {...messages[biker.status]} />}
      </TableCell>
      <TableCell align="left">
        {' '}
        <FormattedMessage {...messages[isPremiumUser(biker) ? ACCOUNT_TYPES.PREMIUM : ACCOUNT_TYPES.FREE]} />
      </TableCell>
      <TableCell align="left"> {biker.role === ROLES.admin && <FormattedMessage {...messages.admin} />}</TableCell>
      <TableCell align="left">
        {biker.createdAt && (
          <FormattedDate value={new Date(biker.createdAt)} year="numeric" month="long" day="2-digit" />
        )}
      </TableCell>
      <TableCell align="left">
        <IconButton onClick={() => editBiker(biker._id)} color="primary" aria-label="upload picture" component="span">
          <SettingsIcon />
        </IconButton>

        <IconButton
          disabled={biker.status === ACCOUNT_STATUSES.removed}
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => removeAccount(biker._id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

UserElements.propTypes = {
  biker: propTypes.object,
  removeAccount: propTypes.func,
  editBiker: propTypes.func,
};

export default UserElements;
