import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import FacebookLogin from 'react-facebook-login';
import messages from './messages';
import { useConnect } from './hooks';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

const useStyles = makeStyles(theme => ({
  svgStyle: {
    left: '-1px',
    position: 'absolute',
  },
  fbBtn: {
    zIndex: '-1',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '45px',
    background: '#3b5998',
    borderRadius: theme.spacing(10),
    justifyContent: 'center',
    color: '#FFF',
    '& .fa-facebook-square': {
      position: 'absolute',
      left: theme.spacing(1.5),
      fontSize: '26px',
    },
  },
}));

const FacebookButton = ({ handleClose }) => {
  const intl = useIntl();
  const classes = useStyles();
  // eslint-disable-next-line
  const { signInWithFacebook, isNative, postMessage } = useConnect();
  // eslint-disable-next-line
  const handleNativeClick = () => {
    postMessage({ type: NATIVE_ACTIONS.SGININ_WITH_FACEBOOK });
    handleClose();
  };

  return isNative ? (
    <button
      disabled
      type="button"
      className={classes.fbBtn}
      // onClick={() => handleNativeClick(NATIVE_ACTIONS.SGININ_WITH_FACEBOOK)}
    >
      <svg
        className={classes.svgStyle}
        width="40"
        height="40"
        clipRule="evenodd"
        fillRule="evenodd"
        viewBox="0 0 560 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m410.096 200.048c0-71.818-58.23-130.048-130.048-130.048s-130.048 58.23-130.048 130.048c0 64.905 47.55 118.709 109.73 128.476v-90.875h-33.029v-37.601h33.029v-28.658c0-32.59 19.422-50.604 49.122-50.604 14.228 0 29.115 2.542 29.115 2.542v32.005h-16.405c-16.148 0-21.196 10.022-21.196 20.318v24.396h36.064l-5.761 37.601h-30.304v90.875c62.18-9.749 109.73-63.553 109.73-128.476z"
          fill="#1977f3"
        />
        <path
          d="m330.67 237.648 5.761-37.601h-36.064v-24.396c0-10.278 5.029-20.318 21.196-20.318h16.405v-32.005s-14.886-2.542-29.115-2.542c-29.7 0-49.122 17.996-49.122 50.604v28.658h-33.029v37.601h33.029v90.875c6.62 1.041 13.405 1.572 20.318 1.572s13.698-.549 20.318-1.572v-90.875h30.304z"
          fill="#fefefe"
        />
      </svg>
      {intl.formatMessage(messages.facebook)}
    </button>
  ) : (
    <FacebookLogin
      disabled
      appId="516650465844929"
      fields="id,first_name,last_name,picture,email"
      scope="public_profile,email"
      icon="fab fa-facebook-square"
      version="5.0"
      disableMobileRedirect
      size="small"
      textButton={intl.formatMessage(messages.facebook)}
      // callback={response => {
      //   signInWithFacebook(response).then(() => handleClose());
      // }}
      cssClass={classes.fbBtn}
    />
  );
};

FacebookButton.propTypes = {
  handleClose: PropTypes.func,
};

export default FacebookButton;
