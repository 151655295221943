import React, { useEffect } from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { useConnect, useBikerImage, useLoading } from './hooks';
import api from '../../../lib/api';
import messages from './messages';
import useRoutes from '../../hooks/routes';
import { isLoggedUser, AVATAR_SIZES, fullname } from '../../../lib/models/bikers';

const useStyles = makeStyles({
  [AVATAR_SIZES.small]: {
    height: '1.5rem',
    width: '1.5rem',
  },
  [AVATAR_SIZES.medium]: {
    height: '2.5rem',
    width: '2.5rem',
  },
  [AVATAR_SIZES.large]: {
    height: '3.5rem',
    width: '3.5rem',
  },
  [AVATAR_SIZES.extraLarge]: {
    height: '6.5rem',
    width: '6.5rem',
  },
  avatar: {
    margin: 'auto',
  },
});

const AvatarComponent = ({ className, biker, redirect, size = AVATAR_SIZES.LARGE, hideDrawer }) => {
  const intl = useIntl();
  const classes = useStyles();
  // const [imageSrc, setImageSrc] = useImage();
  const { user } = useConnect();
  const history = useHistory();
  const routes = useRoutes();
  const [loading, setLoading] = useLoading(); //
  const [bikerImage, setBikerImage] = useBikerImage('/comot-blue.png');

  useEffect(() => {
    setLoading(true);
    if (prop('image', biker))
      api({ method: 'bikers:getBikerImage', _id: prop('_id', biker) })
        .then(img => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (!reader.result) return;
            setBikerImage(reader.result);
          };
          reader.readAsDataURL(img);
        })
        .then(() => setLoading(false))
        .catch(e => e);
  }, [prop(biker, '_id')]); // eslint-disable-line
  const redirectToProfile = e => {
    if (!redirect) return;
    e.stopPropagation();
    if (isLoggedUser(user, biker)) return history.push(routes.getPathByName('profile'));
    if (hideDrawer) hideDrawer();
    return history.push(routes.getPathByName('bikerProfile', biker._id));
  };
  const loadImage = () => {
    if (loading) return '/comot-blue.png';
    if (bikerImage || prop('picture', biker)) return bikerImage || prop('picture', biker);
    return '/comot-blue.png';
  };
  return biker ? (
    <Tooltip className={className} title={intl.formatMessage(prop('profile', messages))}>
      <Avatar
        referrerPolicy="no-referrer"
        onClick={redirectToProfile}
        alt={fullname(biker)}
        src={loadImage()}
        className={`${classes[size]} ${classes.avatar} `}
      />
    </Tooltip>
  ) : (
    <Skeleton className={`${classes[size]} ${classes.avatar} `} variant="circle" />
  );
};

AvatarComponent.propTypes = {
  className: PropTypes.object,
  biker: PropTypes.object,
  redirect: PropTypes.bool,
  size: PropTypes.string,
  hideDrawer: PropTypes.func,
};

export default AvatarComponent;
