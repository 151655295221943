import { includes } from 'ramda';
import { NATIVE_ACTIONS } from '../../lib/models/native';
import api from '../../lib/api';
import actions from '../actions'; // eslint-disable-line
import { emit } from './events'; // eslint-disable-line
import { getToken } from '../selectors/auth';
import { localize } from './location';

const reducer = (state = { isNative: true }, action = {}) => {
  switch (action.type) {
    case NATIVE_ACTIONS.IS_NATIVE:
      return { ...state, isNative: true };
    default:
      return state;
  }
};

export const handleNativeActions = ({ type, ...data }) => (dispatch, getState) => {
  if (type && includes('@events', type)) return dispatch(emit({ type, ...data }));
  switch (type) {
    case NATIVE_ACTIONS.VALIDATE_USER:
      return dispatch(actions.auth.signedIn(data.token, data));
    case NATIVE_ACTIONS.SGININ_WITH_GOOGLE:
      return dispatch(actions.auth.signInWithGoogle({ ...data, origin: 'native', ...data.user }));
    case NATIVE_ACTIONS.SGININ_WITH_EMAIL:
      return api.auth.addExpoToken(data);
    case NATIVE_ACTIONS.SGININ_WITH_FACEBOOK:
      return dispatch(actions.auth.signIn({ ...data, origin: 'native' }));
    case NATIVE_ACTIONS.IS_NATIVE: {
      const token = getToken(getState());
      if (token) dispatch(actions.events.loadAll());
      return dispatch({ type });
    }
    case NATIVE_ACTIONS.TRACK_MY_LOCATION:
      return dispatch(localize(data.coords));
    default:
      return null;
  }
};

export const postMessage = data => () => {
  // const isNative = getIsNative(getState());
  if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify(data));
};

const nativeActions = { handleNativeActions, postMessage };
export default { reducer, actions: nativeActions };
