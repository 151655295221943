import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.Nav.title',
    defaultMessage: 'Comot',
  },
  profile: {
    id: 'app.Nav.profil',
    defaultMessage: 'Mon Profil',
  },
  tripsNotifications: {
    id: 'app.Nav.tripsNotifications',
    defaultMessage: 'Notification de nouvelle balade',
  },
  searchRadius: {
    id: 'app.Nav.searchRadius',
    defaultMessage: 'Définir le rayon de recherche',
  },
  tutorials: {
    id: 'app.Nav.tutorials',
    defaultMessage: 'Tutoriels',
  },
  plus: {
    id: 'app.Nav.plus',
    defaultMessage: 'Plus',
  },
  shareComot: {
    id: 'app.Nav.shareComot',
    defaultMessage: 'Partager Comot',
  },
  disconnect: {
    id: 'app.Nav.disconnect',
    defaultMessage: 'Déconnexion',
  },
  dialogTitle: {
    id: 'app.Nav.dialogTitle',
    defaultMessage: 'Attention',
  },
  logoutDialogText: {
    id: 'app.Nav.logoutDialogText',
    defaultMessage: 'Voulez-vous vraiment vous déconnecter ?',
  },
  confirm: {
    id: 'app.Nav.confirm',
    defaultMessage: 'Valider',
  },
  backlog: {
    id: 'app.Nav.backlog',
    defaultMessage: 'Backlog',
  },
  passPremium: {
    id: 'app.Nav.passPremium',
    defaultMessage: 'Passer Premium',
  },
});
