import { assoc, compose, reduce, propOr, pluck, values, set, lensPath, prop, head, path } from 'ramda';
import { differenceInSeconds } from 'date-fns';
import { getAddress } from '../mapbox';
import { SIGNED_OUT } from './auth'; // eslint-disable-line
import { request } from './utils';
import { localize } from './location';
import { getcurrentTripId, getShareLocation } from '../selectors/ridingTrip';
import { EVENTS_LOADED } from './events'; //eslint-disable-line
import { FRIENDS_LOADED, FRIEND_LOADED } from './friends'; //eslint-disable-line
import { CHANNELS_LOADED } from './channels'; //eslint-disable-line
import { MESSAGES_LOADED } from './messages'; //eslint-disable-line
import { getLocation } from '../selectors/location';
import routes from './routes'; //eslint-disable-line
import bikers from './bikers'; //eslint-disable-line
import myTrips from './myTrips'; //eslint-disable-line
import trippersActions from './trippers'; //eslint-disable-line
import auth from './auth'; //eslint-disable-line
import { getUser } from '../selectors/auth';
const BIKER_LOADED = '@bikers:BIKER_LOADED';
const BIKERS_LOADED = '@bikers:BIKERS_LOADED';
const OVERIDE_BIKERS = '@bikers:OVERIDE_BIKERS';
const BIKERS_COUNT = '@bikers:BIKERS_COUNT';
const UPDATE_NOTIFICATIONS_RADIUS = '@bikers:UPDATE_NOTIFICATIONS_RADIUS';
export const BIKER_UPDATED = '@bikers:BIKER_UPDATED';
export const BIKER_ADDED = '@bikers:BIKER_ADDED';
export const ADMIN_BIKER_UPDATED = '@bikers:ADMIN_BIKER_UPDATED';

const reducer = (state = { data: {} }, action) => {
  let data;
  switch (action.type) {
    case BIKERS_LOADED:
    case EVENTS_LOADED:
    case FRIENDS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), { ...state.data }),
        propOr([], 'bikers'),
      )(action);
      return { ...state, data: { ...state.data, ...data } };
    case OVERIDE_BIKERS:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'bikers'),
      )(action);
      return { ...state, data: { ...data } };
    case BIKER_LOADED:
    case FRIEND_LOADED:
      return {
        ...state,
        data: assoc(action.biker._id, action.biker, state.data),
      };

    case BIKERS_COUNT:
      return {
        ...state,
        bikersCounts: action.bikersCounts,
      };
    case CHANNELS_LOADED:
    case MESSAGES_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), { ...state.data }),
        propOr([], 'bikers'),
        propOr([], 'data'),
      )(action);
      return { ...state, data };
    case UPDATE_NOTIFICATIONS_RADIUS:
      return {
        ...state,
        data: set(lensPath([action.userId, 'location', 'coordinates']), [...action.location.coordinates], state.data),
      };
    case BIKER_UPDATED:
      return {
        ...state,
        data: assoc(action.biker._id, action.biker, state.data),
      };
    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};

const loadOne = id => dispatch =>
  request(dispatch, { method: 'bikers:loadOne', id }).then(biker => {
    dispatch({ type: BIKER_LOADED, biker });
    return biker;
  });

const setLocation = position => (dispatch, getState) =>
  new Promise(resolve => {
    const state = getState();
    const tripId = getcurrentTripId(state);
    const location = getLocation(state);
    const shareLocation = getShareLocation(state);
    if (!location || differenceInSeconds(new Date(), new Date(prop('updatedAt', location))) > 1) {
      dispatch(localize(position));
      if (tripId && shareLocation) request(dispatch, { method: 'trippers:move', position, tripId });
    }
    resolve(position);
  });

const setBikers = biker => dispatch => dispatch({ type: BIKER_LOADED, biker });

const loadAll = options => dispatch =>
  request(dispatch, { method: 'bikers:loadAll', ...options }).then(bikers => {
    dispatch({ type: BIKERS_LOADED, bikers });
    return bikers;
  });

const loadAllByAdmin = (keyword, accountStatus) => dispatch =>
  request(dispatch, { method: 'bikers:findBikers', keyword, accountStatus }).then(bikers => {
    dispatch({ type: OVERIDE_BIKERS, bikers });
    return bikers;
  });

const loadBikersCount = accountStatus => dispatch =>
  request(dispatch, { method: 'bikers:getBikersCount', accountStatus }).then(bikersCounts => {
    dispatch({ type: BIKERS_COUNT, bikersCounts });
    return bikersCounts;
  });

export const update = biker => dispatch => {
  // eslint-disable-next-line
  return request(dispatch, { method: 'bikers:update', ...biker }).then(biker =>
    dispatch({ type: BIKER_UPDATED, biker }),
  );
};
export const adminUpdate = biker => dispatch => {
  // eslint-disable-next-line
  return request(dispatch, { method: 'bikers:adminUpdate', ...biker }).then(biker =>
    dispatch({ type: ADMIN_BIKER_UPDATED, biker }),
  );
};

export const addBiker = biker => dispatch => {
  return request(dispatch, { method: 'bikers:add', ...biker }).then(biker => dispatch({ type: BIKER_ADDED, biker }));
};

const loadTrips = statuses => dispatch => {
  const getTripLeaderIds = compose(
    values,
    pluck('leaderId'),
  );
  const getTripRouteIds = compose(
    values,
    pluck('routeId'),
  );
  return request(dispatch, { method: 'bikers:loadTrips', statuses })
    .then(({ trips, trippers }) => {
      dispatch(myTrips.actions.tripsLoaded(trips));
      dispatch(trippersActions.actions.trippersLoaded(trippers));
      return trips;
    })
    .then(trips => {
      const leaderIds = getTripLeaderIds(trips);
      const routeIds = getTripRouteIds(trips);
      return Promise.all([
        dispatch(routes.actions.loadAll({ ids: routeIds })),
        dispatch(bikers.actions.loadAll({ ids: leaderIds })),
      ]);
    });
};

const updateNotificationsRadius = location => (dispatch, getState) => {
  const { _id } = getUser(getState());
  return getAddress([location[0], location[1]]).then(response => {
    const address = compose(
      prop('place_name'),
      head,
      path(['data', 'features']),
    )(response);

    dispatch({
      type: UPDATE_NOTIFICATIONS_RADIUS,
      userId: _id,
      location: { type: 'point', coordinates: location },
    });
    return address;
  });
};

const removeAccount = () => dispatch => {
  request(dispatch, { method: 'bikers:remove' }).then(() => dispatch(auth.actions.signOut()));
};

const removeAccountWithId = id => dispatch => request(dispatch, { method: 'bikers:removeById', id });

const sendNotifications = notification => dispatch =>
  request(dispatch, { method: 'bikers:sendNotifications', notification });
const actions = {
  removeAccount,
  removeAccountWithId,
  loadOne,
  setLocation,
  setBikers,
  loadAll,
  loadAllByAdmin,
  loadBikersCount,
  update,
  loadTrips,
  updateNotificationsRadius,
  addBiker,
  adminUpdate,
  sendNotifications,
};

export default { actions, reducer };
