import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const TextFieldForm = ({ input: { value, onChange, name, ...restInput }, requiredField, meta, ...rest }) => {
  const showError = meta.error && meta.touched;

  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={restInput}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
    />
  );
};
TextFieldForm.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  requiredField: PropTypes.bool,
};

export default TextFieldForm;
