import React from 'react';
import { useConnect } from './hooks';
import LoggedInPage from './LoggedInPage';
import LoginStepper from './LoginStepper';

const Home = () => {
  const { user } = useConnect();
  return user ? <LoggedInPage /> : <LoginStepper />;
};

export default Home;
