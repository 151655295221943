import { prop, propOr, compose, values, pathOr, path } from 'ramda';
import { createSelector } from 'reselect';

const root = prop('routes');

export const getRoutes = createSelector(
  root,
  compose(
    values,
    propOr([], 'data'),
  ),
);

export const getRouteswithIds = createSelector(
  root,
  propOr([], 'data'),
);

export const getRoute = id =>
  createSelector(
    getRouteswithIds,
    prop(id),
  );

export const getRouteFeature = (index, routeId) =>
  createSelector(
    getRoute(routeId),
    route => pathOr(path([('location', 'features', 0)], route), ['location', 'features', index], route),
  );
