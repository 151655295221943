import React from 'react';
import Grid from '@material-ui/core/Grid';
import NewsCard from './NewsCard';
import { useConnect } from './hooks';

const News = () => {
  const { trip } = useConnect();
  return <Grid container>{trip._id && <NewsCard trip={trip} />} </Grid>;
};

export default News;
