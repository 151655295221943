import { propOr, compose, values, prop, filter, length, last } from 'ramda';
import { createSelector } from 'reselect';
import { TRIP_START_SOON, PREMIUM_WILL_EXPIRE } from '../../lib/models/events';
const root = prop('events');
export const getEvents = createSelector(
  root,
  compose(
    filter(event => event.type !== TRIP_START_SOON && event.type !== PREMIUM_WILL_EXPIRE),
    values,
    propOr([], 'data'),
  ),
);

export const getUnredEventsCount = createSelector(
  root,
  compose(
    length,
    getEvents,
  ),
);

export const getNewsEvents = createSelector(
  root,
  compose(
    filter(event => event.type === TRIP_START_SOON),
    values,
    propOr([], 'data'),
  ),
);

export const getUnredNewsCount = createSelector(
  root,
  compose(
    length,
    filter(event => !event.readAt && event.type === TRIP_START_SOON),
    values,
    propOr([], 'data'),
  ),
);

export const getLastPremiumExpiration = createSelector(
  root,
  compose(
    last,
    filter(event => event.type === PREMIUM_WILL_EXPIRE),
    values,
    propOr([], 'data'),
  ),
);
