import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { path } from 'ramda';
import 'firebase/messaging';
import api from '../lib/api';

const listen = onMessage => {
  const firebaseConfig = {
    apiKey: 'AIzaSyATkqnC_vs_ie2vTGS3E3WuenP6L5MSWKk',
    authDomain: 'co-motard.firebaseapp.com',
    databaseURL: 'https://co-motard.firebaseio.com',
    projectId: 'co-motard',
    storageBucket: 'co-motard.appspot.com',
    messagingSenderId: '559873827684',
    appId: '1:559873827684:web:cd5987f3fb27714356d071',
    measurementId: 'G-EX5R10G0W9',
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      'BE96oS-OfSOf0xmJzkBFzNkrW8J34w8tr6mQtK7pyYDMewUy81sqv5hueflqLWuxtcEJUJZk_V8qO24EZkJy6gU',
    );
    const sendTokenToServer = firebaseToken => api.events.listen({ firebaseToken });

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      Notification.requestPermission().then(permission => {
        if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(function(permission) {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              messaging
                .getToken()
                .then(currentToken => {
                  if (currentToken) {
                    messaging.onMessage(msg => {
                      console.log('Firebase msg received: ', msg);
                      if (path(['data', 'action'], msg)) onMessage(JSON.parse(msg.data.action));
                    });
                    resolve(sendTokenToServer(currentToken));
                  } else {
                    reject(new Error('No Instance ID token available. Request permission to generate one'));
                  }
                })
                .catch(err => {
                  console.log(err);
                  reject(new Error('An error occurred while retrieving FCM token'));
                });

              // Callback fired if Instance ID token is updated.
              messaging.onTokenRefresh(() => {
                messaging
                  .getToken()
                  .then(refreshedToken => {
                    sendTokenToServer(refreshedToken);
                  })
                  .catch(err => {
                    console.log('Unable to retrieve refreshed token ', err);
                  });
              });
              console.log('Firebase registred with notifications');
            } else {
              console.error('Unable to get permission to notify.');
            }
          });
        }
      });
    });
  }
};

export default listen;
