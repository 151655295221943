import React, { useState } from 'react';
import { node } from 'prop-types';
import { NavProvider } from '../NavProvider';

const NavigationProvider = ({ children }) => {
  const [component, setComponent] = useState();
  const fct = nav => {
    if (!nav) return component;
    setComponent(nav);
  };

  return <NavProvider value={fct}>{children}</NavProvider>;
};

NavigationProvider.propTypes = {
  children: node,
};

export default NavigationProvider;
