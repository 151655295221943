import { defineMessages } from 'react-intl';

export default defineMessages({
  sectionText: {
    id: 'components.AboutUs.sectionText',
    defaultMessage: "Rencontre d'autres Co-Pilotes & {br} Découvre de nouvelles balades grâce à Comot !",
  },

  sectionTitle: {
    id: 'components.AboutUs.sectionTitle',
    defaultMessage: 'Marre de rouler seul?',
  },
});
