import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import { firebase } from './middlewares';

const configureStore = initialState => {
  const middlewares = [thunk, firebase];
  return createStore(createRootReducer(), initialState, applyMiddleware(...middlewares));
};

export default configureStore;
