import React from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import amber from '@material-ui/core/colors/amber';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import messages from './messages';
import Avatar from '../Avatar';
import { daysLeftTillNewTripAllower, fullname, isPremiumUser, tripsLimitReached } from '../../../lib/models/bikers';
import PremimDrawer from '../Drawers/PremiumDrawer';
import { usePremiumDrawer } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  cardAction: {
    maxWidth: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  avatar: {
    margin: 'auto',
  },
  badge: {
    width: '10px',
    height: '10px',
    background: theme.palette.primary.main,
    margin: 'auto',
    display: 'block',
    marginTop: '6px',
    borderRadius: '100%',
  },
  cardFooter: {
    backgroundColor: theme.palette.grey[50],
  },
  notificationsColor: {
    position: 'absolute',
    width: '3px',
    height: '100%',
    backgroundColor: amber[600],
  },
  date: {
    display: 'block',
    marginRight: theme.spacing(2),
  },
}));

const InvitationRecieved = ({ event, trip, source, biker, confirm, reject, setNotifications }) => {
  const classes = useStyles({ type: event.type });

  const [premiumDrawer, setPremiumDrawer] = usePremiumDrawer();
  const history = useHistory();
  const routes = useRoutes();
  const redirectToTrip = () => {
    setNotifications(false);
    history.push(routes.getPathByName('trip', event.tripId));
  };
  return (
    <Card className={classes.root}>
      <Box className={classes.notificationsColor} />
      <CardActionArea onClick={() => redirectToTrip()}>
        <Grid container className={classes.cardAction}>
          <Grid item xs={3}>
            <Avatar redirect biker={source} hideDrawer={() => setNotifications(false)} />
          </Grid>
          <Grid xs={8} item>
            <Typography>
              <FormattedMessage
                {...messages.invitationRecieved}
                values={{ tripName: trip.name, sourceName: fullname(source) }}
              />
            </Typography>
          </Grid>
          <Grid xs={1} item>
            {!event.readAt && (
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
                className={classes.badge}
              />
            )}
          </Grid>
        </Grid>
        <FormattedRelativeTime
          numeric="auto"
          updateIntervalInSeconds={10}
          value={differenceInSeconds(new Date(event.createdAt), new Date())}
          unit="second"
        >
          {date => (
            <Typography variant="caption" className={classes.date} gutterBottom align="right">
              {date}
            </Typography>
          )}
        </FormattedRelativeTime>
      </CardActionArea>
      <CardActions className={classes.cardFooter}>
        <Grid container direction="row-reverse">
          <Grid item>
            <Button
              onClick={() =>
                !isPremiumUser(biker) && tripsLimitReached(biker)
                  ? setPremiumDrawer(true)
                  : confirm(trip._id, biker._id)
              }
              size="small"
              color="primary"
            >
              <FormattedMessage {...messages.accept} />
            </Button>
            <Button onClick={() => reject(trip._id, biker._id)} size="small" color="secondary">
              <FormattedMessage {...messages.reject} />
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      <PremimDrawer
        open={premiumDrawer}
        setOpen={setPremiumDrawer}
        title={{
          ...messages.premiumDrawerTitle,
          values: {
            days: daysLeftTillNewTripAllower(biker),
            b: chunks => <span style={{ fontWeight: 'normal', display: 'contents' }}>{chunks}</span>,
            br: <br />,
          },
        }}
        text={messages.premiumDrawerText}
      />
    </Card>
  );
};

InvitationRecieved.propTypes = {
  event: PropTypes.object,
  source: PropTypes.object,
  biker: PropTypes.object,
  trip: PropTypes.object,
  setEventAsRed: PropTypes.func,
  confirm: PropTypes.func,
  reject: PropTypes.func,
  setNotifications: PropTypes.func,
};
export default InvitationRecieved;
