import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  loadOne: ({ id }) => axios.get(endPoint(`/api/trips/loadOne/${id}`), { headers: bearer() }).then(prop('data')),
  loadAll: params => axios.post(endPoint(`/api/trips/loadAll`), params, { headers: bearer() }).then(prop('data')),
  loadForBiker: params =>
    axios.post(endPoint(`/api/trips/loadForBiker`), params, { headers: bearer() }).then(prop('data')),
  create: params => axios.post(endPoint(`/api/trips/create`), params, { headers: bearer() }).then(prop('data')),
  update: params => axios.post(endPoint(`/api/trips/update`), params, { headers: bearer() }).then(prop('data')),
  cancel: ({ id }) => axios.get(endPoint(`/api/trips/cancel/${id}`), { headers: bearer() }).then(prop('data')),
  end: ({ id }) => axios.get(endPoint(`/api/trips/end/${id}`), { headers: bearer() }).then(prop('data')),
  getActiveBikers: ({ id }) =>
    axios.post(endPoint(`/api/trips/getActiveBikers`), { id }, { headers: bearer() }).then(prop('data')),
  getTrippers: ({ id, statuses }) =>
    axios.post(endPoint(`/api/trips/getTrippers`), { id, statuses }, { headers: bearer() }).then(prop('data')),

  loadSuggestedTrips: params =>
    axios.post(endPoint(`/api/trips/loadSuggestedTrips`), params, { headers: bearer() }).then(prop('data')),
});
