import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsCenter from './NotificationsCenter';
import messages from './messages';
import useHeight from '../../hooks/useHeight';

const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
  root: ({ height }) => ({
    display: 'flex',
    zIndex: '1201',
    width: `${drawerWidth}px`,
    position: 'fixed',

    Height: `calc(${height}px  - ${theme.heights.appBar})`,
  }),

  icon: {
    marginBottom: '-7px',
  },

  drawer: {
    width: `100vw`,
    flexShrink: 0,
  },
  drawerPaper: {
    width: `100vw`,
    height: 'auto',
    top: theme.heights.appBar,
  },
  drawerHeader: {
    display: 'flex',
    position: 'sticky',
    top: '0',
    zIndex: ' 1500',
    background: ' #FFF',

    alignItems: 'center',
    padding: theme.spacing(1),
    ...theme.mixins.toolbar,
    color: theme.palette.grey[800],
    minHeight: 'auto',
  },
}));

const SelectLocation = ({ notificationsRadius, setNotificationsRadius, route, zoom }) => {
  const height = useHeight();
  const classes = useStyles({ height });

  return (
    <Box className={classes.root} style={{ width: `${drawerWidth}px` }}>
      <Drawer
        variant="persistent"
        anchor="right"
        open={notificationsRadius}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid container direction="column">
          <Grid container item wrap="nowrap" justify="space-between" className={classes.drawerHeader}>
            <Grid item>
              <IconButton onClick={() => setNotificationsRadius(false)}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage {...messages.hide} />
                </Typography>
                <ChevronRightIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography ariant="body2">
                <FormattedMessage {...messages.selectRadius} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <NotificationsCenter
            zoom={zoom}
            route={route}
            notificationsRadius={notificationsRadius}
            setNotificationsRadius={setNotificationsRadius}
          />
        </Grid>
      </Drawer>
    </Box>
  );
};

SelectLocation.propTypes = {
  notificationsRadius: PropTypes.bool,
  setNotificationsRadius: PropTypes.func,
  route: PropTypes.object,

  zoom: PropTypes.number,
};

export default SelectLocation;
