import { compose, propOr, assoc, reduce, set, lensPath, values, pluck } from 'ramda';
import { request } from './utils';
import { EVENTS_LOADED } from './events'; //eslint-disable-line
import { SIGNED_OUT, LAST_TRIP_DATE, DEC_TRIPS_COUNT, refreshUser } from './auth'; // eslint-disable-line
import { CHANNELS_LOADED } from './channels'; // eslint-disable-line
import { MESSAGES_LOADED } from './messages'; //eslint-disable-line
import { MESSAGE_PUBLISHED } from '../../lib/models/events';
import { NATIVE_ACTIONS } from '../../lib/models/native';
import routes from './routes'; //eslint-disable-line
import trippersActions from './trippers'; //eslint-disable-line
import ducksActions from '../actions'; //eslint-disable-line
import { getUser } from '../selectors/auth';
import { isPremiumUser } from '../../lib/models/bikers';

const TRIPS_LOADED = '@trips:TRIPS_LOADED';
const TRIP_LOADED = '@trips:TRIP_LOADED';
const TRIPPERS_LOADED = '@trips:TRIPPERS_LOADED';
export const CREATE_TRIP = '@trips:CREATE_TRIP';
export const TRIP_CANCELED = '@trips:TRIP_CANCELED';
export const TRIP_UPDATED = '@trips:TRIP_UPDATED';
export const TRIP_ENDED = '@trips:TRIP_ENDED';

const reducer = (state = { data: {} }, action) => {
  let data;
  switch (action.type) {
    case TRIPS_LOADED:
    case EVENTS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), { ...state.data }),
        propOr([], 'trips'),
      )(action);
      return { ...state, data };
    case MESSAGE_PUBLISHED:
      return {
        ...state,
        data: set(lensPath([action.message.tripId, 'lastMessageId']), action.message._id, state.data),
      };
    case TRIP_LOADED:
      return {
        ...state,
        data: assoc(action.trip._id, action.trip, state.data),
      };
    case TRIPPERS_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.tripId]: {
            ...state.data[action.tripId],
            trippers: action.trippers,
          },
        },
      };
    case MESSAGES_LOADED:
    case CHANNELS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), { ...state.data }),
        propOr([], 'trips'),
        propOr([], 'data'),
      )(action);
      return { ...state, data };
    case CREATE_TRIP:
      return {
        ...state,
        data: assoc(action.trip._id, action.trip, state.data),
      };
    case TRIP_CANCELED:
      return {
        ...state,
        data: assoc(action.trip._id, action.trip, state.data),
      };
    case TRIP_ENDED:
      return {
        ...state,
        data: assoc(action.trip._id, action.trip, state.data),
      };

    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};

const loadOne = id => dispatch => {
  if (id)
    return request(dispatch, { method: 'trips:loadOne', id }).then(trip => {
      dispatch({ type: TRIP_LOADED, trip });
      return trip;
    });
};

const update = trip => dispatch =>
  // eslint-disable-next-line
  request(dispatch, { method: 'trips:update', ...trip }).then(trip => {
    dispatch({ type: TRIP_UPDATED, trip });
    return trip;
  });

const loadAll = params => dispatch => {
  request(dispatch, { method: 'trips:loadAll', ...params }).then(trips => {
    dispatch({ type: TRIPS_LOADED, trips });
    return trips;
  });
};

const setTrips = trips => dispatch => dispatch({ type: TRIPS_LOADED, trips });

const create = trip => (dispatch, getState) =>
  request(dispatch, { method: 'trips:create', ...trip })
    // eslint-disable-next-line
    .then(trip => {
      const state = getState();
      const user = getUser(state);
      if (!isPremiumUser(user)) dispatch(ducksActions.native.postMessage({ type: NATIVE_ACTIONS.SHOW_ADD }));
      dispatch({ type: CREATE_TRIP, trip });
      dispatch({ type: LAST_TRIP_DATE });
      dispatch(refreshUser());
      return trip;
    })
    .catch(e => e);

const cancel = trip => dispatch =>
  // eslint-disable-next-line
  request(dispatch, { method: 'trips:cancel', id: trip._id }).then(trip => {
    dispatch(ducksActions.native.postMessage({ type: NATIVE_ACTIONS.STOP_LOCATION_TRACKING }));
    dispatch({ type: TRIP_CANCELED, trip });
    dispatch({ type: DEC_TRIPS_COUNT });
    return trip;
  });

const end = trip => dispatch =>
  // eslint-disable-next-line
  request(dispatch, { method: 'trips:end', id: trip._id }).then(trip => {
    dispatch(ducksActions.native.postMessage({ type: NATIVE_ACTIONS.STOP_LOCATION_TRACKING }));
    dispatch({ type: TRIP_ENDED, trip });
    return trip;
  });

const tripsLoaded = trips => dispatch => {
  dispatch({ type: TRIPS_LOADED, trips });
  return trips;
};

const getTripTrippers = (tripId, statuses) => dispatch =>
  request(dispatch, { method: 'trips:getTrippers', id: tripId, statuses }).then(trippers => {
    dispatch({ type: TRIPPERS_LOADED, trippers, tripId });
    return trippers;
  });

const loadSuggestedTrips = () => dispatch => {
  const getTripRouteIds = compose(
    values,
    pluck('routeId'),
  );
  return request(dispatch, { method: 'trips:loadSuggestedTrips' })
    .then(({ trips, trippers }) => {
      dispatch({ type: TRIPS_LOADED, trips });
      dispatch(trippersActions.actions.trippersLoaded(trippers));
      return trips;
    })
    .then(trips => {
      const routeIds = getTripRouteIds(trips);
      return Promise.all([dispatch(routes.actions.loadAll({ ids: routeIds }))]);
    });
};

const actions = {
  loadOne,
  loadAll,
  create,
  setTrips,
  update,
  cancel,
  end,
  tripsLoaded,
  getTripTrippers,
  loadSuggestedTrips,
};

export default { reducer, actions };
