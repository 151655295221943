import language from './language';
import auth from './auth'; // eslint-disable-line
import location from './location';
import core from './core';
import trippers from './trippers'; // eslint-disable-line
import trips from './trips'; // eslint-disable-line
import routes from './routes'; // eslint-disable-line
import bikers from './bikers'; // eslint-disable-line
import tunnel from './tunnel'; // eslint-disable-line
import events from './events'; // eslint-disable-line
import ui from './ui'; // eslint-disable-line
import channels from './channels'; // eslint-disable-line
import messages from './messages'; // eslint-disable-line
import ridingTrip from './ridingTrip'; // eslint-disable-line
import locations from './locations'; // eslint-disable-line
import myTrips from './myTrips'; // eslint-disable-line
import friends from './friends'; // eslint-disable-line
import native from './native'; // eslint-disable-line
import homeTrips from './homeTrips'; // eslint-disable-line

export default {
  language,
  auth,
  location,
  core,
  trips,
  routes,
  bikers,
  tunnel,
  events,
  ui,
  trippers,
  channels,
  messages,
  ridingTrip,
  locations,
  myTrips,
  friends,
  native,
  homeTrips,
};
