import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  publish: ({ tripId, message }) =>
    axios.post(endPoint(`/api/chat/publish`), { tripId, message }, { headers: bearer() }).then(prop('data')),
  read: ({ tripId }) => axios.post(endPoint(`/api/chat/read`), { tripId }, { headers: bearer() }).then(prop('data')),
  getChannels: () => axios.get(endPoint(`/api/chat/getChannels`), { headers: bearer() }).then(prop('data')),
  loadMessages: ({ tripId }) =>
    axios.post(endPoint(`/api/chat/loadMessages`), { tripId }, { headers: bearer() }).then(prop('data')),
});
