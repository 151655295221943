import { prop, pathOr } from 'ramda';
import 'mapbox-gl/dist/mapbox-gl.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIntl, FormattedMessage } from 'react-intl';
// import Slider from '@material-ui/core/Slider';
import RoomIcon from '@material-ui/icons/Room';
import Button from '@material-ui/core/Button';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { geolocated } from 'react-geolocated';
import MapGl, { FlyToInterpolator, Marker, HTMLOverlay } from 'react-map-gl';
import { Box } from '@material-ui/core';
import User from '@material-ui/icons/PersonPinCircle';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import MyLocation from '@material-ui/icons/MyLocation';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../CircularProgress';
import { useViewport, useGeoCoder, useConnect } from './hooks';
import messages from './messages';
import ZoomButtons from '../ZoomButtons';
import { pixelValue } from '../../mapbox';
import useHeight from '../../hooks/useHeight';

const useStyles = makeStyles(theme => ({
  root: ({ height }) => ({
    width: '100%',
    height: `calc(${height}px  - 2 * ${theme.heights.appBar})`,
    position: 'relative',
  }),
  icon: {
    fontSize: '3em',
    transform: 'translate(-50%, -100%)',
  },

  markerIcon: {
    fontSize: '3em',
    transform: 'translate(-50%, -100%)',
    top: '50%',
    left: '50%',
    position: 'absolute',
  },

  circle: ({ radius }) => ({
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    position: 'absolute',
    backgroundColor: ' rgb(2, 9, 40,0.2)',
    border: '1px solid rgb(2, 9, 40,0.5)',
    width: radius,
    height: radius,
    borderRadius: '100%',
  }),
  slider: {
    height: '80%',
    top: '10%',
    zIndex: '999999',
    position: 'absolute',
  },
  markerImage: {
    width: '24px',
    height: '24px',

    transform: 'translate(-50%, -100%)',
    top: '50%',
    left: '50%',
    position: 'absolute',
  },
  buttonsContainer: {
    position: 'absolute',
    zIndex: '1',
    bottom: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  actionButtons: {
    padding: theme.spacing(1),
  },

  centerFab: {
    zIndex: '1199',
    marginBottom: theme.spacing(1),
  },
  geocoderInput: {
    zIndex: '30',
    backgroundColor: '#fff',
  },
  button: {
    width: '100%',
    margin: 'auto',
    display: 'block',
    zIndex: '10',
  },
  cancelBtn: {
    border: ` 1px solid ${theme.palette.secondary.main}`,
    backgroundColor: ' rgba(150, 0, 50, 0.7)',
    color: '#FFF',
    marginTop: theme.spacing(1),
  },
  autoComplete: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const buttonsStyle = {
  zIndex: '1',
};

const Map = ({ children, notificationsRadius, coords, setNotificationsRadius }) => {
  const mapRef = useRef(null);
  const intl = useIntl();

  const [viewport, setViewport] = useViewport();
  const {
    user,
    biker,
    updateNotificationsRadius,
    loadOne,
    location,
    setLocation,
    update,
    pendingRequests,
  } = useConnect();
  const position = pathOr(null, ['location', 'coordinates'], biker);
  const { geoCoder, queryResults, setGeoCoder, updateResults } = useGeoCoder(position);
  const height = useHeight();
  const classes = useStyles({
    height,
    radius: pixelValue(prop(1, position) || 50, 200, viewport.zoom),
  });
  const handleGeocoderViewportChange = e => {
    if (!e.target.classList.length) {
      updateNotificationsRadius([viewport.longitude, viewport.latitude]).then(setGeoCoder);
    }
  };

  // const marks = [
  //   {
  //     value: 30,
  //     label: '30 km',
  //   },
  //   {
  //     value: 200,
  //     label: '200 km',
  //   },
  // ];

  useEffect(() => {
    if (!biker) loadOne(prop('_id', user));
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (coords && setLocation) {
      setLocation({ latitude: coords.latitude, longitude: coords.longitude });
      if (!position) {
        setViewport({
          ...viewport,
          latitude: coords.latitude || 0,
          longitude: coords.longitude || 0,
        });
      }
    }
  }, [coords]); // eslint-disable-line

  // useEffect(() => {
  //   if (position && position[1] !== prop('latitude', viewport) && position[0] !== prop('longitude', viewport)) {
  //   updateNotificationsRadius([location.longitude, location.latitude]).then(setGeoCoder);
  //   }

  //   if ((!viewport.latitude || !viewport.longitude) && location && biker && !position) {

  //     setViewport({ ...viewport, latitude: location.latitude, longitude: location.longitude });
  //   }
  // }, [biker]); // eslint-disable-line

  useEffect(() => {
    if (position && position[1] !== prop('latitude', viewport) && position[0] !== prop('longitude', viewport)) {
      setViewport({
        ...viewport,
        latitude: position[1],
        longitude: position[0],
      });
    }
  }, [position]); // eslint-disable-line

  const centerMap = () => {
    return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        className={classes.centerFab}
        onClick={() => {
          updateNotificationsRadius([coords.longitude, coords.latitude]);
          setViewport({
            ...viewport,
            transitionDuration: 300,
            latitude: location.latitude,
            longitude: location.longitude,
          });
        }}
      >
        <MyLocation />
      </Fab>
    );
  };

  // const valuetext = value => `${value} km`;

  const handleZoom = e => {
    if (e.delta > 0) return setViewport({ ...viewport, zoom: viewport.zoom + 1, transitionDuration: 0 });
    setViewport({ ...viewport, transitionDuration: 0, zoom: viewport.zoom > 3 ? viewport.zoom - 1 : viewport.zoom });
  };
  // if (!isGeolocationAvailable || !isGeolocationEnabled) {
  //   return <RequestLocationDialog />;
  // }
  return (
    <Box className={classes.root}>
      {/* <Slider
        className={classes.slider}
        style={{ height: '80%' }}
        min={30}
        getAriaValueText={valuetext}
        valueLabelDisplay="on"
        max={200}
        step={10}
        orientation="vertical"
        defaultValue={30}
        aria-labelledby="vertical-slider"
        marks={marks}
        value={prop('radius', position) || 30}
        onChange={(e, v) => updateNotificationsRadius({ ...position, radius: v })}
      /> */}
      {pendingRequests !== 0 && <Loader icon="/loadMap.gif" withouBackground />}
      <Autocomplete
        filterOptions={x => x}
        onInputChange={updateResults}
        inputValue={geoCoder || ''}
        className={classes.autoComplete}
        onChange={(event, newValue) => {
          if (newValue) {
            updateNotificationsRadius(newValue.geometry.coordinates).then(setGeoCoder);
            setViewport({
              ...viewport,
              latitude: newValue.geometry.coordinates[1],
              longitude: newValue.geometry.coordinates[0],
            });
          }
          setGeoCoder('');
        }}
        options={queryResults}
        getOptionLabel={option => option.place_name}
        style={{ width: 300 }}
        noOptionsText={intl.formatMessage(messages.noResults)}
        renderInput={params => (
          <TextField
            className={classes.geocoderInput}
            {...params}
            placeholder={intl.formatMessage(messages.search)}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <MapGl
        {...viewport}
        ref={mapRef}
        width="100%"
        height="100%"
        scrollZoom={false}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={viewState => {
          setViewport(viewState);
        }}
        transitionInterpolator={new FlyToInterpolator()}
        onDblClick={() => {
          setViewport({
            ...viewport,
            zoom: viewport.zoom + 2,
          });
        }}
        onTouchEnd={handleGeocoderViewportChange}
        onMouseUp={handleGeocoderViewportChange}
        onWheel={handleZoom}
      >
        <HTMLOverlay style={buttonsStyle} redraw={() => children} />
        <HTMLOverlay redraw={() => <RoomIcon className={classes.markerIcon} />} />

        {location && location.longitude && location.latitude && (
          <Marker longitude={location.longitude} latitude={location.latitude}>
            <User className={classes.icon} />
          </Marker>
        )}
        <HTMLOverlay redraw={() => <div className={classes.circle} />} />

        <HTMLOverlay
          redraw={() => (
            <Grid container direction="column" className={classes.buttonsContainer}>
              <Grid container direction="column" alignItems="flex-end" className={classes.actionButtons} item>
                <Grid item>{centerMap()}</Grid>
                <Grid item>
                  <ZoomButtons viewport={viewport} setViewport={setViewport} />
                </Grid>
              </Grid>

              {notificationsRadius && (
                <Grid container justify="center" item>
                  <Grid xs={10} sm={4} md={3} item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        update({ coordinates: [viewport.longitude, viewport.latitude], _id: biker._id }).then(() =>
                          setNotificationsRadius(false),
                        );
                      }}
                      color="primary"
                      className={classes.button}
                    >
                      <FormattedMessage {...messages.validate} />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        />
      </MapGl>
    </Box>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  isGeolocationAvailable: PropTypes.bool,
  isGeolocationEnabled: PropTypes.bool,
  notificationsRadius: PropTypes.bool,
  setNotificationsRadius: PropTypes.func,
  coords: PropTypes.object,
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 100000,
  watchPosition: true,
})(Map);
