import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  loadOne: ({ id }) => axios.get(endPoint(`/api/routes/loadOne/${id}`)).then(prop('data')),
  loadAll: ({ ids, near, distances, times, difficulty, publicOnly }) =>
    axios
      .post(
        endPoint(`/api/routes/loadAll`),
        { ids, near, distances, times, difficulty, publicOnly },
        { headers: bearer() },
      )
      .then(prop('data')),
  create: params => axios.post(endPoint(`/api/routes/create`), params, { headers: bearer() }).then(prop('data')),
  update: params => axios.post(endPoint(`/api/routes/update`), params).then(prop('data')),
  getMapImage: ({ _id }) =>
    axios.get(endPoint(`/api/routes/getMapImage/${_id}`), { responseType: 'blob' }).then(prop('data')),
});
