import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { length, map, prop } from 'ramda';
import Box from '@material-ui/core/Box';
import withWidth from '@material-ui/core/withWidth';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Typography from '@material-ui/core/Typography';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import ExploreIcon from '@material-ui/icons/Explore';
import MapIcon from '@material-ui/icons/MapTwoTone';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import ChatIcon from '@material-ui/icons/Chat';
import { Button, Collapse } from '@material-ui/core';
import { string } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useCollapseMenu, useLoginPopup, useConnect, usePremiumDrawer, useSelectedTrip } from './hooks';
import News from '../News';
import messages from './messages';
import useRoutes from '../../hooks/routes';
import WhatWeDo from '../WhatWeDo';
import AboutUs from '../AboutUs';
import KnowMore from '../KnowMore';
import Footer from '../Footer';
import LoginPopup from '../Login/LoginPopup';
import useHeight from '../../hooks/useHeight';
import SuggestedTrips from './SuggestedTrips';
import { daysLeftTillNewTripAllower, isPremiumUser, tripsLimitReached } from '../../../lib/models/bikers';
import PremimDrawer from '../Drawers/PremiumDrawer';

const useStyles = makeStyles(theme => ({
  container: ({ user, height }) => ({
    height: user ? height : `100vh`,
    width: '100%',
    overflowX: 'hidden',
    background:
      'linear-gradient(356deg, rgba(255,255,255,0.7617822128851541) 0%, rgba(255,255,255,0.7183648459383753) 50%, rgba(255,255,255,1) 100%)',
  }),
  backgroundGradient: ({ user, height }) => ({
    backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    width: '100%',
    height: user ? height : `100vh`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  swipableCar: () => ({
    marginLeft: '-3px',
    maxWidth: '100vw',
    color: '#000',
    backgroundColor: 'transparent',
    padding: ' 0px calc(100vw + -346px) 0px 0px',
  }),
  showContainer: {
    height: '100%',
  },
  fabShow: {
    marginBottom: theme.spacing(10),
    height: '4rem',
    width: '5rem',
    borderRadius: '0 40px 40px 0',
    background: '#FFF',
    position: 'absolute',
    top: '200px',
    padding: 0,
    marginLeft: '-1.8rem',
    paddingLeft: '1.3rem',
    zIndex: 1,
  },
  findTripButton: {
    backgroundColor: '#FFF',
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]} `,
    borderRadius: '40px',
    width: '80%',
    margin: 'auto',
  },
  createTripContainer: {
    borderRadius: '40px',
    overflow: 'hidden',
    backgroundColor: theme.palette.success.main,
    width: '80%',
    margin: 'auto',
    '& span': {
      color: '#FFF',
    },
  },
  button: {
    width: '100%',
    margin: 'auto',
    textTransform: 'none',
    color: theme.palette.primary.main,
    borderRadius: '40px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  disabled: {
    opacity: '.5',
  },
  comotImage: {
    width: '24px',
  },
  leftIcon: {
    left: theme.spacing(2),
    position: 'absolute',
    color: theme.palette.success.main,
  },
  createBtn: {
    textTransform: 'none',
  },
  buttonContainer: ({ tripId }) => ({
    width: '80%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    marginBottom: tripId ? theme.spacing(1) : theme.spacing(5),
  }),
  colapseButtons: {
    backgroundColor: 'transparent',
    '& > :nth-child(1) ': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    '& > :nth-child(2) ': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  aboutUsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  homeHeader: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    position: 'absolute',
    top: 0,
    left: 0,
  },

  navigationRoot: {
    [theme.breakpoints.up('md')]: {
      display: `none`,
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '40px',
    '& .MuiBottomNavigationAction-label': {
      opacity: 1,
    },
    '& > :nth-child(1)': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    '& > :nth-child(2)': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    '& .MuiButtonBase-root': {
      minWidth: '0',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  pageTitle: {
    fontWeight: '700',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(8),
    color: theme.palette.primary.main,
  },

  homeMenu: {
    paddingBottom: theme.spacing(2),
    maxWidth: '350px',
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  subTitle: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  content: {
    width: '100%',
    borderRadius: '40px',
  },
  iconBtn: {
    top: '0px',
    left: '0px',
    zIndex: 1,
    padding: theme.spacing(1),
  },
  whiteIcon: {
    color: '#FFF',
  },
  bottomMunu: {
    width: '80vw',
  },
}));

const MenuPopup = ({ width }) => {
  const { user, trip, clearTunnel, loadTrips, trips } = useConnect();
  const history = useHistory();
  const intl = useIntl();
  const height = useHeight();
  const classes = useStyles({ user, tripId: prop('_id', trip), height, tripsNumber: length(trips) });
  const [loginPopup, setLoginPopup] = useLoginPopup();
  const [premiumDrawer, setPremiumDrawer] = usePremiumDrawer();
  const routes = useRoutes();
  const redirect = path => {
    if (!user) return setLoginPopup(true);
    history.push(routes.getPathByName(path));
  };
  const [selectedTrip, setSelectedTrip] = useSelectedTrip();
  const handleChangeIndex = idx => {
    setSelectedTrip(idx);
  };
  useEffect(() => {
    clearTunnel();
    loadTrips();
  }, []); // eslint-disable-line

  const { checked } = useCollapseMenu();
  return (
    <Box style={{ position: 'relative' }}>
      {loginPopup && <LoginPopup dialog={loginPopup} setDialog={setLoginPopup} />}
      <Box className={classes.backgroundGradient}>
        <Grid
          item
          container
          xs={12}
          direction="column"
          justify="flex-end"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            item
            className={classes.homeHeader}
            wrap="nowrap"
            justify="flex-start"
            alignContent="flex-start"
            direction="row"
          >
            <Grid container item direction="column" alignItems="right">
              <Grid item>
                <Typography className={classes.pageTitle} variant="h5">
                  <FormattedMessage {...messages.title} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.subTitle}>
                  <FormattedMessage {...messages.subTitle} />
                </Typography>
              </Grid>
              <Grid item>
                <SwipeableViews
                  index={selectedTrip}
                  onChangeIndex={handleChangeIndex}
                  enableMouseEvents
                  className={classes.swipableCar}
                >
                  {map(trip => <SuggestedTrips key={trip._id} trip={trip} />)(trips)}
                </SwipeableViews>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item direction="column" alignItems="center">
            <Grid container item direction="column" className={classes.buttonContainer}>
              <Grid item className={classes.findTripButton}>
                <Button
                  className={classes.button}
                  onClick={() =>
                    !isPremiumUser(user) && tripsLimitReached(user) ? setPremiumDrawer(true) : redirect('joinTrip')
                  }
                >
                  <MapIcon className={classes.leftIcon} />
                  <FormattedMessage {...messages.join} />
                </Button>
              </Grid>

              <Grid item container className={classes.createTripContainer}>
                <Grid item className={classes.content}>
                  <Button
                    className={classes.button}
                    fullWidth
                    onClick={() =>
                      !isPremiumUser(user) && tripsLimitReached(user)
                        ? setPremiumDrawer(true)
                        : redirect('createNewRoute')
                    }
                  >
                    <AddCircleSharpIcon className={clsx(classes.leftIcon, classes.whiteIcon)} />
                    <FormattedMessage {...messages.create} />
                  </Button>
                </Grid>

                <Grid item className={classes.content}>
                  <Collapse in={checked}>
                    <BottomNavigation showLabels className={classes.colapseButtons}>
                      <BottomNavigationAction
                        onClick={() =>
                          !isPremiumUser(user) && tripsLimitReached(user)
                            ? setPremiumDrawer(true)
                            : redirect('createNewRoute')
                        }
                        className={classes.icon}
                        label={intl.formatMessage(messages.edit)}
                        icon={<CreateIcon />}
                      />
                      <BottomNavigationAction
                        disabled
                        onClick={() => redirect('createfromCatalog')}
                        className={clsx(classes.icon, classes.disabled)}
                        label={intl.formatMessage(messages.catalog)}
                        icon={<ExploreIcon />}
                      />
                    </BottomNavigation>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
            <Grid cotainer item direction="column" alignItems="center">
              <Grid item container className={classes.homeMenu}>
                <News />
              </Grid>
              <Grid item className={classes.bottomMunu}>
                <BottomNavigation showLabels className={classes.navigationRoot}>
                  <BottomNavigationAction
                    onClick={() => redirect('myTrips')}
                    className={classes.icon}
                    label={intl.formatMessage(messages.myTrips)}
                    icon={<img className={classes.comotImage} src="comot-blue.svg" alt="mes trajets" />}
                  />
                  <BottomNavigationAction
                    onClick={() => redirect('friends')}
                    className={classes.icon}
                    label={intl.formatMessage(messages.friends)}
                    icon={<PeopleAltIcon />}
                  />
                  <BottomNavigationAction
                    onClick={() => redirect('chats')}
                    className={classes.icon}
                    label={intl.formatMessage(messages.chat)}
                    icon={<ChatIcon />}
                  />
                </BottomNavigation>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {(width === 'lg' || width === 'xl') && (
        <Box className={classes.aboutUsContainer}>
          <AboutUs />
          <WhatWeDo />
          <KnowMore />
          <Footer />
        </Box>
      )}

      <PremimDrawer
        open={premiumDrawer}
        setOpen={setPremiumDrawer}
        title={{
          ...messages.premiumDrawerTitle,
          values: {
            days: daysLeftTillNewTripAllower(user),
            b: chunks => <span style={{ fontWeight: 'normal', display: 'contents' }}>{chunks}</span>,
            br: <br />,
          },
        }}
        text={messages.premiumDrawerText}
      />
    </Box>
  );
};

MenuPopup.propTypes = {
  width: string,
};

const LoggedInPage = ({ width }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <MenuPopup width={width} />
    </Box>
  );
};

LoggedInPage.propTypes = {
  width: string,
};
export default withWidth()(LoggedInPage);
