import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import { useIntl, FormattedMessage } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { values } from 'ramda';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import useHeight from '../../hooks/useHeight';
import NavBack from '../Nav/Back';
import UserElement from './UserElement';
import useRoutes from '../../hooks/routes';
import { useAccountStatuses, useConnect, useSearchInput } from './hooks';
import messages from './messages';
import { ACCOUNT_STATUSES } from '../../../lib/models/bikers';

const useStyles = makeStyles(theme => ({
  backgourdContainer: ({ height }) => ({
    backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  container: ({ height }) => ({
    backgroundColor: '#FFFFFFCC',
    paddingBottom: theme.spacing(4),
    minHeight: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  divider: {
    height: '3px',
    borderRadius: '3px',
    margin: `${theme.spacing(1)}px auto`,
    width: '70%',
    maxWidth: '200px',
  },
  successButton: {
    color: '#FFF',
    borderRadius: '20px',
    height: '64px',
    backgroundColor: theme.palette.success.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '2px solid',
      borderColor: theme.palette.success.dark,
      content: '""',
    },
    '&:hover,&active,&focus': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const Backlog = () => {
  const height = useHeight();
  const intl = useIntl();
  const { bikers = [], bikersCount, loadBikers, loadBikersCount, removeAccountWithId, setSnackBar } = useConnect();
  const classes = useStyles({ height });
  const [searchInput, setSeatchInput] = useSearchInput();
  const [accountStatus, setAccountStatus] = useAccountStatuses();

  const handleChangeAccount = event => {
    setAccountStatus(event.target.value);
  };
  useEffect(() => {
    loadBikers(searchInput, accountStatus);
    loadBikersCount(accountStatus);
  }, [accountStatus, searchInput]); // eslint-disable-line
  const handleChange = e => {
    setSeatchInput(e.target.value);
  };

  const removeAccount = id => {
    removeAccountWithId(id).then(() => {
      loadBikers(searchInput, accountStatus);
      loadBikersCount(accountStatus);
      setSnackBar({
        open: true,
        title: intl.formatMessage(messages.accountRemoved),
        color: 'error',
      });
    });
  };
  const history = useHistory();
  const routes = useRoutes();
  const editBiker = id => history.push(routes.getPathByName('backOfficeEdit', id));
  return (
    <>
      <div className={classes.backgourdContainer}>
        <div className={classes.container}>
          <NavBack title={intl.formatMessage(messages.title)} />
          <Container direction="column">
            <Grid container item alignItems="center" justify="space-between">
              <Grid item sm={4}>
                <TextField
                  variant="filled"
                  label={intl.formatMessage(messages.searchUser)}
                  fullWidth
                  onChange={handleChange}
                  value={searchInput}
                  inputprops={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container item sm={4} direction="column">
                <Grid container item alignItems="center" justify="center">
                  <PersonIcon />
                  <Typography align="center" variant="h6">
                    {'  '}
                    <FormattedMessage {...messages.users} />
                    {'  '}
                    {bikersCount.bikersCount && ` ${bikersCount.bikersCount}`}
                  </Typography>
                </Grid>

                <Divider className={classes.divider} />
                <Grid item container alignItems="center" justify="center">
                  <SportsMotorsportsIcon />
                  <Typography align="center" variant="h6">
                    {'  '}
                    <FormattedMessage {...messages.premium} />
                    {'  '}
                    {bikersCount.premiumCount && ` ${bikersCount.premiumCount}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={4}>
                <Button
                  className={classes.successButton}
                  variant="contained"
                  onClick={() => history.push(routes.getPathByName('backOfficeAdd'))}
                >
                  <Add />
                  <FormattedMessage {...messages.createNewUser} />
                </Button>
              </Grid>
              <FormControl className={classes.formControl}>
                <InputLabel id="selectAccountType">
                  <FormattedMessage {...messages.accountType} />
                </InputLabel>
                <Select
                  labelId="selectAccountType"
                  id="demo-mutiple-chip"
                  multiple
                  value={accountStatus}
                  className={classes.selectStatuse}
                  onChange={handleChangeAccount}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {values(ACCOUNT_STATUSES).map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <FormattedMessage {...messages.userId} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.mail} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.userName} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.status} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.type} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.role} />
                    </TableCell>
                    <TableCell align="left">
                      <FormattedMessage {...messages.createdAt} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bikers &&
                    bikers.map(biker => (
                      <UserElement biker={biker} editBiker={editBiker} removeAccount={removeAccount} />
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Backlog;
