import { defineMessages } from 'react-intl';

export default defineMessages({
  hide: {
    id: 'components.Notifications.hide',
    defaultMessage: 'Cacher',
  },
  accept: {
    id: 'components.Notifications.accept',
    defaultMessage: 'Accepter',
  },
  reject: {
    id: 'components.Notifications.reject',
    defaultMessage: 'rejeter',
  },
  setAsRed: {
    id: 'components.Notifications.setAsRed',
    defaultMessage: 'Marquer comme lu',
  },
  setSearchCenter: {
    id: 'components.Notifications.setTripsCenter',
    defaultMessage: 'périmètre des notifications ',
  },
  noNotifications: {
    id: 'components.noNotifications.setAsRed',
    defaultMessage: 'Pas de notifications non lues',
  },
  bikerConfirmedToJoinTrip: {
    id: 'components.Notifications.bikerConfirmedToJoinTrip',
    defaultMessage: '{sourceName} a confirmé votre participation à la balade "{tripName}". ',
  },
  bikerConfirmedInvitation: {
    id: 'components.Notifications.bikerConfirmedInvitation',
    defaultMessage: '{sourceName} a accepté votre invitation de rejoindre la balade "{tripName}".',
  },
  bikerRejectedToJoinTrip: {
    id: 'components.Notifications.bikerRejectedToJoinTrip',
    defaultMessage: '{sourceName} a rejeté votre participation à la balade "{tripName}".',
  },
  bikerRejectedInvitation: {
    id: 'components.Notifications.bikerRejectedInvitation',
    defaultMessage: '{sourceName} a rejeté votre invitation de rejoindre la balade "{tripName}".',
  },

  bikerRequestedToJoinTrip: {
    id: 'components.Notifications.bikerRequestedToJoinTrip',
    defaultMessage: '{sourceName} veut rejoindre votre balade "{tripName}".',
  },
  bikerCanceledParticipation: {
    id: 'components.Notifications.bikerCanceledParticipation',
    defaultMessage: '{sourceName} a annulé sa participation à votre balade "{tripName}".',
  },
  bikerJoinedTrip: {
    id: 'components.Notifications.bikerJoinedTrip',
    defaultMessage: '{sourceName} a rejoint votre balade "{tripName}".',
  },
  tripCanceled: {
    id: 'components.Notifications.tripCanceled',
    defaultMessage: '{sourceName} a annulé la balade "{tripName}".',
  },
  tripUpdated: {
    id: 'components.Notifications.tripUpdated',
    defaultMessage: '{sourceName} a modifié la balade "{tripName}".',
  },
  tripCreated: {
    id: 'components.Notifications.tripCreated',
    defaultMessage: '{sourceName} a créé la balade "{tripName}".',
  },
  tripEnded: {
    id: 'components.Notifications.tripEnded',
    defaultMessage: '{sourceName} a terminé la balade "{tripName}".',
  },
  tripStarInHours: {
    id: 'components.Notifications.tripStarInHours',
    defaultMessage:
      'Le bouton GO de la balade "{tripName}" vient d’apparaître ! Allez voir la position actuelle des participants .',
  },
  tripStarIn1Day: {
    id: 'components.Notifications.tripStarIn1Day',
    defaultMessage: 'Préparez-vous votre balade "{tripName}" commence demain !',
  },
  tripStarIn3Days: {
    id: 'components.Notifications.tripStarIn3Days',
    defaultMessage: 'Préparez-vous votre balade "{tripName}" commence dans quelques jours !',
  },
  newMessages: {
    id: 'components.Notifications.newMessages',
    defaultMessage:
      'Vous avez { count,plural, one {une conversation} other {# conversations} } avec de nouveaux messages.',
  },
  search: {
    id: 'components.Notifications.search',
    defaultMessage: 'Rechercher',
  },
  validate: {
    id: 'components.Notifications.validate',
    defaultMessage: 'Valider',
  },
  cancel: {
    id: 'components.Notifications.cancel',
    defaultMessage: 'Annuler',
  },
  addStep: {
    id: 'components.Notifications.addStep',
    defaultMessage: 'Ajouter une étape',
  },
  noResults: {
    id: 'components.Notifications.noResults',
    defaultMessage: 'Aucune adresse trouvée',
  },
  selectRadius: {
    id: 'components.Notifications.selectRadius',
    defaultMessage: 'Rayon de notifications',
  },
  newFriendRequest: {
    id: 'components.Notifications.selectRadius',
    defaultMessage: "{sourceName} vous a envoyé une demande d'amitié",
  },
  friendRequestAccepted: {
    id: 'components.Notifications.selectRadius',
    defaultMessage: "{sourceName} a accepté votre demande d'amitié",
  },
  invitationRecieved: {
    id: 'components.Notifications.invitationRecieved',
    defaultMessage: '{sourceName} vous a envoyé une invitation pour rejoindre la balade {tripName} ',
  },
  dialogTitleWillEnd: {
    id: 'components.Notifications.dialogTitleWillEnd',
    defaultMessage: 'Votre premium gratuite expirera bientôt',
  },
  dialogTitlended: {
    id: 'components.Notifications.dialogTitlended',
    defaultMessage: 'Votre premium est expirée',
  },
  dialogText: {
    id: 'components.Notifications.dialogText',
    defaultMessage: "Pour continuer de profiter pleinement de l'app, c'est par ici: ",
  },
  passPremium: {
    id: 'components.Notifications.passPremium',
    defaultMessage: 'Passer premium',
  },
  premiumDrawerTitle: {
    id: 'components.Notifications.premiumDrawerTitle',
    defaultMessage:
      "Encore {days} jours d'attente avant de pouvoir créer ou rejoindre une nouvelle balade {br} {br} <b>Impatient ?</b> ",
  },
  premiumDrawerText: {
    id: 'components.Notifications.premiumText',
    defaultMessage: 'Pour continuer sans attendre !',
  },
});
