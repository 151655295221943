import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../selectors/auth';
import actions from '../../actions';
import { getIsNative } from '../../selectors/native';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    logout: () => dispatch(actions.auth.signOut()),
    updateBiker: props => dispatch(actions.bikers.update(props)),
    isNative: useSelector(getIsNative),
    askExpoToShareComot: () =>
      dispatch(actions.native.postMessage({ type: NATIVE_ACTIONS.SHARE_LINK, payload: 'https://www.mycomot.fr/' })),
  };
};

export const useDrawer = () => {
  const [drawer, setDrawer] = useState(false);
  return [drawer, setDrawer];
};

export const useLogoutDialog = () => {
  const [logoutDialog, setLogoutDialog] = useState(false);
  return [logoutDialog, setLogoutDialog];
};

export const useNnotificationsRadius = () => {
  const [notificationsRadius, setNotificationsRadius] = useState(false);
  return [notificationsRadius, setNotificationsRadius];
};
