import { useDispatch, useSelector } from 'react-redux';
import actions from '../../actions';
import { getToken, getUser } from '../../selectors/auth';

export const useConnect = () => {
  const dispatch = useDispatch();

  return {
    user: useSelector(getUser),
    token: useSelector(getToken),
    onAuth: user => dispatch(actions.auth.signIn(user)),
  };
};
