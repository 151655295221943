import { Button, Drawer, Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PassPremiumButton, { SIZES } from '../../Buttons/PassPremiumButton';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: `100px`,
    flexShrink: 0,
  },
  drawerPaper: () => ({
    width: `100%`,
    height: 'auto',
    bottom: 0,
    padding: theme.spacing(2),
    borderRadius: '10px 10px 0 0 ',
  }),
  drawerPaperRoot: {
    right: '0',
    position: 'absolute',
    padding: theme.spacing(2),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    minWidth: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  text: {
    marginTop: theme.spacing(1),
  },
}));

const PremimDrawer = ({ title, text, open, setOpen }) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor="bottom"
      onClose={() => setOpen(false)}
      open={open}
      classes={{
        root: classes.drawerPaperRoot,
        paper: classes.drawerPaper,
      }}
    >
      <Grid container direction="column" justify="center" alignContent="center">
        <Button color="secondary" className={classes.closeBtn}>
          <CloseIcon onClick={() => setOpen(false)} />
        </Button>
        <Grid item>
          <Typography variant="h6" align="center" className={classes.title}>
            <FormattedMessage {...title} />
          </Typography>
        </Grid>
        <Grid item container justify="center" alignContent="center">
          <PassPremiumButton size={SIZES.LARGE} />
        </Grid>
        <Grid item>
          <Typography align="center" className={classes.text}>
            <FormattedMessage {...text} />
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

PremimDrawer.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default PremimDrawer;
