import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import GoogleLogin from 'react-google-login';
import messages from './messages';
import { useConnect } from './hooks';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

const useStyles = makeStyles(theme => ({
  googleBtn: {
    zIndex: '-1',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '45px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(10),
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    background: '#FFF',
    color: theme.palette.grey[800],
    '& svg': {
      position: 'absolute',
      left: theme.spacing(2),
      fontSize: '26px',
    },
  },
}));

const GoogleButton = ({ handleClose }) => {
  const intl = useIntl();
  const classes = useStyles();
  // eslint-disable-next-line
  const { signInWithGoogle, isNative, postMessage } = useConnect();

  // eslint-disable-next-line
  const handleNativeClick = () => {
    postMessage({ type: NATIVE_ACTIONS.SGININ_WITH_GOOGLE });
    handleClose();
  };

  const responseGoogle = res => {
    if (!res.error) {
      signInWithGoogle({ token: res.tokenId, accessToken: res.accessToken, ...res.profileObj }).then(() =>
        handleClose(),
      );
      console.log(`Signed in with google as ${res.getBasicProfile().getEmail()}`); // eslint-disable-line no-console
    } else {
      console.log(res); // eslint-disable-line no-console
    }
  };
  return (
    <GoogleLogin
      clientId="559873827684-25lulevgfh0hk010ddltbpb3s1puhnei.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      scope="profile email https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.addresses.read"
      icon
      // eslint-disable-next-line
      render={({ onClick }) => (
        <button
          type="button"
          className={classes.googleBtn}
          // onClick={e => (isNative ? handleNativeClick() : onClick(e))}
        >
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
              <path
                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                fill="#EA4335"
              />
              <path
                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                fill="#4285F4"
              />
              <path
                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                fill="#FBBC05"
              />
              <path
                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                fill="#34A853"
              />
              <path fill="none" d="M0 0h18v18H0z" />
            </g>
          </svg>
          {intl.formatMessage(messages.google)}
        </button>
      )}
    />
  );
};

GoogleButton.propTypes = {
  handleClose: PropTypes.func,
};

export default GoogleButton;
