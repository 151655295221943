import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.backLog.title',
    defaultMessage: 'Back Log',
  },
  searchUser: {
    id: 'components.backLog.searchUser',
    defaultMessage: 'Rechercher un utilisateur',
  },
  users: {
    id: 'components.backLog.users',
    defaultMessage: 'Utilisateurs',
  },
  premium: {
    id: 'components.backLog.premium',
    defaultMessage: 'Premium',
  },
  createNewUser: {
    id: 'components.backLog.createNewUser',
    defaultMessage: 'Créer un nouvel utilisateur',
  },
  userId: {
    id: 'components.backLog.userId',
    defaultMessage: 'ID utilisateur',
  },
  mail: {
    id: 'components.backLog.mail',
    defaultMessage: 'Mail',
  },
  userName: {
    id: 'components.backLog.userName',
    defaultMessage: 'Pseudo',
  },
  status: {
    id: 'components.backLog.status',
    defaultMessage: 'Statut',
  },
  type: {
    id: 'components.backLog.type',
    defaultMessage: 'Type',
  },

  removed: {
    id: 'components.backLog.removed',
    defaultMessage: 'supprimé',
  },

  active: {
    id: 'components.backLog.active',
    defaultMessage: 'active',
  },

  uncomplete: {
    id: 'components.backLog.uncomplete',
    defaultMessage: 'incomplet',
  },

  unverified: {
    id: 'components.backLog.unverified',
    defaultMessage: 'non vérifié',
  },

  free: {
    id: 'components.backLog.free',
    defaultMessage: 'gratuit',
  },
  accountRemoved: {
    id: 'components.backLog.accountRemoved',
    defaultMessage: 'le compte a été supprimé',
  },
  role: {
    id: 'components.backLog.role',
    defaultMessage: 'Role',
  },
  admin: {
    id: 'components.backLog.admin',
    defaultMessage: 'Admin',
  },
  user: {
    id: 'components.backLog.User',
    defaultMessage: '',
  },
  createdAt: {
    id: 'components.backLog.createdAt',
    defaultMessage: 'créé à',
  },
  accountType: {
    id: 'components.backLog.accountType',
    defaultMessage: 'statut',
  },
  accounts: {
    id: 'components.backLog.accounts',
    defaultMessage: 'Comptes',
  },
  notifications: {
    id: 'components.backLog.notifications',
    defaultMessage: 'Notifications',
  },
  accountsTitle: {
    id: 'components.backLog.accountsTitle',
    defaultMessage: 'Gérer les comptes',
  },
  notificationsTitle: {
    id: 'components.backLog.notificationsTitle',
    defaultMessage: 'Notifications',
  },
  notificationTitle: {
    id: 'components.backLog.notificationTitle',
    defaultMessage: 'Titre',
  },
  notificationContent: {
    id: 'components.backLog.notificationContent',
    defaultMessage: 'contenu',
  },
  sendNotification: {
    id: 'components.backLog.sendNotification',
    defaultMessage: 'envoyer la notification',
  },
  comot: {
    id: 'components.backLog.comot',
    defaultMessage: 'comot',
  },
});
