import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import messages from './messages';
import FacebookButton from '../Login/FacebookButton';
import GoogleButton from '../Login/GoogleButton';
import EmailButton from '../Login/EmailButton';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    zIndex: 3,
    width: '100vw',
    height: '100vh',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url("/homePageWeb.webp")`,
    },
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  blackFilter: {
    width: '100%',
    height: '100%',
    background: `${theme.palette.primary.main}80`,
    padding: 0,
    top: 0,
    position: 'absolute',
  },
  bikeImage: {
    marginTop: theme.spacing(2),
    maxWidth: '200px',
    display: 'block',
    margin: 'auto',
  },
  divider: {
    height: '3px',
    borderRadius: '3px',
    backgroundColor: '#FFFFFFCC',
    margin: `${theme.spacing(2)}px auto`,
    maxWidth: '200px',
  },
  title: {
    color: '#FFFFFFED',
    fontWeight: 900,
  },
  buttonContainer: {
    marginBottom: theme.spacing(3),
    width: '100%',
    maxWidth: '340px',
  },
}));

const LoggedInStepper = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid className={classes.blackFilter} container justify="center">
        <Grid item container xs={11} alignContent="center" direction="column">
          <Grid item>
            <img className={classes.bikeImage} src="/homeLogo.png" alt="comot" />
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item>
            <Typography gutterBottom className={classes.title} variant="h4" align="center">
              <FormattedMessage {...messages.welcome} />{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" className={classes.title}>
              <FormattedMessage {...messages.connectToContinue} />{' '}
            </Typography>
          </Grid>

          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item className={classes.buttonContainer}>
            <GoogleButton />
          </Grid>

          <Grid item className={classes.buttonContainer}>
            <FacebookButton />
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <EmailButton />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoggedInStepper;
