import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import withWidth from '@material-ui/core/withWidth';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { useParams, useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import NavBack from '../Nav/Back';
import { useConnect, useCurrentBiker, usePremiumEndDate } from './hooks';
import useRoutes from '../../hooks/routes';
import SelectField from '../../forms/Select';
import messages from './messages';
import TextFieldForm from '../../forms/TextField';
import { isMailValid, emptyForm } from '../../forms/utils';
import useHeight from '../../hooks/useHeight';
import { isPremiumUser } from '../../../lib/models/bikers';

const useStyles = makeStyles(theme => ({
  backgourdContainer: ({ height }) => ({
    backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  container: ({ height }) => ({
    backgroundColor: '#FFFFFFCC',
    paddingBottom: theme.spacing(4),
    minHeight: `calc(${height}px  - ${theme.heights.appBar} )`,
  }),
  inputItem: {
    width: '100%',
    marginTop: theme.spacing(5),
    '& .MuiFormControl-root': {
      minWidth: '100%',
      margin: 0,
    },
  },

  validateButton: {
    margin: 'auto',
    display: 'block',
  },
}));

const PublicProfile = () => {
  const height = useHeight();
  const routes = useRoutes();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles({ height });
  const [currentBiker, setCurrentBiker] = useCurrentBiker();
  const [premiumEndDate, setPremiumEndDate] = usePremiumEndDate();

  const { update, addBiker, setSnackBar, loadOne } = useConnect(id); //eslint-disable-line

  const intl = useIntl();
  const onSubmit = values => {
    if (currentBiker)
      return update({
        ...values,
        expiresAt: values.accountType === 'premium' ? premiumEndDate : undefined,
        id: currentBiker._id,
      }).then(() => {
        setSnackBar({
          open: true,
          title: intl.formatMessage(messages.accountUpdated),
          color: 'success',
        });
        history.push(routes.getPathByName('backOffice'));
      });
    addBiker({ expiresAt: values.accountType === 'premium' ? premiumEndDate : undefined, ...values })
      .then(() => {
        setSnackBar({
          open: true,
          title: intl.formatMessage(messages.accountCreated),
          color: 'success',
        });
        history.push(routes.getPathByName('backOffice'));
      })
      .catch(e => {
        if (e.response && e.response.status === 409) {
          setSnackBar({
            open: true,
            title: intl.formatMessage(messages.userExistTitle),
            text: intl.formatMessage(messages.userExistText),
            color: 'error',
          });
        }
      });
  };
  useEffect(() => {
    loadOne(id).then(res => {
      setCurrentBiker(res);
      console.log(res);
      if (res.expiresAt) {
        setPremiumEndDate(new Date(res.expiresAt));
      }
    });
  }, [id]); //eslint-disable-line

  const validate = values => {
    const errors = {};
    if (!isMailValid(values.email)) errors.email = intl.formatMessage(messages.unvalidEmail);

    if (emptyForm(values.email)) errors.email = intl.formatMessage(messages.required);
    return errors;
  };

  return (
    <>
      <NavBack title={intl.formatMessage(messages.title)} />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form id="detailsForm" onSubmit={handleSubmit} className={classes.backgourdContainer}>
            <Grid
              className={classes.container}
              container
              direction="column"
              alignContent="center"
              justify="space-between"
            >
              <Grid
                item
                container
                xs={11}
                sm={6}
                md={4}
                alignContent="center"
                direction="column"
                justify="space-between"
              >
                <Grid item className={classes.inputItem}>
                  <Field
                    inputprops={{ disableUnderline: true }}
                    name="email"
                    defaultValue={currentBiker && currentBiker.email}
                    disabled={currentBiker}
                    fullWidth
                    placeholder={intl.formatMessage(messages.addressMail)}
                    component={TextFieldForm}
                  />
                </Grid>

                <Grid item className={classes.inputItem}>
                  <Field
                    name="accountType"
                    label={intl.formatMessage(messages.type)}
                    component={SelectField}
                    IconComponent={() => <ArrowDropDownIcon />}
                    defaultValue={isPremiumUser(currentBiker) ? 'premium' : 'free'}
                  >
                    <MenuItem key={1} value="free">
                      {intl.formatMessage(messages.free)}
                    </MenuItem>
                    <MenuItem key={2} value="premium">
                      {intl.formatMessage(messages.premium)}
                    </MenuItem>
                  </Field>
                </Grid>

                {values.accountType === 'premium' && (
                  <Grid item className={classes.inputItem}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label={intl.formatMessage(messages.endDate)}
                        name="startDate"
                        value={premiumEndDate}
                        ampm={false}
                        onChange={e => setPremiumEndDate(e.toString('dd/MM/yyyy'))}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}

                <Grid item className={classes.inputItem}>
                  <Field
                    name="role"
                    label={intl.formatMessage(messages.role)}
                    component={SelectField}
                    IconComponent={() => <ArrowDropDownIcon />}
                    defaultValue={(currentBiker && currentBiker.role) || 'user'}
                  >
                    <MenuItem key={1} value={undefined}></MenuItem>
                    <MenuItem key={2} value="admin">
                      {intl.formatMessage(messages.admin)}
                    </MenuItem>
                  </Field>
                </Grid>
              </Grid>

              <Box className={classes.bottomButton}>
                {id ? (
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={classes.validateButton}
                    disabled={!currentBiker}
                  >
                    <FormattedMessage {...messages.update} />
                  </Button>
                ) : (
                  <Button variant="contained" type="submit" color="primary" className={classes.validateButton}>
                    <FormattedMessage {...messages.validateButton} />
                  </Button>
                )}
              </Box>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

PublicProfile.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  isFirstConnectionProfile: PropTypes.bool,
};

export default withWidth()(PublicProfile);
