import { useState } from 'react';

import { useSelector } from 'react-redux';

import { getUser } from '../../selectors/auth';

export const useConnect = () => {
  return {
    user: useSelector(getUser),
  };
};

export const useBikerImage = () => {
  const [bikerImage, setBikerImage] = useState();
  return [bikerImage, image => setBikerImage(image)];
};
export const useLoading = () => {
  const [loading, setLoading] = useState(false);
  return [loading, setLoading];
};
export const useImage = () => {
  const [imageSrc, setImageSrc] = useState();
  return [imageSrc, img => setImageSrc(img)];
};
