import React from 'react';
import { string, func } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import Nav from '.';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(1),
    minWidth: 'fit-content',
    overflow: 'hidden',
  },
  icon: {
    color: '#fff',
    backgroundColor: 'transparent',
  },
  noNavStyle: {
    display: 'none',
  },
  nav: {
    display: 'flex',
    backgroundColor: 'transparent',
  },
}));

const Back = ({ title, clickAction }) => {
  const history = useHistory();
  const classes = useStyles();
  const routes = useRoutes();
  const handleClick = () => {
    if (clickAction) clickAction();
    history.push(routes.getDefaultRoute().path);
  };
  return (
    <Nav style={{ display: 'none' }} className={classes.nav}>
      <IconButton onClick={handleClick}>
        <ArrowBackIcon className={classes.icon} />
      </IconButton>
      <Typography noWrap variant="h6" className={classes.title}>
        {title}
      </Typography>
    </Nav>
  );
};

Back.propTypes = {
  clickAction: func,
  title: string.isRequired,
};

export default Back;
