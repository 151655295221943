import { compose, reduce, propOr, dissoc } from 'ramda';
import { TRIPPER_CANCELED } from './trippers'; // eslint-disable-line

const TRIPS_LOADED = '@myTrips:TRIPS_LOADED';
export const TRIP_REMOVED = '@myTrips:TRIPS_REMOVED';

const reducer = (state = {}, action = {}) => {
  let data;
  switch (action.type) {
    case TRIPS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), { ...state.data }),
        propOr([], 'trips'),
      )(action);

      return { ...state, data };
    case TRIP_REMOVED:
      return {
        ...state,
        data: dissoc(action.id, state.data),
      };
    case TRIPPER_CANCELED:
      return {
        ...state,
        data: dissoc(action.tripper.tripId, state.data),
      };
    default:
      return {
        ...state,
      };
  }
};
const tripsLoaded = trips => dispatch => {
  dispatch({ type: TRIPS_LOADED, trips });
  return trips;
};
const actions = { tripsLoaded };

export default { reducer, actions };
