import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useRoutes from '../../../hooks/routes';
import messages from './messages';

export const SIZES = {
  LARGE: 'LARGE',
  MID: 'MID',
};

const useStyles = makeStyles(theme => ({
  premiumLogo: ({ size }) => ({
    width: size === SIZES.LARGE ? '50px' : '30px',
    marginRight: theme.spacing(2),
    position: 'absolute',
    left: '10px',
  }),
  premiumButton: ({ size }) => ({
    marginTop: theme.spacing(2),
    background: 'linear-gradient(180deg, rgba(237,195,1,1) 0%, rgba(237,115,1,1) 100%)',
    borderRadius: '40px',
    minWidth: '75%',
    fontWeight: size === SIZES.LARGE && 'bold',
    fontSize: size === SIZES.LARGE && '1rem',
    padding: size === SIZES.LARGE && theme.spacing(2),
  }),
}));
const PassPremiumButton = ({ text, size, buttonStyle }) => {
  const classes = useStyles({ size });
  const history = useHistory();
  const routes = useRoutes();
  return (
    <Button
      disabled
      className={clsx(classes.premiumButton, buttonStyle || {})}
      onClick={() => history.push(routes.getPathByName('premium'))}
    >
      <img src="/logo_premium.webp" alt="premium" className={classes.premiumLogo} />
      <FormattedMessage {...(text || messages.passPremium)} />
    </Button>
  );
};

PassPremiumButton.propTypes = {
  text: PropTypes.object,
  buttonStyle: PropTypes.object,
  size: PropTypes.string,
};

export default PassPremiumButton;
