import { defineMessages } from 'react-intl';

export default defineMessages({
  tripStartSoon: {
    id: 'components.News.tripStartSoon',
    defaultMessage: 'La balade "{tripName}" que vous avez rejoint commence dans. ',
  },
  yourTripStartSoon: {
    id: 'components.News.yourTripStartSoon',
    defaultMessage: 'la balade "{tripName}" que vous avez créé commence dans. ',
  },
  tripStarted: {
    id: 'components.News.tripStartSoon',
    defaultMessage: 'La balade "{tripName}" que vous avez rejoint a commencé il y a. ',
  },
  yourTripStarted: {
    id: 'components.News.yourTripStartSoon',
    defaultMessage: 'la balade "{tripName}" que vous avez créé a commencé il y a',
  },
  go: {
    id: 'components.News.go',
    defaultMessage: 'Go !',
  },
  tripEnded: {
    id: 'components.News.tripEnded',
    defaultMessage: 'Balade terminée?',
  },
  validate: {
    id: 'components.Departure.validate',
    defaultMessage: 'Valider',
  },
  cancel: {
    id: 'components.Departure.cancel',
    defaultMessage: 'Annuler',
  },
  dialogTitle: {
    id: 'components.Departure.dialogTitle',
    defaultMessage: "Si vous validez, vous n'aurez plus accès à cette balade.",
  },
});
