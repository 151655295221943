import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.App.title',
    defaultMessage: 'Comot',
  },
  description: {
    id: 'app.App.description',
    defaultMessage: '......',
  },
  confirm: {
    id: 'app.App.confirm',
    defaultMessage: 'Valider',
  },
  cancel: {
    id: 'app.App.cancel',
    defaultMessage: 'Annuler',
  },
  dialogTitle: {
    id: 'app.App.dialogTitle',
    defaultMessage: 'Attention',
  },
  logoutDialogText: {
    id: 'app.App.logoutDialogText',
    defaultMessage: 'Voulez-vous vraiment vous déconnecter ?',
  },

  displayProfile: {
    id: 'app.App.displayProfile',
    defaultMessage: 'Mon profil',
  },
  logout: {
    id: 'app.App.logout',
    defaultMessage: 'Déconnexion',
  },
  myTrips: {
    id: 'app.App.myTrips',
    defaultMessage: 'Mes trajets',
  },
  tChat: {
    id: 'app.App.tChat',
    defaultMessage: 'Tchat',
  },
  signIn: {
    id: 'app.App.signIn',
    defaultMessage: "S'identifier",
  },
  comotPremium: {
    id: 'app.App.comotPremium',
    defaultMessage: 'Premium',
  },
  legalMention: {
    id: 'components.App.legalMention',
    defaultMessage: 'Mentions légales',
  },
});
