import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  loadAll: () => axios.get(endPoint(`/api/events/loadAll`), { headers: bearer() }).then(prop('data')),
  read: ({ id }) => axios.post(endPoint(`/api/events/read`), { id }, { headers: bearer() }).then(prop('data')),
  remove: ({ id }) => axios.post(endPoint(`/api/events/remove`), { id }, { headers: bearer() }).then(prop('data')),
  listen: ({ firebaseToken }) =>
    axios
      .get(endPoint(`/api/listen`), { headers: { ...bearer(), 'x-firebase-token': firebaseToken } })
      .then(prop('data')),
});
