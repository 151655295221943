import { prop } from 'ramda';
import { createSelector } from 'reselect';

const root = prop('auth');
export const getToken = createSelector(
  root,
  prop('token'),
);
export const getUser = createSelector(
  root,
  prop('user'),
);
export const getClientSecret = createSelector(
  root,
  prop('clientSecret'),
);
