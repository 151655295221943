import { defineMessages } from 'react-intl';

export default defineMessages({
  qutions: {
    id: 'components.knowMore.qutions',
    defaultMessage: 'Des questions?',
  },
  clickHere: {
    id: 'components.knowMore.clickHere',
    defaultMessage: 'Clique ici, on te dit tout !',
  },
  btnTxt: {
    id: 'components.knowMore.btnTxt',
    defaultMessage: 'En savoir plus',
  },
  text: {
    id: 'components.knowMore.whatYouWillLike',
    defaultMessage:
      "Tu veux en savoir plus sur nous? {br}Sur l'application? {br}Nous contacter ou même nous envoyer un mot doux?",
  },
});
