import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton, Popover, Button } from '@material-ui/core';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { map, prop, includes, replace } from 'ramda';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Auth from '../Auth';
import SnackBar from '../SnackBar';
import useRoutes from '../../hooks/routes';
import Notifications from '../Notifications';
import messages from './messages';
import { useConnect, useMenu, useNotifications, useLogoutDialog, useLoginPopup, useMobile } from './hooks';
import UserCard from './UserCard';
import NavigationProvider from '../NavigationProvider';
import { NavImpl } from '../Nav';
import NavHome from '../Nav/Home';
import Dialog from '../Dialog';
import Loader from '../Loader';
import LoginPopup from '../Login/LoginPopup';
import { ACCOUNT_STATUSES } from '../../../lib/models/bikers';
const FIRST_CONNECTION = 'FIRST_CONNECTION';
const FIRST_CONNECTION_PROFILE = 'FIRST_CONNECTION_PROFILE';

const useStyles = makeStyles(theme => ({
  bar: ({ pathname }) => ({
    minHeight: 64,
    position:
      pathname === '/' ||
      pathname.split('/')[1] === 'plus' ||
      pathname === '/my_trips' ||
      pathname.split('/')[1] === 'departure' ||
      pathname.split('/')[1] === 'trip'
        ? 'absolute'
        : 'sticky',
    backgroundColor:
      (pathname === '/' ||
        pathname === '/plus' ||
        pathname === '/my_trips' ||
        pathname === '/chats' ||
        pathname === '/friends' ||
        pathname.split('/')[1] === 'trip' ||
        pathname.split('/')[1] === 'departure') &&
      'transparent',
    top: '0',
    zIndex: 10,
    boxShadow: 'none',
  }),
  toolbar: {
    justifyContent: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: '3em',
  },
  logo: {
    height: '3em',
    width: 'auto',
    marginRight: theme.spacing(2),
  },
  buttonIcon: {
    fontSize: '2rem',
  },
  badge: ({ pathname }) => ({
    color: pathname === '/' ? theme.palette.primary.main : '#FFF',
    boxShadow: theme.shadows[12],

    padding: 4,
    '& .MuiBadge-badge': {
      top: theme.spacing(1),
      left: theme.spacing(1),
      height: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      minWidth: theme.spacing(2),
      fontWeight: '600',
    },
  }),
  appBarMenu: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
    '& button': {
      textTransform: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: `none`,
    },
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: ' 20px solid transparent',
    borderRight: '20px solid transparent',
    position: 'absolute',
    bottom: '-8px',
    borderBottom: ' 20px solid #FFF',
    zIndex: '100',
  },
  signInBtn: {
    color: '#ffb14a',
    textTransform: 'none',
    fontWeight: '700',
  },
  linearProgress: {
    width: '100%',
    position: 'fixed',
    zIndex: '9999999999',
    bottom: '7px',
  },
}));

const makeAuthRoute = ({ isAuthRequired, isAdminRequired, isPremiumRequired }, route) => props =>
  isAuthRequired(route) ? (
    <Auth isAdminRequired={() => isAdminRequired(route)} isPremiumRequired={() => isPremiumRequired(route)}>
      <route.component {...props} />
    </Auth>
  ) : (
    <route.component {...props} />
  );

const MakeRoutes = () => {
  const routes = useRoutes();
  return (
    <Switch>
      {map(
        route => (
          <Route key={route.path} exact={route.exact} path={route.path} render={makeAuthRoute(routes, route)} />
        ),
        routes.all,
      )}
      <Redirect to={routes.getDefaultRoute().path} />
    </Switch>
  );
};

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const [locationState, setLocation] = useState('/');
  const classes = useStyles({ pathname: locationState });
  const routes = useRoutes();
  const intl = useIntl();
  const [loginPopup, setLoginPopup] = useLoginPopup();

  const isFirstConnectionProfile = localStorage.getItem(FIRST_CONNECTION_PROFILE);
  const {
    user,
    snackBar,
    resetSnackBar,
    unredEventsCount,
    logout,
    getIsNative,
    core,
    setAllEventsAsRed,
    handleNativeActions,
  } = useConnect();
  const { userMenuAnchor, closeUserMenu } = useMenu();
  const [isMobile, setIsMobile] = useMobile();
  const [logoutDialog, setLogoutDialog] = useLogoutDialog();
  const [notifications, setNotifications] = useNotifications();
  const isUserMenuOpen = Boolean(userMenuAnchor);
  const redirect = path => {
    if (!user) return setLoginPopup(true);
    history.push(routes.getPathByName(path));
  };

  useEffect(() => {
    setLocation(location.pathname);
  }, [location]); // eslint-disable-line

  const isFirstConnection = localStorage.getItem(FIRST_CONNECTION);
  const useIsComplete =
    user && user.status !== ACCOUNT_STATUSES.uncomplete && user.status !== ACCOUNT_STATUSES.unverified;
  useEffect(() => {
    setIsMobile();

    if (user && user.status === ACCOUNT_STATUSES.active && isFirstConnection)
      return history.push(routes.getPathByName('TutosAboutUs'));
    if (user && user.status === ACCOUNT_STATUSES.uncomplete) return history.push(routes.getPathByName('accountInfo'));

    if (user && user.status === ACCOUNT_STATUSES.unverified) {
      if (includes('/accountConfirmation/', location.pathname))
        return history.push({
          pathname: location.pathname,
          state: { token: replace('/accountConfirmation/', '', location.pathname) },
        });
      return history.push(routes.getPathByName('accountConfirmation'));
    }
  }, [user, location.pathname]); // eslint-disable-line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const receiveAction = inf => {
    const { detail } = inf;
    handleNativeActions(detail);
    if (detail.redirect) {
      switch (detail.id) {
        case 'trip':
          return history.push(routes.getPathByName('trip', detail.page));
        case 'resetPassword':
          return history.push(routes.getPathByName('passwordReset', detail.page));
        default:
          history.push(routes.getDefaultRoute().path);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('toReact', receiveAction);

    return () => window.removeEventListener('toReact', receiveAction);
  }, [getIsNative, receiveAction]);
  useEffect(() => {
    getIsNative();
  }, []); // eslint-disable-line

  const handleNotificationsClick = () => {
    if (isMobile) setAllEventsAsRed();
    setNotifications();
  };
  return (
    <Box>
      {prop('pendingRequests', core) !== 0 && <Loader />}
      <LoginPopup dialog={loginPopup} setDialog={setLoginPopup} />
      <Notifications open={notifications} setNotifications={setNotifications} />
      <NavigationProvider>
        {!isFirstConnectionProfile &&
          !isFirstConnection &&
          locationState !== '/comotLogin' &&
          locationState !== '/premium' &&
          locationState !== '/join_trip' &&
          locationState !== '/new_trip' &&
          locationState !== '/my_trips' &&
          locationState !== '/chats' &&
          locationState !== '/friends' &&
          (locationState.split('/')[1] !== 'my_trips' || !locationState.split('/')[2]) &&
          (locationState.split('/')[1] !== 'chat' || !locationState.split('/')[2]) &&
          locationState.split('/')[1] !== 'edit_trip' &&
          locationState.split('/')[1] !== 'trip' &&
          locationState.split('/')[1] !== 'departure' &&
          locationState.split('/')[1] !== 'redo_trip' &&
          locationState.split('/')[1] !== 'profile' &&
          locationState.split('/')[1] !== 'plus' &&
          locationState !== '/faq/about_us' && (
            <AppBar position={locationState === '/' ? 'absolute' : 'static'} className={classes.bar}>
              <Toolbar className={classes.toolbar}>
                <NavImpl />
                <NavHome />
                {useIsComplete && (
                  <>
                    <Box className={classes.grow}></Box>
                    <Box className={classes.appBarMenu}>
                      <Button onClick={() => redirect('myTrips')}>
                        <FormattedMessage {...messages.myTrips} />
                      </Button>
                      <Button onClick={() => redirect('chats')}>
                        <FormattedMessage {...messages.tChat} />
                      </Button>
                      <IconButton aria-label="add" color="primary" onClick={() => redirect('friends')}>
                        <PeopleAltIcon />
                      </IconButton>
                    </Box>
                  </>
                )}

                {useIsComplete && location.pathname === '/' && (
                  <IconButton aria-label="bell" className={classes.badge} onClick={handleNotificationsClick}>
                    <Badge
                      badgeContent={unredEventsCount}
                      color="secondary"
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'top',
                      }}
                    >
                      <NotificationsNoneOutlinedIcon className={classes.buttonIcon} />
                    </Badge>
                  </IconButton>
                )}

                <Popover
                  id="menu-appbar"
                  anchorEl={userMenuAnchor}
                  open={isUserMenuOpen}
                  onClose={closeUserMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <UserCard user={user} handleClose={closeUserMenu} setDialog={setLogoutDialog} />
                </Popover>
              </Toolbar>
            </AppBar>
          )}

        <MakeRoutes />
      </NavigationProvider>

      {snackBar && snackBar.open && <SnackBar info={snackBar} resetSnackBar={resetSnackBar} />}

      <Dialog
        dialog={logoutDialog}
        title={intl.formatMessage(messages.dialogTitle)}
        text={intl.formatMessage(messages.logoutDialogText)}
        onOkActionName={intl.formatMessage(messages.confirm)}
        setDialog={setLogoutDialog}
        onOk={() => {
          logout();
          window.location.reload();
        }}
      />
    </Box>
  );
};

export default App;
