import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../actions';
import { getUser } from '../../selectors/auth';
import { getCore } from '../../selectors/core';
import { getSnackBar } from '../../selectors/ui';
import { getUnredEventsCount } from '../../selectors/events';
import { isMobileBrowser } from '../../utils/ui';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    unredEventsCount: useSelector(getUnredEventsCount),
    snackBar: useSelector(getSnackBar),
    notifications: useSelector(getSnackBar),
    core: useSelector(getCore),
    resetSnackBar: () => dispatch(actions.ui.resetSnackBar()),
    logout: () => dispatch(actions.auth.signOut()),
    toggleNotifications: () => dispatch(actions.ui.toggleNotifications()),
    clearTunnel: () => dispatch(actions.tunnel.clearTunnel()),
    setAllEventsAsRed: () => dispatch(actions.events.setAllEventsAsRed()),
    setLocation: position => dispatch(actions.bikers.setLocation(position)),
    handleNativeActions: data => dispatch(actions.native.handleNativeActions(data)),
    getIsNative: () => dispatch(actions.native.postMessage({ type: NATIVE_ACTIONS.IS_NATIVE })),
  };
};

export const useLogoutDialog = () => {
  const [logoutDialog, setLogoutDialog] = useState(false);
  return [logoutDialog, setLogoutDialog];
};

export const useNotifications = () => {
  const [notifications, setNotifications] = useState(false);
  return [notifications, () => setNotifications(!notifications)];
};

export const useLoginPopup = () => {
  const [loginPopup, setLoginPopup] = useState(false);

  return [loginPopup, setLoginPopup];
};

export const useMenu = () => {
  const [userMenuAnchor, useUserMenuAnchor] = useState(null);
  const openUserMenu = event => useUserMenuAnchor(event.currentTarget); // eslint-disable-line
  const closeUserMenu = () => useUserMenuAnchor(null); // eslint-disable-line

  return {
    userMenuAnchor,
    openUserMenu,
    closeUserMenu,
  };
};

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  return [isMobile, () => setIsMobile(isMobileBrowser())];
};
