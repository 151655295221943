import { prop, propOr, filter, compose, values, path, pathOr, head } from 'ramda';
import { createSelector } from 'reselect';
import { getUser } from './auth';
import { isLocated } from '../../lib/models/bikers';

import { getFriends } from './friends';
import * as wheelsDM from '../../lib/models/wheels';
import { isPrivate } from '../../lib/models/trips';

const root = prop('tunnel');

export const getTripId = createSelector(
  root,
  prop('id'),
);

export const getCurrentStep = createSelector(
  root,
  prop('currentStep'),
);

export const getTrippers = createSelector(
  root,
  compose(
    values,
    pathOr([], ['trippers', 'data']),
  ),
);

export const getRidingTrippers = createSelector(
  getTrippers,
  trippers =>
    filter(tripper => tripper.status === wheelsDM.STATUS.riding || tripper.status === wheelsDM.STATUS.suspended)(
      trippers,
    ),
);
export const getTripper = id =>
  createSelector(
    root,
    compose(
      prop(id),
      pathOr({}, ['trippers', 'data']),
    ),
  );

export const getTrip = createSelector(
  root,
  prop('trip'),
);

export const getSelectedRoute = createSelector(
  root,
  prop('route'),
);

export const getStartingAddress = createSelector(
  root,
  prop('startingAddress'),
);

export const getRouteFeature = index =>
  createSelector(
    getSelectedRoute,
    route => pathOr(path([('location', 'features', 0)], route), ['location', 'features', index], route),
  );

export const getRouteAddress = index =>
  createSelector(
    getDirection,
    path(['waypoints', index, 'name']),
  );

export const getTrips = createSelector(
  root,
  compose(
    values,
    pathOr([], ['trips', 'data']),
  ),
);

export const getAccessibleTrips = createSelector(
  getTrips,
  getFriends,
  (trips, friends) => filter(trip => friends[trip.leaderId] || !isPrivate(trip))(trips),
);

export const getTrippersExceptLeader = createSelector(
  getTrippers,
  getTrip,
  (trippers, trip) => filter(tripper => tripper.bikerId !== trip.leaderId)(trippers),
);

export const getLeader = createSelector(
  getTrippers,
  getTrip,
  (trippers, trip) =>
    compose(
      head,
      filter(tripper => tripper.bikerId === trip.leaderId),
    )(trippers),
);

export const getTripType = createSelector(
  getTrip,
  prop('type'),
);

export const getTunnelMode = createSelector(
  root,
  prop('currentMode'),
);

export const getCurrentTrip = createSelector(
  getTripId,
  getTrips,
  (id, trips) => trips[id],
);

export const getBikers = createSelector(
  root,
  propOr([], 'bikers'),
);

export const getOtherBikers = createSelector(
  getBikers,
  getUser,
  (bikers, user) => filter(biker => biker._id !== user._id, bikers),
);

export const getLocatedBikers = createSelector(
  getOtherBikers,
  filter(isLocated),
);

export const getPosition = createSelector(
  root,
  prop('position'),
);

export const getDirection = createSelector(
  root,
  prop('directions'),
);

export const getInfo = createSelector(
  root,
  prop('info'),
);

export const getFilter = createSelector(
  root,
  prop('filter'),
);

export const getRoutes = createSelector(
  root,
  propOr([], 'routes'),
);

export const getSameDayTrips = createSelector(
  root,
  prop('sameDayTrips'),
);

export const getSameDayTripsExceptCurrent = createSelector(
  getTrip,
  getSameDayTrips,
  (activeTrip, sameDayTrips = []) =>
    activeTrip ? sameDayTrips.filter(trip => trip._id !== prop('_id', activeTrip)) : sameDayTrips,
);
