import { defineMessages } from 'react-intl';

export default defineMessages({
  creation: {
    id: 'components.WhatWeDo.creation',
    defaultMessage: 'Création',
  },
  discover: {
    id: 'components.WhatWeDo.discover',
    defaultMessage: 'Découvrir',
  },
  gestion: {
    id: 'components.WhatWeDo.gestion',
    defaultMessage: 'Gestion',
  },
  creationText: {
    id: 'components.WhatWeDo.creationText',
    defaultMessage: 'Édite tes balades de A à Z ou inspire toi de notre catalogue de balades à proximité.',
  },
  discoverText: {
    id: 'components.WhatWeDo.discoverText',
    defaultMessage: 'Rejoins des balades publiques, ou entre amis en seulement quelques clics.',
  },
  gestionText: {
    id: 'components.WhatWeDo.gestionText',
    defaultMessage:
      "Être informé en temps réel de l'apparition d'une balade à proximité et discute avec tes Co-Pilotes pour bien préparer ta sortie.",
  },
});
