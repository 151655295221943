import { propEq, prop, equals, or } from 'ramda';
import { isFuture, isPast } from 'date-fns';

export const STATUS = {
  active: 'active',
  draft: 'draft',
  canceled: 'canceled',
  done: 'done',
  deleted: 'deleted',
};

export const MODE = {
  create: 'create',
  createWithRoute: 'createWithRoute',
  edit: 'edit',
  redo: 'redo',
};

export const TYPES = {
  created: 'created',
  joined: 'joined',
  history: 'history',
};

export const TRIP_TYPE = {
  public: 'public',
  private: 'private',
};

export const TRYP_CLASSES = ['arsouille', 'tranquille', 'enduro', '125cc'];

export const statusEq = propEq('status');
export const isActive = statusEq(STATUS.active);
export const isDraft = statusEq(STATUS.draft);
export const isCanceled = statusEq(STATUS.canceled);
export const isDone = statusEq(STATUS.done);

export const typeEq = propEq('tripType');
export const isPublic = typeEq(TRIP_TYPE.public);
export const isPrivate = typeEq(TRIP_TYPE.private);

export const userIsLeader = (user, trip) => equals(prop('_id', user), prop('leaderId', trip));

export const isMyTrip = (trip, user) =>
  prop('leaderId', trip) === user._id && or(isFuture(new Date(trip.startAt)) && isActive(trip), isDraft(trip));
export const isJoinedTrip = (trip, user) =>
  prop('leaderId', trip) !== user._id && or(!isCanceled(trip) && isFuture(new Date(trip.startAt)), isDraft(trip));
export const isHistoryTrip = trip => isPast(new Date(trip.startAt)) || isCanceled(trip) || isDone(trip);

export const getTripType = (trip, user) => {
  if (isMyTrip(trip, user)) return TYPES.created;
  if (isJoinedTrip(trip, user)) return TYPES.joined;
  if (isHistoryTrip(trip)) return TYPES.history;
};
