import { compose, filter, identity, pluck } from 'ramda';
import { combineReducers } from 'redux';
import ducks from './ducks';

const reducers = compose(
  filter(identity),
  pluck('reducer'),
)(ducks);
const createRootReducer = () => combineReducers(reducers);
export default createRootReducer;
