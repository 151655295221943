import { SIGNED_OUT } from './auth'; // eslint-disable-line
export const SET_SNACKBAR = '@UI/SET_SNACKBAR';
export const RESET_SNACKBAR = '@UI/RESET_SNACKBAR';

const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackBar: action.snackBarInfo,
      };
    case RESET_SNACKBAR:
      return {
        ...state,
        snackBar: {},
      };
    case SIGNED_OUT:
      return {};
    default:
      return state;
  }
};

export const setSnackBar = snackBarInfo => ({ type: SET_SNACKBAR, snackBarInfo });
export const resetSnackBar = () => ({ type: RESET_SNACKBAR });

const actions = { setSnackBar, resetSnackBar };
export default { reducer, actions };
