import { propEq } from 'ramda';

export const BIKER_MOVED = '@events:BIKER_MOVED';
export const BIKER_RIDING = '@events:BIKER_RIDING';
export const BIKER_JOINED_TRIP = '@events:BIKER_JOINED_TRIP';
export const BIKER_REQUEST_TO_JOIN_TRIP = '@events:BIKER_REQUEST_TO_JOIN_TRIP';
export const BIKER_CONFIRMED_TO_JOIN_TRIP = '@events:BIKER_CONFIRMED_TO_JOIN_TRIP';
export const BIKER_REJECTED_TO_JOIN_TRIP = '@events:BIKER_REJECTED_TO_JOIN_TRIP';
export const BIKER_CANCELED_PARTICIPATION = '@events:BIKER_CANCELED_PARTICIPATION';
export const TRIP_CREATED = '@events:TRIP_CREATED';
export const TRIP_CANCELED = '@events:TRIP_CANCELED';
export const TRIP_START_SOON = '@events:TRIP_START_SOON';
export const MESSAGE_PUBLISHED = '@events:MESSAGE_PUBLISHED';
export const ROADTRIP_COMING_SOON = '@events:ROADTRIP_COMING_SOON';
export const ROADTRIP_STARTED = '@events:ROADTRIP_STARTED';
export const UNREAD_MESSAGES = '@events:UNREAD_MESSAGES';
export const TRIP_ENDED = '@events:TRIP_ENDED';
export const TRIP_UPDATED = '@events:TRIP_UPDATED';
export const TRIP_REMINDER = '@events:TRIP_REMINDER';
export const FRIEND_REQUEST_RECIEVED = '@events:FRIEND_REQUEST_RECIEVED';
export const FRIEND_REQUEST_ACCEPTED = '@events:FRIEND_REQUEST_ACCEPTED';
export const INVITATION_SENT = '@events:INVITATION_SENT';
export const BIKER_CONFIRMED_INVITATION = '@events:BIKER_CONFIRMED_INVITATION';
export const BIKER_REJECTED_INVITATION = '@events:BIKER_REJECTED_INVITATION';
export const ACCOUNT_CONFIRMED = '@events:ACCOUNT_CONFIRMED';
export const MESSAGE = '@events:MESSAGE';
export const PREMIUM_WILL_EXPIRE = '@events:PREMIUM_WILL_EXPIRE';
export const PREMIUM_ACTIVE = '@events:PREMIUM_ACTIVE';

export const REMINDER_TYPES = { in8Hours: 'in8Hours', in1Day: 'in1Day', in3Days: 'in3Days', in7Days: 'in7Days' };

export const statusEq = propEq('type');

export const isPending = statusEq(BIKER_REQUEST_TO_JOIN_TRIP);
