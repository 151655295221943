import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getcurrentTripId } from '../../selectors/ridingTrip';
import { getUser } from '../../selectors/auth';
import { getTrip } from '../../selectors/trips';
import actions from '../../actions';
import { getBikersArray, getBikersCount } from '../../selectors/bikers';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    trip: useSelector(getTrip(useSelector(getcurrentTripId))),
    bikersCount: useSelector(getBikersCount),
    bikers: useSelector(getBikersArray) || [],
    setSnackBar: snackBar => dispatch(actions.ui.setSnackBar(snackBar)),
    addBiker: biker => dispatch(actions.bikers.addBiker(biker)),
    loadOne: id => dispatch(actions.bikers.loadOne(id)),
    update: biker => dispatch(actions.bikers.adminUpdate(biker)),
  };
};

export const useCurrentBiker = () => {
  const [currentBiker, setCurrentBiker] = useState();
  return [currentBiker, setCurrentBiker];
};

export const usePremiumEndDate = () => {
  const [premiumEndDate, setPremiumEndDate] = useState();
  return [premiumEndDate, setPremiumEndDate];
};
