import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { prop } from 'ramda';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import MapIcon from '@material-ui/icons/Map';
import WatchIcon from '@material-ui/icons/Watch';
import Typography from '@material-ui/core/Typography';
import Avatar from '../Avatar';
import MapImage from './MapImage';
import useRoutes from '../../hooks/routes';
import { isGathering } from '../../../lib/models/routes';
import { secondsToMin } from '../../utils/time';
import { to2Digits } from '../../utils/numbers';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    height: '100%',
    width: '340px',
    borderRadius: '40px',
    '& > div': {
      boxShadow: 'none',
    },
  },
  card: {
    height: '100%',
    maxWidth: '340px',
  },
  media: {
    height: 140,
  },
  text: {
    fontSize: '.9rem',
    overflow: 'hidden',
    textOverflow: ' ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  startingMarker: {
    height: '16px',
    marginRight: ' 6px',
    width: 'auto',
    marginBottom: '.2rem',
  },
  infoIcon: {
    marginBottom: '.6rem',
  },
  bikersCount: {
    marginLeft: 'auto',
  },
  cardContent: {
    padding: 0,
  },
  cardInfoContent: {
    maxWidth: '340px',
    overflow: 'hidden',
  },
  cardInfo: {
    padding: theme.spacing(0.75),
  },
  icon: {
    marginBottom: '-5px',
    width: '.8em',
    height: '.8em',
  },
  iconBtn: {
    padding: 0,
  },
  rating: {
    marginTop: theme.spacing(2),
    opacity: 1,
  },

  fullTripCard: {
    borderColor: theme.palette.secondary.main,
    border: '1px solid',

    borderRadius: '40px',
  },
  tripName: {
    fontWeight: 'bold',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '160px',
    whiteSpace: 'nowrap',
  },
  cardStyle: {
    borderRadius: '40px',
    overflow: 'hidden',
  },
  titleAndLocation: {
    width: '200px',
  },
  titleStyle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '180px',
    whiteSpace: 'nowrap',
  },
  locationText: {
    fontSize: '.9rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '160px',
    whiteSpace: 'nowrap',
  },
  secondIcon: {
    marginTop: '7px',
  },
}));

const PopupCard = ({ trip }) => {
  const classes = useStyles();
  const { route } = trip;
  const routes = useRoutes();
  const isTripFull = trip => prop('trippersCount', trip) === prop('maxBikers', trip);
  const history = useHistory();

  return (
    <Grid container direction="column" justify="flex-end" className={classes.cardContainer}>
      <Box boxShadow={3} className={classes.cardBox}>
        <Card className={isTripFull(trip) ? clsx(classes.fullTripCard, classes.cardStyle) : classes.cardStyle}>
          <CardActionArea
            className={classes.card}
            onClick={() => {
              history.push(routes.getPathByName('trip', trip._id));
            }}
          >
            <CardContent className={classes.cardContent}>
              {trip && <MapImage mapId={prop('routeId', trip)} />}

              <Grid container item direction="row" spacing={1} className={classes.cardInfo} wrap="nowrap">
                <Grid item>
                  <Avatar biker={trip.leader.biker} redirect />
                </Grid>
                <Grid item container direction="column">
                  <Grid item className={classes.titleAndLocation}>
                    <Typography className={classes.tripName} gutterBottom>
                      {prop('name', trip)}
                    </Typography>
                  </Grid>
                  <Grid item container className={classes.text} wrap="nowrap">
                    <Grid item>
                      <img src="/markerStart.png" alt="start" className={classes.startingMarker} />
                    </Grid>
                    <Grid item>
                      <Box className={classes.text}>
                        <Typography className={classes.locationText}>{prop('startingAddress', route)}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {route && !isGathering(route) && (
                  <Grid item container direction="column" wrap="nowrap">
                    <Grid item className={classes.cardInfoContent}>
                      <Typography variant="caption" color="textSecondary">
                        <MapIcon className={classes.icon} /> {`${prop('distance', route)} km`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.secondIcon}>
                      <Typography variant="caption" color="textSecondary">
                        <WatchIcon className={classes.icon} /> {to2Digits(secondsToMin(prop('time', route) * 60))}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Grid>
  );
};

PopupCard.propTypes = {
  trip: PropTypes.object,
};
export default PopupCard;
