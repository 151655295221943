import { compose, propOr, reduce, lensPath, set, mapObjIndexed, forEach, pathOr, reject, dissoc } from 'ramda';
import { getEvents } from '../selectors/events';
import { getcurrentTripId } from '../selectors/ridingTrip';
import { request } from './utils';
import trips from './trips'; // eslint-disable-line
import bikers from './bikers'; // eslint-disable-line
import ridingTrip from './ridingTrip'; // eslint-disable-line

import {
  BIKER_CONFIRMED_TO_JOIN_TRIP,
  BIKER_REQUEST_TO_JOIN_TRIP,
  BIKER_REJECTED_TO_JOIN_TRIP,
  BIKER_JOINED_TRIP,
  TRIP_CREATED,
  TRIP_CANCELED,
  TRIP_START_SOON,
  BIKER_MOVED,
  BIKER_RIDING,
  MESSAGE_PUBLISHED,
  BIKER_CANCELED_PARTICIPATION,
  ROADTRIP_COMING_SOON,
  ROADTRIP_STARTED,
  TRIP_ENDED,
  UNREAD_MESSAGES,
  TRIP_UPDATED,
  TRIP_REMINDER,
  FRIEND_REQUEST_ACCEPTED,
  FRIEND_REQUEST_RECIEVED,
  INVITATION_SENT,
  BIKER_CONFIRMED_INVITATION,
  BIKER_REJECTED_INVITATION,
  ACCOUNT_CONFIRMED,
  PREMIUM_ACTIVE,
} from '../../lib/models/events';
import trippers, { TRIPPERS_ACCEPTED, TRIPPERS_REJECTED } from './trippers'; // eslint-disable-line
import { REQUEST_REJECTED, REQUEST_ACCEPTED } from './friends'; // eslint-disable-line
import { SIGNED_OUT, DEC_TRIPS_COUNT, refreshUser } from './auth'; // eslint-disable-line

const ADD_EVENT = '@events:ADD_EVENT';
const REMOVE_EVENT = '@events:REMOVE_EVENT';
export const EVENTS_LOADED = '@events:EVENTS_LOADED';
const SET_EVENT_AS_RED = '@events:SET_EVENT_AS_RED';
const SET_ALL_EVENTS_AS_RED = '@events:SET_ALL_EVENTS_AS_RED';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case REMOVE_EVENT:
      return {
        ...state,
        data: dissoc(action.event._id, state.data),
      };
    case ADD_EVENT:
      return {
        ...state,
        data: { [action.event._id]: action.event, ...state.data },
      };
    case SET_EVENT_AS_RED:
      return {
        ...state,
        data: set(lensPath([action.id, 'readAt']), pathOr(new Date()), state.data),
      };
    case SET_ALL_EVENTS_AS_RED:
      data = mapObjIndexed(num => ({ ...num, readAt: num.readAt || new Date() }), state.data);
      return {
        ...state,
        data,
      };
    case EVENTS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'events'),
      )(action);
      return { ...state, data };
    case TRIPPERS_ACCEPTED:
    case TRIPPERS_REJECTED:
      data = reject(
        event =>
          action.tripper.tripId === event.tripId &&
          (action.tripper.bikerId === event.bikerId || event.targetId) &&
          (event.type === BIKER_REQUEST_TO_JOIN_TRIP || event.type === INVITATION_SENT),
        state.data,
      );
      return {
        ...state,
        data,
      };

    case REQUEST_REJECTED:
    case REQUEST_ACCEPTED:
      data = reject(event => action.bikerId === event.bikerId && event.type === FRIEND_REQUEST_RECIEVED, state.data);
      return {
        ...state,
        data,
      };
    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};
// eslint-disable-next-line
const emitEventToTripper = event => dispatch => {
  Promise.all([
    dispatch(trips.actions.loadOne(event.tripId)),
    // dispatch(bikers.actions.loadOne(event.bikerId)),
    dispatch(bikers.actions.loadOne(event.leaderId)),
  ]).then(() => dispatch({ type: ADD_EVENT, event }));
};
// eslint-disable-next-line
const emitEventToLeader = event => dispatch => {
  Promise.all([dispatch(trips.actions.loadOne(event.tripId)), dispatch(bikers.actions.loadOne(event.bikerId))]).then(
    () => dispatch({ type: ADD_EVENT, event }),
  );
};

// const emitEventToAll = event => dispatch => {
//   Promise.all([dispatch(trips.actions.loadOne(event.tripId)), dispatch(bikers.actions.loadOne(event.bikerId), dispatch(bikers.actions.loadOne(event.leaderId)))]).then(
//     () => dispatch({ type: ADD_EVENT, event }),
//   );
// };

const loadAll = () => dispatch =>
  request(dispatch, { method: 'events:loadAll' }).then(notifications => {
    dispatch({ type: EVENTS_LOADED, ...notifications });
    return notifications;
  });

const setEventAsRed = id => dispatch => {
  request(dispatch, { method: 'events:read', id });
  dispatch({ type: SET_EVENT_AS_RED, id });
};

const setAllEventsAsRed = () => (dispatch, getState) => {
  forEach(event => {
    if (!event.readAt) request(dispatch, { method: 'events:read', id: event._id });
  }, getEvents(getState()));
  dispatch({ type: SET_ALL_EVENTS_AS_RED });
};

const emitIncomingTrip = event => dispatch => {
  dispatch(ridingTrip.actions.setActiveTripInfo(event.tripId));
  return dispatch(event);
};
const emitMessagePublished = event => dispatch => {
  dispatch(event);
};

const emitUnreadMessages = event => dispatch => dispatch({ type: ADD_EVENT, event });

const emitBikerEvent = event => (dispatch, getState) =>
  event.tripId === getcurrentTripId(getState()) && dispatch(event);

const removeEvent = event => dispatch => dispatch({ type: REMOVE_EVENT, event });

const remove = event => dispatch => {
  dispatch(removeEvent(event));
  request(dispatch, { method: 'events:remove', id: event._id });
};

const emitJoinEvent = event => dispatch => {
  dispatch(loadAll());
  dispatch(trippers.actions.loadOne(event.tripperId));
};

const handlePremiumActive = () => dispatch => {
  dispatch(refreshUser());
};

export const emit = event => dispatch => {
  switch (event.type) {
    case TRIP_CREATED:
    case TRIP_ENDED:
    case TRIP_START_SOON:
    case TRIP_UPDATED:
    case TRIP_REMINDER:
    case FRIEND_REQUEST_ACCEPTED:
    case FRIEND_REQUEST_RECIEVED:
    case INVITATION_SENT:
    case BIKER_REQUEST_TO_JOIN_TRIP:
    case BIKER_CANCELED_PARTICIPATION:
      return dispatch(loadAll());
    case TRIP_CANCELED:
      dispatch({ type: DEC_TRIPS_COUNT });
      return dispatch(loadAll());
    case BIKER_REJECTED_TO_JOIN_TRIP:
    case BIKER_CONFIRMED_TO_JOIN_TRIP:
    case BIKER_JOINED_TRIP:
    case BIKER_CONFIRMED_INVITATION:
    case BIKER_REJECTED_INVITATION:
      return dispatch(emitJoinEvent(event));
    case MESSAGE_PUBLISHED:
      return dispatch(emitMessagePublished(event));
    case UNREAD_MESSAGES:
      return dispatch(emitUnreadMessages(event));
    case ROADTRIP_COMING_SOON:
    case ROADTRIP_STARTED:
      return dispatch(emitIncomingTrip(event));
    case BIKER_RIDING:
    case BIKER_MOVED:
      return dispatch(emitBikerEvent(event));
    case ACCOUNT_CONFIRMED:
      return dispatch({ type: ACCOUNT_CONFIRMED });
    case PREMIUM_ACTIVE:
      return dispatch(handlePremiumActive(event));
    default:
      return { type: 'UNKNOWN_EVENT', event };
  }
};

const actions = { emit, loadAll, setEventAsRed, setAllEventsAsRed, removeEvent, remove };

export default { reducer, actions };
