import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  linearProgress: {
    width: '100%',
    position: 'fixed',
    zIndex: '4',
  },
}));

const Loader = () => {
  const classes = useStyles();
  return <LinearProgress className={classes.linearProgress} />;
};

export default Loader;
