import React from 'react';

import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  bikeImage: {
    width: '100%',
    borderRadius: '10px',
  },
  titleContainer: {
    marginBottom: theme.spacing(6),
    '& h6': {
      fontSize: '2em',
    },
  },
  desc: { fontSize: '1.5em' },

  textContainer: {
    padding: theme.spacing(2),
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid container direction="row" justify="center" spacing={6} className={classes.container}>
        <Grid container direction="column" item xs={7} spacing={3} className={classes.textContainer}>
          <Grid item className={classes.titleContainer}>
            <Typography align="center" variant="h6">
              <FormattedMessage {...messages.sectionTitle} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="right" className={classes.desc}>
              <FormattedMessage {...messages.sectionText} values={{ br: <br /> }} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <img className={classes.bikeImage} src="/aboutUsImage.jpg" alt="bike" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
