import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import messages from './messages';
import Avatar from '../Avatar';
import useRoutes from '../../hooks/routes';
import { fullname } from '../../../lib/models/bikers';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 300,
    boxShadow: 'unset',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  actions: {
    display: 'flex',
  },
  button: {
    textTransform: 'none',

    margin: 'auto',
    display: 'block',
  },
  premiumBtn: {
    color: '#ffb14a',
    textTransform: 'none',
    fontSize: '1rem',
    margin: 'auto',
    display: 'block',
    fontWeight: '700',
  },
  legalMentionBtn: {
    display: 'block',
    maxWidth: '200px',
    fontSize: '.8em',
    margin: 'auto',
    fontWeight: 700,
    color: theme.palette.grey[500],
    textTransform: 'none',
    textAlign: 'center',
  },
}));

export const UserCard = ({ handleClose, user, setDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const routes = useRoutes();
  return (
    <Card className={classes.card}>
      {user && <CardHeader avatar={<Avatar biker={user} redirect />} title={fullname(user)} subheader={user.email} />}
      <CardActions className={classes.actions}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              onClick={() => {
                handleClose();
                history.push(routes.getPathByName('profile'));
              }}
              size="small"
              color="primary"
            >
              <FormattedMessage {...messages.displayProfile} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              size="small"
              color="primary"
              onClick={() => {
                handleClose();
                setDialog(true);
              }}
            >
              <FormattedMessage {...messages.logout} />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              href=" https://www.co-motard.com/mention-l%C3%A9gales"
              target="_blank"
              className={classes.legalMentionBtn}
            >
              <FormattedMessage {...messages.legalMention} />
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              size="small"
              color="primary"
              className={classes.premiumBtn}
              onClick={() => {
                history.push(routes.getPathByName('premium'));
              }}
            >
              <FormattedMessage {...messages.comotPremium} />
            </Button>
          </Grid> */}
        </Grid>
      </CardActions>
    </Card>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
  setDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCard;
