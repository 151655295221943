import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  signInWithFacebook: data => axios.post(endPoint(`/api/auth/signInWithFacebook`), { ...data }).then(prop('data')),
  signInWithGoogle: data => axios.post(endPoint(`/api/auth/signInWithGoogle`), { ...data }).then(prop('data')),
  signInWithEmail: data => axios.post(endPoint(`/api/auth/signInWithEmail`), { ...data }).then(prop('data')),
  resetPasswordEmail: data => axios.post(endPoint(`/api/auth/resetPasswordEmail`), { ...data }).then(prop('data')),
  resetPasswordRequest: data => axios.post(endPoint(`/api/auth/resetPasswordRequest`), { ...data }).then(prop('data')),
  editPassword: data =>
    axios.post(endPoint(`/api/auth/editPassword`), { ...data }, { headers: bearer() }).then(prop('data')),

  confirmationMail: () =>
    axios.post(endPoint(`/api/auth/confirmationMail`), {}, { headers: bearer() }).then(prop('data')),
  confirmAccount: data =>
    axios.post(endPoint(`/api/auth/confirmAccount`), { ...data }, { headers: bearer() }).then(prop('data')),
  signUp: data => axios.post(endPoint(`/api/auth/signUp`), { ...data }).then(prop('data')),
  signOut: () => axios.get(endPoint(`/api/auth/signOut`), { headers: bearer() }).then(prop('data')),
  getUser: () => axios.get(endPoint(`/api/auth/getUser`), { headers: bearer() }).then(prop('data')),
  createMonthPaymentIntent: () =>
    axios.get(endPoint(`/api/auth/createMonthPaymentIntent`), { headers: bearer() }).then(prop('data')),
  createYearPaymentIntent: () =>
    axios.get(endPoint(`/api/auth/createYearPaymentIntent`), { headers: bearer() }).then(prop('data')),
  addExpoToken: data =>
    axios.post(endPoint(`/api/auth/addExpoToken`), { ...data }, { headers: bearer() }).then(prop('data')),
});
