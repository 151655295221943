import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getcurrentTripId } from '../../selectors/ridingTrip';
import { getUser } from '../../selectors/auth';
import { getTrip } from '../../selectors/trips';
import actions from '../../actions';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    user: useSelector(getUser),
    trip: useSelector(getTrip(useSelector(getcurrentTripId))),
    end: id => dispatch(actions.trippers.end(id)),
    postMessage: message => dispatch(actions.native.postMessage(message)),
  };
};

export const useEndDialog = () => {
  const [endDialog, setEndDialog] = useState(false);
  return [endDialog, setEndDialog];
};
