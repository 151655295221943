import React from 'react';

import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import messages from './messages';

const COMOT_WEBSITE = 'https://www.co-motard.com/';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  bikeImage: {
    width: '100%',
    borderRadius: '10px',
  },
  button: {
    width: '100%',
    margin: 'auto',
    textTransform: 'none',
    maxWidth: '80%',
    display: 'block',
    borderRadius: '10px',
  },
  titleContainer: {
    marginBottom: theme.spacing(6),
    '& h6': {
      fontSize: '2em',
    },
  },
  desc: { fontSize: '1.5em', marginBottom: theme.spacing(6) },

  anchor: {
    textDecoration: 'none',
  },
}));

const KnowMore = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid container direction="row" justify="center" spacing={6} className={classes.container}>
        <Grid item xs={5}>
          <img className={classes.bikeImage} src="/knowMore.jpg" alt="bike" />
        </Grid>
        <Grid container direction="column" item xs={7} spacing={3} className={classes.textContainer}>
          <Grid item className={classes.titleContainer}>
            <Typography align="center" variant="h6">
              <FormattedMessage {...messages.qutions} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.desc}>
              <FormattedMessage {...messages.text} values={{ br: <br /> }} />
            </Typography>
          </Grid>
          <Grid item>
            <a className={classes.anchor} target="_blank" href={COMOT_WEBSITE} rel="noopener noreferrer">
              <Button variant="contained" color="primary" className={classes.button}>
                <Typography>
                  <FormattedMessage {...messages.btnTxt} />
                </Typography>
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KnowMore;
