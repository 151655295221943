import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  loadOne: ({ id }) => axios.get(endPoint(`/api/friends/loadOne/${id}`), { headers: bearer() }).then(prop('data')),
  loadAll: params => axios.post(endPoint(`/api/friends/loadAll`), params, { headers: bearer() }).then(prop('data')),
  create: params => axios.post(endPoint(`/api/friends/create`), params, { headers: bearer() }).then(prop('data')),
  accept: params => axios.post(endPoint(`/api/friends/accept`), params, { headers: bearer() }).then(prop('data')),
  reject: params => axios.post(endPoint(`/api/friends/reject`), params, { headers: bearer() }).then(prop('data')),
  cancel: params => axios.post(endPoint(`/api/friends/cancel`), params, { headers: bearer() }).then(prop('data')),
  remove: params => axios.post(endPoint(`/api/friends/remove`), params, { headers: bearer() }).then(prop('data')),
});
