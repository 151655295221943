import { pathOr } from 'ramda';

export const START_REQUEST = '@core/START_REQUEST';
export const END_REQUEST = '@core/END_REQUEST';
export const REQUEST_ERROR = '@core/REQUEST_ERROR';
export const COMM_ERROR = 'commError';
export const AUTH_ERROR = 'authError';

const getErrorType = ({ statusCode }) => {
  if (statusCode === 401 || statusCode === 403) return AUTH_ERROR;
  return COMM_ERROR;
};

const initialState = { pendingRequests: 0 };
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_REQUEST:
      return {
        ...state,
        pendingRequests: state.pendingRequests + 1,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        error: { ...action.error, id: pathOr(0, ['error', 'id'], state) + 1, type: getErrorType(action.error) },
        pendingRequests: state.pendingRequests - 1,
      };
    case END_REQUEST:
      return {
        ...state,
        pendingRequests: state.pendingRequests - 1,
      };
    default:
      return state;
  }
};

export const startRequest = () => ({ type: START_REQUEST });
export const endRequest = () => ({ type: END_REQUEST });
export const requestError = ({ method, type, statusCode }) => ({
  type: REQUEST_ERROR,
  error: { method, type, statusCode },
});

export default { reducer };
