import { compose, reduce, propOr, assoc, pluck, values, uniq, path, prop, head } from 'ramda';
import { request } from './utils';
import { SIGNED_OUT } from './auth'; // eslint-disable-line
import { getAddress } from '../mapbox';
import bikers from './bikers'; //eslint-disable-line
const ROUTES_LOADED = '@routes:ROUTES_LOADED';
const ROUTE_LOADED = '@routes:ROUTE_LOADED';
const ROUTE_UPDATED = '@trips:ROUTE_UPDATED';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case ROUTES_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'routes'),
      )(action);
      return { ...state, data };
    case ROUTE_LOADED:
      return {
        ...state,
        data: assoc(action.route._id, action.route, state.data),
      };
    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};

const loadAll = options => dispatch =>
  request(dispatch, { method: 'routes:loadAll', ...options }).then(routes => {
    const routesAuthorId = compose(
      uniq,
      values,
      pluck('authorId'),
    )(routes);
    dispatch(bikers.actions.loadAll({ ids: routesAuthorId }));
    dispatch({ type: ROUTES_LOADED, routes });
    return routes;
  });

const loadOne = id => dispatch =>
  request(dispatch, { method: 'routes:loadOne', id }).then(route => {
    dispatch({ type: ROUTE_LOADED, route });
    return route;
  });

const update = route => dispatch =>
  // eslint-disable-next-line
  getAddress(path(['location', 'features', 0, 'geometry', 'coordinates'], route)).then(response => {
    const startingAddress = compose(
      prop('place_name'),
      head,
      path(['data', 'features']),
    )(response);
    return (
      request(dispatch, { method: 'routes:update', ...route, startingAddress })
        // eslint-disable-next-line
        .then(ROUTE_UPDATED, route => {
          dispatch({ type: ROUTE_UPDATED, route });
          return route;
        })
        .catch(e => e)
    );
  });

const actions = {
  loadAll,
  loadOne,
  update,
};

export default { reducer, actions };
