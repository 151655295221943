import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    id: 'components.Login.welcome',
    defaultMessage: 'Bienvenue à toi Co-Pilote !',
  },
  connect: {
    id: 'components.Login.connect',
    defaultMessage: 'Connecte toi pour continuer',
  },
  facebook: {
    id: 'components.Login.facebook',
    defaultMessage: 'AVEC FACEBOOK',
  },
  google: {
    id: 'components.Login.google',
    defaultMessage: 'AVEC GOOGLE',
  },
  addressMail: {
    id: 'components.Login.addressMail',
    defaultMessage: 'AVEC TON ADRESSE MAIL',
  },
  legalMention: {
    id: 'components.Login.legalMention',
    defaultMessage: 'Mentions légales',
  },
});
