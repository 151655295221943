import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    padding: 0,
    minWidth: 'auto',
  },
  confirmBtn: {
    margin: 'auto',
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover,&active,&focus': {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
    borderRadius: theme.spacing(2),
  },

  cancelBtn: {
    margin: 'auto',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover,&active,&focus': {
      color: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },
    borderRadius: theme.spacing(2),
  },
  validate: {
    marginRight: theme.spacing(1),
  },
}));

const LogoutDialog = ({
  setDialog,
  dialog,
  title,
  text,
  onOk,
  onClose,
  onWarning,
  onOkActionName,
  onWarningActionName,
  children,
  anchor,
  buttonType,
  custromAction,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
    setDialog(false);
  };

  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={dialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Button color="secondary" className={classes.closeBtn}>
        <CloseIcon onClick={handleClose} />
      </Button>

      {title && <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>}
      {children}
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{text}</DialogContentText>
        </DialogContent>
      )}

      {custromAction}
      {onOk && (
        <DialogActions>
          <Button
            onClick={() => {
              onOk();
              setDialog(false);
            }}
            variant="outlined"
            className={classes.confirmBtn}
          >
            <CheckIcon className={classes.validate} />
            {onOkActionName}
          </Button>
        </DialogActions>
      )}
      {anchor}
      {onWarning && (
        <DialogActions>
          <Button
            type={buttonType}
            onClick={() => {
              onWarning();
              setDialog(false);
            }}
            variant="outlined"
            className={classes.cancelBtn}
          >
            <CloseIcon className={classes.cancel} />
            {onWarningActionName}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

LogoutDialog.propTypes = {
  buttonType: PropTypes.string,
  dialog: PropTypes.bool,
  onOk: PropTypes.func,
  onWarning: PropTypes.func,
  setDialog: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  onOkActionName: PropTypes.string,
  onWarningActionName: PropTypes.string,
  children: PropTypes.node,
  anchor: PropTypes.node,
  custromAction: PropTypes.node,
  onClose: PropTypes.func,
};

export default LogoutDialog;
