import {
  compose,
  pathOr,
  prop,
  path,
  map,
  length,
  assoc,
  pluck,
  set,
  lensPath,
  dissocPath,
  assocPath,
  addIndex,
  dissoc,
  append,
  over,
  init,
  isNil,
  head,
} from 'ramda';

export const STATUS = {
  public: 'public',
  private: 'private',
};

export const getCoordinates = route =>
  compose(
    map(feature => path(['geometry', 'coordinates'], feature)),
    pathOr([], ['location', 'features']),
  )(route);

export const checkIfLastItemOfLoop = (index, route) => (getPointsCount(route) === index ? 0 : index);

const isLoop = route => prop('loop', route);

export const getPointsCountIgnoringLoop = route => (isLoop(route) ? getPointsCount(route) - 1 : getPointsCount(route));

export const getPointsCount = route =>
  compose(
    length,
    pathOr([], ['location', 'features']),
  )(route);

export const isGathering = route => getPointsCount(route) === 1;

export const duplicateSinglePoint = route => {
  if (getPointsCount(route) === 1)
    return over(lensPath(['location', 'features']), append(path(['location', 'features', 0], route)), route);
  return route;
};

export const deleteDuplicatedAddress = (waypoints, route) => {
  if (getPointsCount(route) === 1) return init(waypoints);
  return waypoints;
};

export const routeIsEmpty = route =>
  compose(
    isNil,
    head,
    path(['location', 'features']),
  )(route);

export const toMultipoint = route =>
  compose(
    set(lensPath(['location', 'type']), 'MultiPoint'),
    dissocPath(['location', 'features']),
    assocPath(
      ['location', 'coordinates'],
      map(path(['geometry', 'coordinates']), path(['location', 'features'], route)),
    ),
    assoc('properties', pluck('properties', path(['location', 'features'], route))),
  )(route);

export const toFeatures = route => {
  const mapIndexed = addIndex(map);

  const createFeatures = (item, index) => ({
    type: 'Feature',
    properties: pathOr({}, ['properties', index], route),
    geometry: {
      type: 'Point',
      coordinates: item,
    },
  });
  return compose(
    dissocPath(['location', 'coordinates']),
    dissoc('properties'),
    assocPath(['location', 'features'], mapIndexed(createFeatures, pathOr([], ['location', 'coordinates'], route))),
    assocPath(['location', 'type'], 'FeatureCollection'),
  )(route);
};
