import { useDispatch, useSelector } from 'react-redux';
import actions from '../../actions';
import { getIsNative } from '../../selectors/native';

export const useConnect = () => {
  const dispatch = useDispatch();
  return {
    isNative: useSelector(getIsNative),
    signInWithFacebook: data => dispatch(actions.auth.signInWithFacebook({ ...data })),
    signInWithGoogle: data => dispatch(actions.auth.signInWithGoogle({ ...data })),
    postMessage: data => dispatch(actions.native.postMessage(data)),
  };
};
