import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typograph from '@material-ui/core/Typography';
import Devider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'fixed',
    paddingBottom: theme.spacing(4),
  },

  button: {
    margin: 'auto',
    display: 'block',
  },
  title: {
    fontSize: '2rem',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  connect: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  btnContainer: {
    width: '100%',
  },

  legalMentionBtn: {
    marginTop: theme.spacing(2),
    display: 'block',
    maxWidth: '200px',
    fontSize: '.8em',
    margin: 'auto',
    fontWeight: 700,
    color: theme.palette.grey[500],
    textTransform: 'none',
    textAlign: 'center',
  },
}));

const LoginPopup = ({ dialog, setDialog }) => {
  const intl = useIntl();
  const classes = useStyles();
  const handleClose = () => {
    setDialog(false);
  };

  return (
    <Dialog
      open={dialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Grid item>
          <Paper className={classes.paper}>
            <Typograph className={classes.title} variant="h4" align="center">
              {intl.formatMessage(messages.welcome)}
            </Typograph>
            <Devider variant="middle" />
            <Typograph className={classes.connect} variant="body2" align="center">
              {intl.formatMessage(messages.connect)}
            </Typograph>
            <Grid item container direction="column" justify="center" alignItems="center">
              <Grid item className={classes.btnContainer}>
                <FacebookButton handleClose={handleClose} />

                <Grid item container justify="center">
                  <Grid item className={classes.btnContainer}>
                    <GoogleButton handleClose={handleClose} />
                  </Grid>
                  <Grid item className={classes.btnContainer}>
                    <Button
                      href=" https://www.co-motard.com/mention-l%C3%A9gales"
                      target="_blank"
                      className={classes.legalMentionBtn}
                    >
                      <FormattedMessage {...messages.legalMention} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

LoginPopup.propTypes = {
  dialog: PropTypes.bool,
  setDialog: PropTypes.func,
};

export default LoginPopup;
