import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import pink from '@material-ui/core/colors/pink';
import { propOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import messages from './messages';
import useRoutes from '../../hooks/routes';
import { useConnect } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  cardAction: {
    maxWidth: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  avatar: {
    margin: 'auto',
  },
  badge: {
    width: '10px',
    height: '10px',
    background: theme.palette.primary.main,
    margin: 'auto',
    display: 'block',
    marginTop: '6px',
    borderRadius: '100%',
  },
  cardFooter: {
    backgroundColor: theme.palette.grey[50],
  },
  notificationsColor: {
    position: 'absolute',
    width: '3px',
    height: '100%',
    backgroundColor: pink[600],
  },
  date: {
    display: 'block',
    marginRight: theme.spacing(2),
  },
}));

const NewMessagesCard = ({ event, setNotifications }) => {
  const classes = useStyles();
  const { removeEvent } = useConnect();
  const history = useHistory();
  const routes = useRoutes();

  return (
    <Card className={classes.root}>
      <Box className={classes.notificationsColor} />
      <CardActionArea
        onClick={() => {
          setNotifications(false);
          removeEvent(event);
          history.push(routes.getPathByName('chats'));
        }}
      >
        <Grid container justify="flex-end" className={classes.cardAction}>
          <Grid xs={8} item>
            <Typography>
              <FormattedMessage {...messages.newMessages} values={{ count: propOr('', 'count', event) }} />
            </Typography>
          </Grid>
          <Grid xs={1} item>
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
              className={classes.badge}
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

NewMessagesCard.propTypes = {
  event: PropTypes.object,
  setNotifications: PropTypes.func,
};
export default NewMessagesCard;
