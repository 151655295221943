import { compose, reduce, propOr, assoc } from 'ramda';
import { SIGNED_OUT } from './auth'; // eslint-disable-line
import { CHANNELS_LOADED } from './channels'; // eslint-disable-line
import { request } from './utils';
import { MESSAGE_PUBLISHED } from '../../lib/models/events';

export const MESSAGES_LOADED = '@messages:MESSAGES_LOADED';

const reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case MESSAGES_LOADED:
    case CHANNELS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'messages'),
        propOr([], 'data'),
      )(action);
      return { data };
    case MESSAGE_PUBLISHED:
      return {
        ...state,
        data: assoc(action.message._id, action.message, state.data),
      };
    case SIGNED_OUT:
      return { ...state, data: {} };
    default:
      return state;
  }
};

const loadAll = tripId => dispatch => {
  request(dispatch, { method: 'chat:loadMessages', tripId }).then(data => {
    dispatch({ type: MESSAGES_LOADED, data });
    return data;
  });
};

const publish = options => dispatch => {
  request(dispatch, { method: 'chat:publish', ...options }).then(data => {
    dispatch({ type: MESSAGE_PUBLISHED, message: data });
  });
};

const actions = {
  loadAll,
  publish,
};

export default { reducer, actions };
