import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { green, red, amber } from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  color: props => ({
    backgroundColor: props.color,
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'pre-line',
    '& .MuiSnackbarContent-action': {
      flexDirection: 'column',
    },
  }),
}));

const SnackBar = ({ info = {}, resetSnackBar }) => {
  const { open, title, text, action, color } = info;
  const history = useHistory();
  const routes = useRoutes();
  const snackColor = () => {
    switch (color) {
      case 'success':
        return green[600];
      case 'warning':
        return amber[600];
      case 'error':
        return red[600];
      default:
        return 'transparent';
    }
  };

  const pushToPage = () => {
    resetSnackBar();
    history.push(routes.getPathByName(action));
  };

  const classes = useStyles({ color: snackColor() });
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      variant="outlined"
      autoHideDuration={7000}
      onClose={resetSnackBar}
    >
      <SnackbarContent
        variant="body2"
        className={classes.color}
        message={text ? `${title} \n ${text}` : title}
        action={[
          action && (
            <IconButton key="action" aria-label="action" color="inherit" className={classes.close} onClick={pushToPage}>
              <ArrowForwardIcon />
            </IconButton>
          ),
        ]}
      />
    </Snackbar>
  );
};

SnackBar.propTypes = {
  info: PropTypes.object,
  resetSnackBar: PropTypes.func,
};

export default SnackBar;
