import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backgourdContainer: {
    backgroundImage: `url("/homePageMobile.webp"),url("/homePageMobileLow.webp")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
  },
  container: {
    paddingBottom: theme.spacing(4),
    minHeight: '100vh',
    paddingTop: '0px',
    backgroundColor: '#FFFFFFCC',
  },
  root: {
    zIndex: '5',
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    height: '100px',
  },
}));

const Spinner = ({ icon = '/loading.webp', withouBackground }) => {
  const classes = useStyles();

  if (withouBackground)
    return (
      <div className={`${classes.root} circular-progress--wrapper`}>
        <img className={classes.circularProgress} src={icon} alt="loading" />
      </div>
    );
  return (
    <div className={classes.backgourdContainer}>
      <div className={classes.container}>
        <div className={`${classes.root} circular-progress--wrapper`}>
          <img className={classes.circularProgress} src={icon} alt="loading" />
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  icon: PropTypes.string,
  withouBackground: PropTypes.bool,
};

export default Spinner;
