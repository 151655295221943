import { endOfDay, addDays, addMonths, isPast } from 'date-fns';
import { take, tail } from 'ramda';
export const TODAY = 'TODAY';
export const IN_TEN_DAYS = 'IN_TEN_DAYS';
export const IN_ONE_MONTH = 'IN_ONE_MONTH';
export const CUSTOM_RANGE = 'CUSTOM_RANGE';

export const secondsToMin = time => {
  const sec = parseInt(time, 10); // don't forget the second param
  const minutes = Math.floor(sec / 60);
  const hours = Math.floor(minutes / 60);
  if (hours && minutes % 60 < 10) return `${hours}:0${minutes % 60}`;
  if (hours && minutes % 60 >= 10) return `${hours}:${minutes % 60}`;

  if (minutes) return `${minutes} m`;
  return `0 m`;
};

export const secondsTotime = time => {
  const sec = parseInt(time, 10); // don't forget the second param
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);

  if (hours) return `${hours}h ${minutes}min`;
  return `${minutes}min`;
};

// get date in dd/mm/yyyy format
export const formateDate = date => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}/${mm}/${yyyy}`;
};

// get hour in hh/mm format
export const formatTime = date => {
  let hh = date.getHours();
  let mm = date.getMinutes();

  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${hh}:${mm}`;
};

export const minutesToHours = time => {
  const hours = time / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);
  return `${rHours}h:${rMinutes}min`;
};

export const setTripDate = (date, time) => {
  const hours = time.getHours();
  const mins = time.getMinutes();

  const newDate = date;
  newDate.setHours(hours);
  newDate.setMinutes(mins);
  return newDate;
};
export const getTodayRange = () => {
  const startDate = new Date();
  const endDate = endOfDay(new Date());
  return [startDate.toString(), endDate.toString()];
};

export const getTenDaysFromNowRange = () => {
  const startDate = new Date();
  const endDate = addDays(new Date(), 10);
  return [startDate.toString(), endDate.toString()];
};

export const getOneMonthFromNowRange = () => {
  const startDate = new Date();
  const endDate = addMonths(new Date(), 1);
  return [startDate.toString(), endDate.toString()];
};

export const formatRelativeTime = date => {
  if (take(1, date) === '-') return tail(date);
  return date;
};

export const isPastSevenDays = date => {
  const newDate = addDays(new Date(date), 7);
  return isPast(newDate);
};
