import { compose, reduce, propOr, dissoc, path } from 'ramda';
import { request } from './utils';
import allActions from '../actions'; //eslint-disable-line

export const FRIENDS_LOADED = '@friends:FRIENDS_LOADED';
export const FRIEND_LOADED = '@friends:FRIEND_LOADED';
const FRIEND_ADDED = '@friends:FRIEND_ADDED';
const REQUEST_CACELED = '@friends:REQUEST_CACELED';
const FRIEND_REMOVED = '@friends:FRIEND_REMOVED';
export const REQUEST_ACCEPTED = '@friends:REQUEST_ACCEPTED';
export const REQUEST_REJECTED = '@friends:REQUEST_REJECTED';

const reducer = (state = {}, action) => {
  let data;
  let otherBikers;
  switch (action.type) {
    case FRIENDS_LOADED:
      data = compose(
        reduce((acc, v) => ({ ...acc, [v.recipient]: v }), {}),
        propOr([], 'friends'),
      )(action);

      otherBikers = compose(
        reduce((acc, v) => ({ ...acc, [v._id]: v }), {}),
        propOr([], 'otherBikers'),
      )(action);

      return { ...state, data, otherBikers };
    case FRIEND_LOADED:
    case FRIEND_ADDED:
      data = action.friend
        ? {
            ...state.data,
            [path(['friend', 'recipient'], action)]: action.friend,
          }
        : { ...state.data };
      return { ...state, data, otherBikers: { ...dissoc(path(['friend', 'recipient'], action), state.otherBikers) } };
    case REQUEST_ACCEPTED:
      return {
        ...state,
        data: { ...state.data, [action.bikerId]: { ...action.friend } },
      };
    case REQUEST_REJECTED:
    case FRIEND_REMOVED:
    case REQUEST_CACELED:
      return {
        ...state,
        data: { ...dissoc(action.bikerId, state.data) },
      };
    default:
      return state;
  }
};

const loadAll = params => dispatch =>
  request(dispatch, { method: 'friends:loadAll', ...params }).then(response =>
    dispatch({
      type: FRIENDS_LOADED,
      friends: response.friends,
      bikers: response.bikers,
      otherBikers: response.otherBikers,
    }),
  );

const addFriend = bikerId => dispatch => {
  request(dispatch, { method: 'friends:create', bikerId }).then(response =>
    response._id ? dispatch({ type: FRIEND_ADDED, friend: response }) : null,
  );
};

const loadOne = id => dispatch =>
  request(dispatch, { method: 'friends:loadOne', id }).then(response =>
    response.biker ? dispatch({ type: FRIEND_LOADED, friend: response.friend, biker: response.biker }) : null,
  );

const acceptRequest = bikerId => dispatch => {
  request(dispatch, { method: 'friends:accept', bikerId }).then(response =>
    dispatch({ type: REQUEST_ACCEPTED, friend: response, bikerId }),
  );
};

const rejectRequest = bikerId => dispatch => {
  request(dispatch, { method: 'friends:reject', bikerId }).then(() => dispatch({ type: REQUEST_REJECTED, bikerId }));
};

const cancelRequest = bikerId => dispatch => {
  request(dispatch, { method: 'friends:cancel', bikerId }).then(() => dispatch({ type: REQUEST_CACELED, bikerId }));
};

const removeFriend = bikerId => dispatch => {
  request(dispatch, { method: 'friends:remove', bikerId }).then(() => dispatch({ type: FRIEND_REMOVED, bikerId }));
};

const actions = {
  loadOne,
  loadAll,
  acceptRequest,
  rejectRequest,
  addFriend,
  cancelRequest,
  removeFriend,
};

export default { reducer, actions };
