import { propEq } from 'ramda';

export const STATUS = {
  visitor: 'visitor',
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  canceled: 'canceled',
  riding: 'riding',
  suspended: 'suspended',
  done: 'done',
  left: 'left',
  archived: 'archived',
  invited: 'invited',
  deleted: 'deleted',
};

export const statusEq = propEq('status');
export const isVisitor = statusEq(STATUS.visitor);
export const isCanceled = statusEq(STATUS.canceled);
export const isRiding = statusEq(STATUS.riding);
export const isPending = statusEq(STATUS.pending);
export const isAccepted = statusEq(STATUS.accepted);
export const isSuspended = statusEq(STATUS.suspended);
export const isRejected = statusEq(STATUS.rejected);
export const isDone = statusEq(STATUS.done);
export const isLeft = statusEq(STATUS.left);
export const isArchived = statusEq(STATUS.archived);
export const isInvited = statusEq(STATUS.invited);
export const isDeleted = statusEq(STATUS.deleted);
export const isLeader = (user, trip) => user._id.equals(trip.leaderId);
