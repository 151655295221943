import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
  },
  icon: {
    fontSize: '12em',
    color: theme.palette.grey[700],
  },
  message: {
    color: theme.palette.grey[700],
    padding: theme.spacing(2),
  },
}));

const NoResults = ({ message }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.content}>
      <Grid item>
        <YoutubeSearchedForIcon className={classes.icon} />
      </Grid>
      <Grid>
        <Typography variant="h6" className={classes.message} align="center">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

NoResults.propTypes = {
  message: PropTypes.string,
};

export default NoResults;
