import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  getBikeImage: ({ _id }) =>
    axios
      .get(endPoint(`/api/bikers/getBikeImage/${_id}`), { headers: bearer(), responseType: 'blob' })
      .then(prop('data')),
  getBikerImage: ({ _id }) =>
    axios
      .get(endPoint(`/api/bikers/getBikerImage/${_id}`), { headers: bearer(), responseType: 'blob' })
      .then(prop('data')),
  add: data => axios.post(endPoint(`/api/bikers/add/`), { ...data }, { headers: bearer() }).then(prop('data')),
  adminUpdate: data =>
    axios.post(endPoint(`/api/bikers/adminUpdate/`), { ...data }, { headers: bearer() }).then(prop('data')),

  update: ({
    _id,
    description,
    licenceDate,
    licenceType,
    bikeBrand,
    bikeYear,
    bikeImage,
    bikerImage,
    bikeComment,
    availableOnFB,
    coordinates,
    userName,
    status,
    birthday,
    newTtripsNotifications,
  }) =>
    axios
      .post(
        endPoint(`/api/bikers/update`),
        {
          _id,
          description,
          licenceDate,
          licenceType,
          bikeBrand,
          bikeYear,
          bikeComment,
          bikeImage,
          bikerImage,
          availableOnFB,
          coordinates,
          userName,
          birthday,
          status,
          newTtripsNotifications,
        },
        { headers: bearer() },
      )
      .then(prop('data')),
  loadOne: ({ id }) => axios.get(endPoint(`/api/bikers/loadOne/${id}`), { headers: bearer() }).then(prop('data')),
  removeById: ({ id }) => axios.post(endPoint(`/api/bikers/remove`), { id }, { headers: bearer() }).then(prop('data')),
  remove: () => axios.get(endPoint(`/api/bikers/remove`), { headers: bearer() }).then(prop('data')),
  loadAll: ({ ids }) => axios.post(endPoint(`/api/bikers/loadAll`), { ids }, { headers: bearer() }).then(prop('data')),
  findBikers: ({ keyword, accountStatus }) =>
    axios
      .post(endPoint(`/api/bikers/findBikers`), { keyword, accountStatus }, { headers: bearer() })
      .then(prop('data')),
  getBikersCount: ({ accountStatus }) =>
    axios.post(endPoint(`/api/bikers/getBikersCount`), { accountStatus }, { headers: bearer() }).then(prop('data')),
  loadTrips: ({ statuses, when }) =>
    axios.post(endPoint(`/api/bikers/loadTrips`), { statuses, when }, { headers: bearer() }).then(prop('data')),
  sendNotifications: ({ notification }) =>
    axios
      .post(endPoint(`/api/bikers/sendNotificationToBikers`), { notification }, { headers: bearer() })
      .then(prop('data')),
});
