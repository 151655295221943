import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  loadOne: ({ id }) => axios.get(endPoint(`/api/trippers/loadOne/${id}`), { headers: bearer() }).then(prop('data')),
  join: ({ tripId, isVisitor }) =>
    axios.post(endPoint(`/api/trippers/join`), { tripId, isVisitor }, { headers: bearer() }).then(prop('data')),
  confirm: ({ tripId, bikerId }) =>
    axios.post(endPoint(`/api/trippers/confirm`), { tripId, bikerId }, { headers: bearer() }).then(prop('data')),
  reject: ({ tripId, bikerId }) =>
    axios.post(endPoint(`/api/trippers/reject`), { tripId, bikerId }, { headers: bearer() }).then(prop('data')),
  cancel: ({ tripId }) =>
    axios.post(endPoint(`/api/trippers/cancel`), { tripId }, { headers: bearer() }).then(prop('data')),
  end: ({ id }) => axios.post(endPoint(`/api/trippers/end`), { id }, { headers: bearer() }).then(prop('data')),
  ride: ({ tripId }) =>
    axios.post(endPoint(`/api/trippers/ride`), { tripId }, { headers: bearer() }).then(prop('data')),
  move: ({ position, tripId }) =>
    axios.post(endPoint(`/api/trippers/move`), { position, tripId }, { headers: bearer() }).then(prop('data')),
  remove: ({ tripId }) =>
    axios.post(endPoint(`/api/trippers/remove`), { tripId }, { headers: bearer() }).then(prop('data')),
  loadTrippersPosition: ({ id }) =>
    axios.post(endPoint(`/api/trippers/loadTrippersPosition`), { id }, { headers: bearer() }).then(prop('data')),
});
