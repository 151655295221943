import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  EmailBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '45px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(10),
    justifyContent: 'center',
    background: '#FFF',
    color: theme.palette.grey[800],
    textTransform: 'none',
    '& img': {
      position: 'absolute',
      left: theme.spacing(2),
      fontSize: '26px',
    },
  },
}));

const EmailButton = ({ handleClose }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const routes = useRoutes();
  useEffect(() => {
    history.push(routes.getPathByName('comotLogin'));
  }, [history, routes]);
  const handleClick = () => {
    history.push(routes.getPathByName('comotLogin'));
    if (handleClose) handleClose();
  };
  return (
    <Button variant="contained" className={classes.EmailBtn} onClick={handleClick}>
      <img src="/motorbike-icon-24-278536.png" alt="bike" />
      {intl.formatMessage(messages.addressMail)}
    </Button>
  );
};

EmailButton.propTypes = {
  handleClose: PropTypes.func,
};

export default EmailButton;
