import React from 'react';
import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { formatDistanceStrict } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import messages from './messages';
import useRoutes from '../../hooks/routes';
import { useConnect, useEndDialog } from './hooks';
import { userIsLeader } from '../../../lib/models/trips';
import Dialog from '../Dialog';
import { NATIVE_ACTIONS } from '../../../lib/models/native';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#FFF',
    borderRadius: '50%',
    height: '64px',
    backgroundColor: theme.palette.success.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '7px solid',
      borderColor: theme.palette.success.dark,
      content: '""',
    },
    '&:hover,&active,&focus': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.3)',
      opacity: 0,
    },
  },
  cardAction: {
    maxWidth: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0.5),
    borderRadius: '10px',
  },
  date: {
    marginRight: theme.spacing(0.5),
  },
  watchIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: '20px',
    marginTop: '1px',
    color: theme.palette.grey[800],
  },
  endTripBtn: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  timeStyle: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const NewsCard = ({ trip }) => {
  const classes = useStyles();
  const history = useHistory();
  const routes = useRoutes();
  const [endDialog, setEndDialog] = useEndDialog();
  const { user, end, postMessage } = useConnect();
  const intl = useIntl();

  const goToDeparture = trip => {
    history.push(routes.getPathByName('departure', trip._id));
  };

  const cardMessage = () => {
    if (userIsLeader(user, trip)) {
      if (isPast(new Date(trip.startAt)))
        return <FormattedMessage {...messages.yourTripStarted} values={{ tripName: trip.name }} />;
      return <FormattedMessage {...messages.yourTripStartSoon} values={{ tripName: trip.name }} />;
    }
    if (isPast(new Date(trip.startAt)))
      return <FormattedMessage {...messages.tripStarted} values={{ tripName: trip.name }} />;
    return <FormattedMessage {...messages.tripStartSoon} values={{ tripName: trip.name }} />;
  };

  return (
    <Grid item container direction="column" alignItems="center">
      <Grid item container>
        <Card className={classes.card}>
          <Grid container alignItems="center" className={classes.cardAction} spacing={1} wrap="nowrap">
            <Grid item xs={3}>
              <Button onClick={() => goToDeparture(trip)} variant="contained" className={classes.button}>
                <FormattedMessage {...messages.go}></FormattedMessage>
              </Button>{' '}
            </Grid>
            <Grid item container direction="column" alignItems="center">
              <Grid item>
                <Typography>{cardMessage()}</Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.timeStyle}>
              {formatDistanceStrict(new Date(trip.startAt), new Date(), {
                locale: frLocale,
                addSuffix: false,
              })}
            </Grid>
            <Dialog
              dialog={endDialog}
              text={intl.formatMessage(messages.dialogTitle)}
              onOkActionName={intl.formatMessage(messages.validate)}
              setDialog={setEndDialog}
              onOk={() => {
                end(trip._id);
                return postMessage({ type: NATIVE_ACTIONS.STOP_LOCATION_TRACKING });
              }}
            />
          </Grid>

          {isPast(new Date(trip.startAt)) && (
            <>
              <Divider />
              <Button className={classes.endTripBtn} onClick={() => setEndDialog(true)}>
                <FormattedMessage {...messages.tripEnded} />
              </Button>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

NewsCard.propTypes = {
  trip: PropTypes.object,
};
export default NewsCard;
